import React from "react";
import "./loginStyle.css"
import diplomatLogo from "./images/diplomat-logo.png"
// import { Link,  useNavigate } from "react-router-dom";
// import AdminSidebar from "../profile/admin/adminSidebar/adminSidebar";
// import { useState, useContext } from "react";
// import { LoginContext } from "../../context/LoginContext";

export default function ResetPassword() {


  return (

    <div className="login-container">
      <div className="box" id="left-box">
        <div className="header"><img src={diplomatLogo} id="diplomat-logo" alt="Diplomat Logo" /></div>
        <div className="login-content">
          <div className="form-div" >
            <h2>Reset Password</h2>
            <form className="form-content">
              <input
                type="text"
                placeholder="New Password"
                className="text-input"
                autoFocus
              />
              <input
                type="password"
                placeholder="Confirm Password"
                className="text-input"
              />
              <button className="login">Set Password</button>
            </form>
          </div>
        </div>
      </div>
      <div className="box" id="right-box"></div>
    </div>
  )
}