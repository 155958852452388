import React, { useContext, useEffect, useState } from "react";
import addIcon from "../../../global/images2/add-icon.png";
import exitIcon from "../../../global/images2/exit-icon.png";
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import { auth, storage } from "../../../../firebase-config";
import { getDownloadURL, ref } from "firebase/storage";

const { RangePicker } = DatePicker;

export default function Documents() {
  const [activeUser, setActiveUser] = useState({ uid: "" }); // Initialize with an empty object
  const [contractURL, setContractURL] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setActiveUser(user.uid);
        // Fetch contract URL when the component mounts
        await getContract();
      } else {
        setActiveUser({ uid: "" }); // Set it to an empty object when user is not authenticated
        setLoading(false); // Set loading to false when user is not authenticated
      }
    });

    return () => unsubscribe();
  }, [loading]);

  const getContract = async () => {
    try {
      const contractPath = `contracts/${activeUser}_contract.pdf`;
      const url = await getDownloadURL(ref(storage, contractPath));
      console.log(url);
      setContractURL(url);
      setLoading(false); // Set loading to false once contract URL is fetched
    } catch (error) {
      console.error("Error fetching file URL: " + error.message);
      setLoading(false); // Set loading to false in case of an error
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator
  }

  const handleDownload = () => {
    console.log("Contract URL:", contractURL);

    const link = document.createElement("a");
    link.href = contractURL;
    link.download = "contract.pdf";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="admin-tenants-container">
      <div className="admin-tenants-content">
        <div className="tenants-header">
          <h1>CONTRACT</h1>
        </div>

        <div className="admin-table-div">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Documents</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="contracts-docs">
                  {contractURL ? (
                    <a href={contractURL} target="_blank" rel="noopener noreferrer">
                      View Contract
                    </a>
                  ) : (
                    "Contract not available"
                  )}
                </td>
                <td>
                  <button className="action-buttons" onClick={handleDownload}>
                    Download
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}