import React, { useEffect } from "react";
import "./loginStyle.css"
import diplomatLogo from "./images/diplomat-logo.png"
import { Link, useNavigate } from "react-router-dom";
// import AdminSidebar from "../profile/admin/adminSidebar/adminSidebar";
import { useState, useContext } from "react";
import { LoginContext } from "../../context/LoginContext";
import { auth } from "../../firebase-config"
// import { onAuthStateChanged, signInWithEmailAndPassword  } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { db } from "../../firebase-config";
// import { collection, getDocs } from "firebase/firestore"


export default function Login() {
  // const { admin, tenants, employees, isLoggedIn, setIsLoggedIn, adminCollection} = useContext(LoginContext);
  const { admin, tenants, employees, setIsLoggedIn } = useContext(LoginContext);
  const [wrongInput, setWrongInput] = useState(false);
  const [notVerified, setNotVerified] = useState(false);
  const [notActivated, setNotActivated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [showPasswordEye, setShowPasswordEye] = useState(true);
  const navigate = useNavigate();
  

  const initialState = {
    email: localStorage.getItem('rememberedEmail') || "",
    password: localStorage.getItem('rememberedPassword') === "" || "",
    
  };

  const [formData, setformData] = useState(initialState);
    
  const handleShowPasswordEye = () => {
    setShowPasswordEye(false);
  }

  useEffect(() => {
    handleShowPasswordEye();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordEye = (e) => {
    return e.target.value === '' ? setShowPasswordEye(false) : setShowPasswordEye(true);
  }


  const loginUser = async (event) => {
    event.preventDefault()
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      )
      const user = userCredential.user

      if (user.emailVerified) {
        // eslint-disable-next-line
        admin.map((adminCred) => {
          if (user.uid === adminCred.userID) {
            setIsLoggedIn(true)
            localStorage.setItem('userFullName', `${adminCred.firstName} ${adminCred.lastName}`);
            navigate("admin")
          }
        });
        // eslint-disable-next-line
        tenants.map((tenantCred) => {
          if (user.uid === tenantCred.userID) {
            if (tenantCred.status === "Active") {
              setIsLoggedIn(true)
              localStorage.setItem('userFullName', `${tenantCred.firstName} ${tenantCred.lastName}`);
              navigate("tenant")
            } else {
              setNotActivated(true)
              setNotVerified(false)
              setWrongInput(false)
            }
          }
        })
        // eslint-disable-next-line
        employees.map((employeesCred) => {
          if (user.uid === employeesCred.userID && employeesCred.status === "Active") {
            if (employeesCred.title === "Chief Security Guard") {
              setIsLoggedIn(true)
              localStorage.setItem('userFullName', `${employeesCred.firstName} ${employeesCred.lastName}`);
              navigate("chiefsecurity")
            } else if (employeesCred.title === "Cashier") {
              setIsLoggedIn(true)
              localStorage.setItem('userFullName', `${employeesCred.firstName} ${employeesCred.lastName}`);
              navigate("cashier")
            }
          }
        })
      } else {
        setNotVerified(true)
        setNotActivated(false)
        setWrongInput(false)
      }
      // for(let i = 0; i < admin.length; i++ ){
      //     if(admin[i].UserID === user.uid){
      //         console.log("hehe");
      //         navigate("admin")
      //         break;
      //     }
      // }

    } catch (error) {
      console.error("Login Error:", error);
      setWrongInput(true)
      setNotActivated(false)
      setNotVerified(false)
    }

  }

  //Get the user input
  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setformData((prevFormData) => {
      const updatedData = {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
  
      // Save email to local storage only if "Remember Me" is checked
      if (name === "email" && updatedData.rememberMe) {
        localStorage.setItem('rememberedEmail', updatedData.email);
      }
  
      // Save password to local storage only if "Remember Me" is checked
      if (name === "password" && updatedData.rememberMe) {
        localStorage.setItem('rememberedPassword', updatedData.password);
      }
  
      // Save "Remember Me" to local storage
      localStorage.setItem('rememberMe', updatedData.rememberMe);
  
      return updatedData;
    });
  }
  function visitor() {
    navigate("visitor")
  }

  return (
    <div className="login-container">
      <div className="box" id="left-box">
        <div className="header"><img src={diplomatLogo} id="diplomat-logo" alt="Diplomat Logo" /></div>
        <div className="login-content">
          <div className="form-div" >
            <h2>Welcome Back!</h2>
            <h3>Login Account</h3>
            <form className="form-content" onSubmit={loginUser}>
              <input
                type="email" 
                placeholder="Email" 
                className="text-input" 
                autoFocus 
                onChange={handleChange}
                name="email"
                value={formData.email}
              />
              <div
                // className='input-with-eye'
                className='text-input'
              >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="text-input"
                  onChange={handleChange}
                  onClick={handlePasswordEye}
                  name="password"
                  value={formData.password}
                />
                {showPassword
                  ? <i className="fa-regular fa-eye-slash" onClick={togglePasswordVisibility}></i>
                  : <i className="fa-regular fa-eye" onClick={togglePasswordVisibility}></i>
                }
              </div>
              <div className="remfor">
                <input
                  type="checkbox"
                  name="rememberMe"
                  onChange={handleChange}
                  checked={formData.rememberMe}
                />
                <label
                  className="remember"
                >Remember Me</label>
              </div>
              <button className="login" onClick={loginUser}>Log In</button>
              <button className="login" onClick={visitor}>Visitor's Form</button>
              <span id="incorrectInp" style={{ display: wrongInput ? 'block' : 'none' }}>The password or Account Number <br></br> you’ve entered is incorrect.</span>
              <span id="incorrectInp" style={{ display: notVerified ? 'block' : 'none' }}>This user is not verified.</span>
              <span id="incorrectInp" style={{ display: notActivated ? 'block' : 'none' }}>This user is not activated.</span>
              <Link to="forgotpassword" className="forgot">Forgot Password?</Link>
            </form>
          </div>
        </div>
      </div>
      <div className="box" id="right-box"></div>
    </div>
  )
}