import React, { useContext, useEffect, useState } from "react";
import "./unit.css"
import exitIcon from "../../../global/images2/exit-icon.png"
import { auth, db } from "../../../../firebase-config";
import { LoginContext } from "../../../../context/LoginContext";
import { addDoc, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import Settings from '../../../global/settings/settings';
import { min, set } from "date-fns";

export default function Unit() {
  const {units, setUnits, unitsCollection, admin, tenants, vat, setVat, wht, setWht} = useContext(LoginContext)
    const [activeUser, setActiveUser] = useState(null)
    const [floor, setFloor] = useState("2ND FLOOR")
    const [curFloor, setCurFloor] = useState(2)
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedUnitHolder, setSelectedUnitHolder] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false)
    const [outofRange, setOutofRange] = useState(false)

    const [newUnitNumber, setNewUnitNumber] = useState(0)
    const [newStatus, setNewStatus] = useState("")
    const [newType, setNewType] = useState("")
    const [newSize, setNewSize] = useState(0)
    const [newRate, setNewRate] = useState(0)
    const [newVat, setNewVat] = useState(12)
    const [newWht, setNewWht] = useState(5)
    const [startRange, setStartRange] = useState(201)
    const [endRange, setEndRange] = useState(210)
    const [studioBaseRate, setStudioBaseRate] = useState(localStorage.getItem("studioBaseRate"))
    const [commercialBaseRate, setCommercialBaseRate] = useState(localStorage.getItem("commercialBaseRate"))
    const [residentialBaseRate, setResidentialBaseRate] = useState(localStorage.getItem("residentialBaseRate"))

    const [unitExisted, setUnitExisted] = useState(false)
    const [unitExisted2, setUnitExisted2] = useState(false)
    const [emptyFields, setEmptyFields] = useState(false)
    const [sizeOutRange, setSizeOutRange] = useState(false)
    const [vatOutRange, setVatOutRange] = useState(false)
    const [whtOutRange, setWhtOutRange] = useState(false)
    const [rateOutRange, setRateOutRange] = useState(false)
    const [cantChange, setCantChange] = useState(false)
    const [minStudioRate, setMinStudioRate] = useState(45000);
    const [minResidentialRate, setMinResidentialRate] = useState(50000);
    const [minCommercialRate, setMinCommercialRate] = useState(55000);
    const [popup, setPopup] = useState(false)
    const [edit, setEdit] = useState(false)

    const [popupInfo, setPopupInfo] = useState(false)

    const [numberofStudio, setNumberOfStudio] = useState(0)
    const [numberofResidential, setNumberOfResidential] = useState(0)
    const [numberOfCommercial, setNumberOfCommercial] = useState(0)

    useEffect(() => {
      if (units.length > 0) {
        const studio = units.find((unit) => unit.type === 'Studio' && unit.status !== 'Occupied' && unit.rate)
        const residential = units.find((unit) => unit.type === 'Residential' && unit.status !== 'Occupied' && unit.rate)
        const commercial = units.find((unit) => unit.type === 'Commercial' && unit.status !== 'Occupied' && unit.rate)
        setMinStudioRate(studio ? studio.rate : 45000);
        setMinResidentialRate(residential ? residential.rate : 50000);
        setMinCommercialRate(commercial ? commercial.rate : 55000);
      }
    }, [units]);
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            setActiveUser(user);
            checkTitle()
        } else {
            setActiveUser(null);
        }
        });

        return () => unsubscribe();
      }, [activeUser]);

      const checkTitle = () => {
        const user = auth.currentUser
        admin.map((admin) => {
            if (admin.userID === user.uid) {
                setIsAdmin(true)
            }
        });
      }

      useEffect(() => {
        // Calculate counts when units change
        let studioCount = 0;
        let residentialCount = 0;
        let commercialCount = 0;

        units.forEach((unit) => {
          if (unit.type === "Studio") {
            studioCount++;
          } else if (unit.type === "Residential") {
            residentialCount++;
          } else if (unit.type === "Commercial") {
            commercialCount++;
          }
        });

        // Set counts in state
        setNumberOfStudio(studioCount);
        setNumberOfResidential(residentialCount);
        setNumberOfCommercial(commercialCount);
      }, [units]);

    const togglePopupInfo = (unit) => {
        setPopupInfo(!popupInfo)
        setSelectedUnit(unit)
        setSelectedUnitHolder(unit)
        setEdit(false)
        setUnitExisted2(false)
        setOutofRange(false)
        setSizeOutRange(false)
        setVatOutRange(false)
        setWhtOutRange(false)
        setRateOutRange(false)
        setEmptyFields(false)
        setCantChange(false)
    }
    const togglePopupForm = () => {
        setPopup(!popup)
        setNewVat(12)
        setNewWht(5)
        setUnitExisted(false)
        setNewRate("")
        setNewSize("")
        setNewUnitNumber("")
        setOutofRange(false)
        setSizeOutRange(false)
        setVatOutRange(false)
        setWhtOutRange(false)
        setRateOutRange(false)
        setEmptyFields(false)
    }
    const statusColors = {
        "Not Available": "gray",
        Reserved: "blue",
        Occupied: "red",
        Available: "green",

      };
      useEffect(() => {
        // Create a reference to the "units" collection
        const unitsCollectionRef = collection(db, 'units');

        // Set up a Firestore listener to listen for changes to the collection
        const unsubscribe = onSnapshot(unitsCollectionRef, (snapshot) => {
            const updatedUnits = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setUnits(updatedUnits);
        });

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe();
        };
    }, []); // Empty dependency array to ensure it runs once on component mount
    const handleNewUnitNumberChange = (event) => {
        // Use a regular expression to allow only up to 3 digits
        const newValue = event.target.value.replace(/\D/g, '').substring(0, 3);
        setNewUnitNumber(newValue);
    };

    const handleNewSizeChange = (event) => {
        // Use a regular expression to allow only up to 3 digits
        const newValue = event.target.value.replace(/\D/g, '').substring(0, 3);
        setNewSize(newValue);
    };

    const handleNewTypeChange = (event) => {
      const newValue = event.target.value;
      setNewType(newValue);

      let newSizeValue = null,
          newRateValue = null;

      switch(newValue) {
        case "Studio":
          newSizeValue = 134;
          newRateValue = minStudioRate;
          break;
        case "Residential":
          newSizeValue = 216;
          newRateValue = minResidentialRate;
          break;
        case "Commercial":
          newSizeValue = 306;
          newRateValue = minCommercialRate;
          break;
        default:
          break;
      }

      setNewSize(newSizeValue);
      setNewRate(newRateValue);
    };

    const handleEditNewTypeChange = (event) => {
      const newValue = event.target.value;

      let newSizeValue = null,
          newRateValue = null;

      switch(newValue) {
        case "Studio":
          newSizeValue = 134;
          newRateValue = minStudioRate;
          break;
        case "Residential":
          newSizeValue = 216;
          newRateValue = minResidentialRate;
          break;
        case "Commercial":
          newSizeValue = 306;
          newRateValue = minCommercialRate;
          break;
        default:
          break;
      }

      setSelectedUnit((prevData) => ({
        ...prevData,
        type: newValue,
        size: newSizeValue,
        rate: newRateValue,
      }));
    };

    const cancelEdit = () => {
      setSelectedUnit((prevData) => ({
        ...prevData,
        ...selectedUnitHolder
      }));
      setEdit(false);
    }

    const handleNewWhtChange = (event) => {
        // Use a regular expression to allow only up to 2 digits
        const newValue = event.target.value.replace(/\D/g, '').substring(0, 2);
        setNewWht(newValue);
    };

    const handleNewVatChange = (event) => {
        // Use a regular expression to allow only up to 2 digits
        const newValue = event.target.value.replace(/\D/g, '').substring(0, 2);
        setNewVat(newValue);
    };
    const addUnit = async () => {
        if (
            newUnitNumber === '' ||
            newType === '' ||
            newStatus === '' ||
            newSize === '' ||
            newVat === '' ||
            newWht === '' ||
            newRate === ''
        ) {
            setEmptyFields(true);
            return;
        }else{
            const numericFields = [newSize, newRate, newVat, newWht];

        if (numericFields.some((field) => field < 0)) {
            // Check if any of the numeric fields have a negative value
            setUnitExisted(true);
            return;
        }
        if(newUnitNumber < startRange || newUnitNumber > endRange){
            setOutofRange(true)
            return;
        }
        if(newSize > 306 || newSize < 134){
            setSizeOutRange(true)
            return;
        }
        if(newVat > 99){
            setVatOutRange(true)
            return;
        }
        if(newWht > 99){
            setWhtOutRange(true)
            return;
        }
        

        if (
          (newType === 'Studio' && newRate < studioBaseRate) ||
          (newType === 'Residential' && newRate < residentialBaseRate) ||
          (newType === 'Commercial' && newRate < commercialBaseRate)
        ) {
          setRateOutRange(true);
          return;
        }

        try {
            const existingUnit = units.find(unit => unit.unitNumber === Number(newUnitNumber));

            if (existingUnit) {
                setUnitExisted(true);
            } else {
                // Unit does not exist, add it to the collection
                const newUnitData = {
                    unitNumber: Number(newUnitNumber),
                    type: newType,
                    status: newStatus,
                    size: Number(newSize),
                    floor: Number(curFloor),
                    vat: Number(vat),
                    wht: Number(wht),
                    rate: Number(newRate),
                };

                await addDoc(unitsCollection, newUnitData);
                togglePopupForm()
            }
        } catch (error) {
            console.error('Error adding unit:', error);
        }
        }


    };

    const handleAddUnitClick = (e) => {
        e.preventDefault();
        addUnit();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSelectedUnit((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const updateUnit = async (id, updatedUnitData) => {
        if (Number(updatedUnitData.unitNumber) < startRange || Number(updatedUnitData.unitNumber > endRange)) {
          setOutofRange(true)
          return
        }else if(updatedUnitData.vat > 99){
            setVatOutRange(true)
            return

        }else if(updatedUnitData.wht > 99){
            setWhtOutRange(true)
            return
        }else if(updatedUnitData.size > 306 || updatedUnitData.size < 134){
            setSizeOutRange(true)
            return   
        }

        if(updatedUnitData.type === "Studio"){
          if(updatedUnitData.rate < studioBaseRate){
            setRateOutRange(true)
            return
          }
        }

        if(updatedUnitData.type === "Commercial"){
          if(updatedUnitData.rate < commercialBaseRate){
            setRateOutRange(true)
            return
          }
        }

        if(updatedUnitData.type === "Residential"){
          if(updatedUnitData.rate < residentialBaseRate){
            setRateOutRange(true)
            return
          }
        }

        

        tenants.map((tenant) => {
            if(Number(tenant.unit) === updatedUnitData.unitNumber && tenant.status === "Active"){
                setCantChange()
            }
        })
        try {
            const existingUnit = units.find(unit => unit.unitNumber === updatedUnitData.unitNumber);

            if (existingUnit && existingUnit.id !== id) {
                // If the existing unit has a different ID, it means the unit number already exists.
                setUnitExisted2(true);
                return;
            } else {
                const unitIndex = units.findIndex(unit => unit.id === id);
                if (unitIndex !== -1) {
                    const unitDocRef = doc(db, 'units', id);
                    await updateDoc(unitDocRef, updatedUnitData);

                    setUnits(prevUnits =>
                        prevUnits.map((unit, index) => (index === unitIndex ? { ...unit, ...updatedUnitData } : unit))
                    );
                    setEdit(false);
                    setUnitExisted2(false)
                } else {
                    console.error('Unit not found in units array.');
                }
            }
        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

  return (
    <div className="adminUnitContainer">
      {popup && (
        <div className="popup-form">
          <div className="popup-overlay" onClick={togglePopupForm}></div>
          <div className="popup-box">

            <div className="popup-header">
              <h1>Add a Unit</h1>
              <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
            </div>
            <div className="popup-content">

              <form>
                <div className="formleft">
                  <label>Unit Number:</label>
                  <input
                    type="number"
                    autoFocus
                    onChange={(event) => { setNewUnitNumber(event.target.value) }}
                    placeholder="Unit Number"
                  />

                  <label>Size:</label>
                  <input
                    type="number"
                    value = { newSize }
                    onChange={(event) => { setNewSize(event.target.value) }}
                    placeholder="Size in sqm"
                  />

                  <label>Type:</label>
                  <select onChange={ handleNewTypeChange }>
                    <option value="">--Choose--</option>
                    <option value="Studio">Studio</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>


                  <label>Base
                    Rate:</label>
                  <input type="number" value = { newRate } onChange={(event) => { setNewRate(event.target.value) }} placeholder="Unit Base Rate"
                    min={
                      newType === "Studio" ? studioBaseRate :
                      newType === "Residential" ? residentialBaseRate :
                      newType === "Commercial" ? commercialBaseRate : 0
                    }/>
                </div>

                <div className="formright">
                  <label>Status:</label>
                  <select onChange={(event) => { setNewStatus(event.target.value) }}>
                    <option value="">--Choose--</option>
                    <option value="Available">Available</option>
                    <option value="Reserved">Reserved</option>
                    <option value="Not Available">Not Available</option>
                  </select>



                  <label>VAT%:</label>
                  <input
                    disabled
                    type="number"
                    onChange={(event) => { setVat(event.target.value) }}
                    value={vat}
                    placeholder="VAT in %"
                  />

                  <label>WHT%:</label>
                  <input
                    disabled
                    type="number"
                    value={wht}
                    onChange={(event) => { setWht(event.target.value) }}
                    placeholder="WHT in %"
                  />

                  <button className="createAccBtn" id="addUnit" onClick={handleAddUnitClick}>Add Unit</button>
                </div>
                <div>
                  <br></br>
                  {unitExisted && <p style={{color: "red", fontSize: "80%"}}><b>This unit already exist.</b></p>}
                  {emptyFields && <p style={{color: "red", fontSize: "80%"}}><b>Other Fields are empty.</b></p>}
                  {outofRange && <p style={{color: "red", fontSize: "80%"}}><b>Unit Number Out of Range.</b></p>}
                  {sizeOutRange && <p style={{color: "red", fontSize: "80%"}}><b>Size out of Range.</b></p>}
                  {rateOutRange && <p style={{color: "red", fontSize: "80%"}}><b>Base Rate out of Range.</b></p>}
                  {vatOutRange && <p style={{color: "red", fontSize: "80%"}}><b>VAT Out of Range.</b></p>}
                  {whtOutRange && <p style={{color: "red", fontSize: "80%"}}><b>VAT Out of Range.</b></p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {popupInfo && (

        <div className="popup-form">
          <div className="popup-overlay" onClick={togglePopupInfo}></div>
          <div className="popup-box" >

            <div className="popup-header">
              <h1>Unit Information</h1>
              <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
            </div>
            <div className="popupInfo-content" >
            <span><b>Unit Number: </b>
              {edit && selectedUnit.status !== "Occupied"? (
              <input
              type="number"
              name="unitNumber"
              className="unitInput"
              value={selectedUnit.unitNumber}
              onChange={handleChange}
              />)
              :(
              selectedUnit.unitNumber
              )}</span>
              {unitExisted2 && <p style={{color: "red", fontSize: "80%"}}><b>This unit already exist.</b></p>}
              {outofRange && <p style={{color: "red", fontSize: "80%"}}><b>Unit Number out of range.</b></p>}
              <span><b>Type: </b>
              {edit ? (
              <select name="type" className="unitInput" value={selectedUnit.type} onChange={handleEditNewTypeChange}>
                  <option value="Studio">Studio</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
              </select>
              ) : (
              selectedUnit.type
              )}
              </span>

              <span><b>Status: </b>
              {edit && selectedUnit.status !== "Occupied" ? (
              <select name="status" className="unitInput" value={selectedUnit.status} onChange={handleChange}>
                  <option value="Available">Available</option>
                  <option value="Occupied">Occupied</option>
                  <option value="Reserved">Reserved</option>
                  <option value="Not Available">Not Available</option>
              </select>
              ) : (
              selectedUnit.status
              )}
              </span>


              <span><b>Base Rate(₱): </b>
              {edit && selectedUnit.status !== "Occupied" ?  (
              <input type="number" name="rate" className="unitInput" value={selectedUnit.rate} onChange={handleChange} min={
                selectedUnit.type === "Studio" ? studioBaseRate :
                selectedUnit.type === "Residential" ? residentialBaseRate :
                selectedUnit.type === "Commercial" ? commercialBaseRate : 0
              }/>)
              :(
              selectedUnit.rate
              )}
              </span>
              {rateOutRange && <p style={{color: "red", fontSize: "80%"}}><b>Base Rate out of range.</b></p>}
              <span><b>Unit Amount: </b>
              { parseFloat(selectedUnit.rate) + (parseFloat(selectedUnit.rate) * (selectedUnit.vat / 100)) - (parseFloat(selectedUnit.rate) * (selectedUnit.wht / 100))}
              </span>
              
              <span><b>VAT%: </b>
              {edit && selectedUnit.status !== "Occupied" && false ? (
              <input type="number" name="vat" className="unitInput" value={selectedUnit.vat} onChange={handleChange} />)
              :(
              selectedUnit.vat
              )}
              </span>
              {vatOutRange && <p style={{color: "red", fontSize: "80%"}}><b>VAT out of range.</b></p>}
              <span><b>WHT%: </b>
              {edit  && selectedUnit.status !== "Occupied" && false ? (
              <input type="number" name="wht" className="unitInput" value={selectedUnit.wht} onChange={handleChange} />)
              :(
              selectedUnit.wht
              )}
              </span>
              {whtOutRange && <p style={{color: "red", fontSize: "80%"}}><b>WHT out of range.</b></p>}
              <span><b>Size: </b>
              {edit ? (
              <input type="text" name="size" className="unitInput" value={selectedUnit.size} onChange={handleChange} />)
              :(
              selectedUnit.size
              )}
              {' '} Sqm</span>
              {sizeOutRange && <p style={{color: "red", fontSize: "80%"}}><b>Size out of range.</b></p>}
            </div>

            <div className="unitInfoBtns">
              {!edit && (
                <button className="unitInfoBtn" id="unitEdit" onClick={() => setEdit(true)}>Edit</button>
              )}

              {edit && (
                <>
                  <button className="unitInfoBtn"
                    id="unitUpdate"
                    onClick={() => {
                      updateUnit(selectedUnit.id, {
                        unitNumber: Number(selectedUnit.unitNumber),
                        type: selectedUnit.type,
                        status: selectedUnit.status,
                        rate: Number(selectedUnit.rate),
                        size: Number(selectedUnit.size),
                        vat: Number(selectedUnit.vat),
                        wht: Number(selectedUnit.wht),
                      });
                    }}>Update</button>
                  <button className="unitInfoBtn" id="unitCancel" onClick={() => cancelEdit()}>Cancel</button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="unit-header"><h2>UNITS</h2></div>
      <div className="unit-content">
        <div className="unit-floors">
        <button className={`floor-buttons ${curFloor == 2 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("2ND FLOOR")
                        setCurFloor(2)
                        setStartRange(201)
                        setEndRange(210)
                    }}>2ND FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 3 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("3RD FLOOR")
                        setCurFloor(3)
                        setStartRange(301)
                        setEndRange(310)
                    }}>3RD FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 4 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("4TH FLOOR")
                        setCurFloor(4)
                        setStartRange(401)
                        setEndRange(410)
                    }}>4TH FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 5 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("5TH FLOOR")
                        setCurFloor(5)
                        setStartRange(501)
                        setEndRange(510)
                    }}>5TH FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 6 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("6TH FLOOR")
                        setCurFloor(6)
                        setStartRange(601)
                        setEndRange(610)
                    }}>6TH FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 7 ? 'floor-buttons--active' : ''} `}
                    onClick={() => {
                        setFloor("7TH FLOOR")
                        setCurFloor(7)
                        setStartRange(701)
                        setEndRange(710)
                    }}>7TH FLOOR &nbsp; &gt;</button>

                    <button className={`floor-buttons ${curFloor == 8 ? 'floor-buttons--active' : ''} `} onClick={() => {
                        setFloor("8TH FLOOR")
                        setCurFloor(8)
                        setStartRange(801)
                        setEndRange(810)
                    }}>8TH FLOOR &nbsp; &gt;</button>
          <div className="unit-box-footer">
            <div className="legend-div">
              <div className="legend" id="avail"></div>
              <span>Available</span>
            </div>
            <div className="legend-div">
              <div className="legend" id="occ"></div>
              <span>Occupied</span>
            </div >
            <div className="legend-div">
              <div className="legend" id="res"></div>
              <span>Reserved</span>
            </div>
            <div className="legend-div">
              <div className="legend" id="notavail"></div>
              <span>Not Available</span>
            </div>
          </div>
        </div>
        <div className="unit-box">
          <div className="units-count">
              <span>Number of Studio: {numberofStudio}</span>
              <span>Number of Residential: {numberofResidential}</span>
              <span>Number of Commercial: {numberOfCommercial}</span>
          </div>
          <div className="unit-box-header">
            <h2>{floor}</h2>
            <p className='unit-box-header-add' onClick={togglePopupForm}>New Unit <i className="fa-solid fa-up-right-from-square"></i></p>
          </div>
          <div className="units">
            {units.map((unit) => (
              curFloor === unit.floor ?
                <div
                  className="unit"
                  key={unit.id}
                  style={{
                    backgroundColor: statusColors[unit.status],
                  }}
                  onClick={() => togglePopupInfo(unit)}
                >
                  <h3 key={unit.id}>{unit.unitNumber}</h3>
                </div>
                : ""
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}