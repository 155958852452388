import React, { useState } from "react";
import addIcon from "../../../global/images2/add-icon.png"
import exitIcon from "../../../global/images2/exit-icon.png"
import { DatePicker } from 'antd';
// import { LoginContext } from "../../../../context/LoginContext";
const { RangePicker } = DatePicker;

export default function Inspection() {

    const [popup, setPopup] = useState(false)
    const [popupInfo, setPopupInfo] = useState(false)

    const togglePopupInfo = () => {
        setPopupInfo(!popupInfo)
    }

    const togglePopupForm = () => {
        setPopup(!popup)
    }

    // const [formData, setformData] = useState(
    //     {
    //         accountNumber: "",
    //         password: "",
    //         email: "",
    //         phoneNum: "",
    //         floor: "",
    //         unit: "",
    //         name: ""
    //     }
    // )

    //Get the user input
    // function handleChange(event){
    //     const {name, value} = event.target
    //     setformData(prevFormData => {
    //         return {
    //             ...prevFormData,
    //             [name]:  value

    //         }
    //     })    

    // }

    function handleSubmit(event) {
        event.preventDefault()

    }
    return (
        <div className="admin-tenants-container">

            {popup && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupForm}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Inspection Form</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
                        </div>
                        <div className="popup-content-complains">

                            <form onSubmit={handleSubmit}>

                                <div className="maintenance-req-input">
                                    <div className="date-input">
                                        <h2>Date of Inspection: </h2>
                                        <input type="date" placeholder="" id="maintenance-req" />
                                    </div>
                                    <div className="name-input">

                                        <div>

                                            <h2>First Name: </h2>
                                            <input type="text" id="maintenance-req" />
                                        </div>

                                        <div>
                                            <h2>Last Name: </h2>
                                            <input type="text" id="maintenance-req" />
                                        </div>
                                    </div>


                                    <div className="unitsubj-input">

                                        <div>
                                            <h2>Location:</h2>
                                            <input type="text" id="maintenance-req" />
                                        </div>


                                    </div>


                                </div>



                                <div className="complaints-desc-input">

                                    <h2>Description:</h2>

                                    <textarea />

                                </div>

                                <div className="btn-div" id="btn-div-complaints">
                                    <button className="createAccBtn" id="sendBtn" onClick={handleSubmit}>Send</button>
                                </div>
                            </form>




                        </div>

                    </div>
                </div>
            )}
            {popupInfo && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupInfo}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Inspection Report</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
                        </div>
                        <div className="popupInfo-content">

                            <span><b>Date:</b>05/18/2023</span>
                            <span><b>Inspection No.:</b> 2</span>
                            <span><b>Location:</b> Elevator</span>
                            <span><b>Subject:</b> Broken</span>
                            <span><b>Description:</b> The elevator is inoperable since it is stuck on the third floor.</span>

                        </div>
                    </div>
                </div>
            )}
            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>INSPECTION</h1>

                </div>

                <div className="tenants-addSearch-div">

                    <div className="left-header">
                        <img src={addIcon} alt="Add Icon" className="add-icon" onClick={togglePopupForm} />

                        <div style={{ display: !popup ? 'block' : 'none' }}>
                            <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                                <RangePicker className="rangedate" />
                            </div>
                        </div>




                    </div>
                    <div className="right-header">
                        <div className="entries">
                            <span>Show</span>

                            <form>
                                <select>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </form>
                            <span>Entries</span>
                        </div>
                        <form>
                            <input type="text" className="searchbar" />
                        </form>
                    </div>


                </div>

                <div className="admin-table-div">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>05/18/2023</td>
                                <td>Elevator</td>
                                <td>Broken</td>
                                <td><button className="action-buttons" onClick={togglePopupInfo}>View</button><button className="action-buttons">Download</button></td>

                            </tr>



                            <tr>
                                <td>04/12/2022</td>
                                <td>3rd Floor Stair Case</td>
                                <td>Blinking Lights</td>
                                <td><button className="action-buttons">View</button><button className="action-buttons">Download</button></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}