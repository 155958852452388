import React, { useContext } from 'react'
// import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'
// import { auth } from '../firebase-config';
// import {onAuthStateChanged} from "firebase/auth"
import { LoginContext } from '../context/LoginContext';

export default function PrivateRoutes() {
    // eslint-disable-next-line
    const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext)
    // const navigate = useNavigate()
    // useEffect(()  => {
    //     console.log(authUser);
    // }, [authUser])

    return (
        isLoggedIn ? <Outlet /> : <Navigate to='/' />
    )
}




