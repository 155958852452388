import React, { useContext, useState, useEffect } from "react";
import { LoginContext } from "../../../../context/LoginContext";
import exitIcon from "../../../global/images2/exit-icon.png"
import { DatePicker } from 'antd';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { getTenantName, getFloor, parseCustomDate } from "../../../../utils";

const { RangePicker } = DatePicker;
export default function CashierLedger(){
    const { tenantsLedger, tenants } = useContext(LoginContext)
    const [popupInfo, setPopupInfo] = useState(false)
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + 1);
    const [startDate, setStartDate] = useState(new Date("2020-1-10"));
    const [endDate, setEndDate] = useState(nextMonthDate);
    const [filteredLedger, setFilteredLedger] = useState([]);

    const togglePopupInfo = () => {
        setPopupInfo(!popupInfo)
    }

    /* OLD
    const rebuildLedger = () => {
        return tenantsLedger.map((ledger) => ({
            unit: ledger.unit,
            tenantId: ledger.tenantID,
            name: `${tenants.find((tenant) => tenant.userID === ledger.tenantID)?.firstName} ${tenants.find((tenant) => tenant.userID === ledger.tenantID)?.lastName}`,
            status: ledger.status,
            balance: ledger.monthlyBalance,
            dueDate: new Date(ledger.dueDate),
        }));
    }

    const displayedLedger = rebuildLedger().filter((ledger) => {
        return ledger.dueDate >= startDate && ledger.dueDate <= endDate
    });
    */

    // Filter by selected date range and add additional properties
    // const displayedLedger = tenantsLedger.filter(ledger => ledger.dueDate >= startDate && ledger.dueDate <= endDate).slice(0, 10)
    const displayedLedger = tenantsLedger.slice(0, 10)
        .map((ledger) => ({
            ...ledger,
            floor: getFloor(ledger.unit),
            name: getTenantName(ledger.tenantID, tenants)
        }));

    // Sort ledger by dueDate then monthlyBalance and lastly the status
    displayedLedger.sort((a, b) => {
        const date = new Date(a.dueDate);
        const nextDate = new Date(b.dueDate);

        if (date > nextDate) {
            return 1;
          } else if (date < nextDate) {
            return -1;
          } else if (a.monthlyBalance > b.monthlyBalance) {
            return -1;
          } else if (a.monthlyBalance < b.monthlyBalance) {
            return 1;
          } else if (a.status === 'Pending' && b.status !== 'Pending') {
            return 1;
          } else if (a.status !== 'Pending' && b.status === 'Pending') {
            return -1;
          } else {
            // If dueDate, balance, and status are the same, they are considered equal
            return 0;
          }
    });

    if(displayedLedger && displayedLedger.length > 0 && filteredLedger.length == 0) setFilteredLedger(displayedLedger.slice());

    // Calendar Date Range Filter
    useEffect(() => {
        const filtered = displayedLedger.filter(ledger => {
            const parsedTransactionDate = parseCustomDate(ledger.dueDate);
            // ledger transactionDate is within the selected date range
            return parsedTransactionDate >= startDate && parsedTransactionDate <= endDate;
        });
        setFilteredLedger(filtered);
    }, [startDate, endDate])

    const handleDateChange = (dateArr) => {
        if (dateArr && Array.isArray(dateArr) && dateArr.length === 2) {
            setStartDate(new Date(dateArr[0].toDate().setHours(0,0,0,0))); // set to start of day
            setEndDate(new Date(dateArr[1].toDate().setHours(23, 59, 59, 999))); // set to end of day
        }
    }

    const downloadAsPdf = (data) => {
        const doc = new jsPDF();
        doc.text(`Ledger from ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`, 10, 10);
        doc.autoTable({
            head: [['Unit', 'Name', 'Status', 'Balance', 'Due Date']],
            body: data.map((ledger) => [
                ledger.unit,
                `${ledger.name}`,
                ledger.status,
                `P${ledger.monthlyBalance}`,
                new Date(ledger.dueDate).toLocaleDateString(),
            ]),
        });
        doc.save(`ledger ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}.pdf`);
    }

    return(
        <div className="admin-tenants-container">
            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>LEDGER</h1>
                </div>

                <div className="tenants-addSearch-div">

                    <div className="left-header">
                        <div style={{display: !popupInfo ? 'block' : 'none' }}>
                            <RangePicker className="rangedate" onChange={handleDateChange}/>
                        </div>
                        <button className="pdfBtn" onClick={() => downloadAsPdf(filteredLedger)}>Download Record</button>
                    </div>
                    <div className="right-header">
                        <div className="entries">
                            
                        </div>

                    </div>
                </div>

                <div className="admin-table-div">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Floor</th>
                                <th>Unit</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Balance</th>
                                <th>Due Date</th>

                            </tr>
                        </thead>
                        <tbody>
                            { filteredLedger.map((ledger, index) => (
                                <tr key={`${ledger.tenantID}-${index}`}>
                                    <td>{ledger.floor}</td>
                                    <td>{ledger.unit}</td>
                                    <td>{ledger.name}</td>
                                    <td>{ledger.status}</td>
                                    <td>₱{ledger.monthlyBalance}</td>
                                    <td>{new Date(ledger.dueDate).toLocaleDateString()}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}