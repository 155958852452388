import image from './group-pix.png';

const PopOutImage = ({ action }) => {

  const closeWindow = () => {
    action(false);
  }
  return (
    <div className='pop-out-container'>
      <div className='image-div'>
        <img src={image} alt='Group' />
      </div>
      <button onClick={closeWindow}><i className="fa-solid fa-xmark"></i></button>
    </div>
  )
}

export default PopOutImage;