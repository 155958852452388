import React, { useContext, useState } from "react";
import exitIcon from "../../../global/images2/exit-icon.png"
import diplomatIcon from "../../../global/sidebarImages/diplomat-logo.png"
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import { db } from "../../../../firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import jsPDF from "jspdf";
const { RangePicker } = DatePicker;
export default function AdminWorkOrders() {
  // const {maintenanceCollection, maintenance, setMaintenance, tenants} = useContext(LoginContext)    
  const { maintenance, setMaintenance } = useContext(LoginContext);
  const [popupInfo, setPopupInfo] = useState(false)
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const [chargeValues, setChargeValues] = useState({});

  const togglePopupInfo = (request) => {
    setSelectedWorkOrder(request)
    setPopupInfo(!popupInfo)
  }

  const acceptRequest = async (id) => {
    try {
      const maintenanceDocRef = doc(db, 'maintenance', id);

      await updateDoc(maintenanceDocRef, { status: "Acknowledged" });


      setMaintenance((prevMaintenance) =>
        prevMaintenance.map((request) => (request.id === id ? { ...request, status: "Acknowledged" } : request))
      );

    } catch (error) {
      console.error('Error updating unit:', error);
    }
  }

  const declineRequest = async (id) => {
    try {
      const maintenanceDocRef = doc(db, 'maintenance', id);

      await updateDoc(maintenanceDocRef, { status: "Declined" });


      setMaintenance((prevMaintenance) =>
        prevMaintenance.map((request) => (request.id === id ? { ...request, status: "Declined" } : request))
      );

    } catch (error) {
      console.error('Error updating unit:', error);
    }
  }

  const chargeTenant = async (request) => {
    try {
      const chargeValue = chargeValues[request.id];
      const maintenanceDocRef = doc(db, 'maintenance', request.id);

      if (chargeValue !== undefined && chargeValue !== '') {
        await updateDoc(maintenanceDocRef, { charge: Number(chargeValue) });

        setMaintenance((prevMaintenance) =>
          prevMaintenance.map((prevRequest) =>
            prevRequest.id === request.id ? { ...prevRequest, charge: Number(chargeValue) } : prevRequest
          )
        );
      }


    } catch (error) {
      console.error('Error updating unit:', error);
    }
  }

  const getBackgroundColor = (status) => {
    if (status === "Pending") {
      return "#dce92b3b"
    } else if (status === "Acknowledged") {
      return "#3c912b50"
    } else if (status === "Declined") {
      return "#6e16163d"
    }
  }

  // date, entries, search, pdf, stattus
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [entriesCount, setEntriesCount] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line
  const [searchedmaintenance, setSearchedMaintenance] = useState([]);
  const [pendingStatus, setPendingStatus] = useState(true);
  const [acknowledgedStatus, setAcknowledgedStatus] = useState(false);
  const [declinedStatus, setDeclinedStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * entriesCount;
  const firstIndex = lastIndex - entriesCount;
  const npage = Math.ceil(maintenance.length / entriesCount);
  // eslint-disable-next-line
  const numbers = [...Array(npage + 1).keys()].slice(1);
  // console.log(searchedmaintenance, numbers)

  const displayedMaintenance = maintenance
    .filter((request) => {
      if (!pendingStatus && request.status === 'Pending') {
        return false;
      }
      else if (!acknowledgedStatus && request.status === 'Acknowledged') {
        return false;
      }
      else if (!declinedStatus && request.status === 'Declined') {
        return false;
      }
      if (startDate && endDate) {
        const requestDate = new Date(request.date);
        if (requestDate < startDate || requestDate > endDate) {
          return false;
        }
      }

      if (searchTerm) {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        const allWordsFound = searchTermsArray.every((word) => {
          for (const key in request) {
            if (Object.hasOwnProperty.call(request, key)) {
              const value = request[key];
              if (value && value.toString().toLowerCase().includes(word)) {
                return true;
              }
            }
          }
          return false;
        });
        return allWordsFound;
      }

      return true;
    }).slice(firstIndex, lastIndex);

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleEntriesChange = (event) => {
    setEntriesCount(event.target.value);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterMaintenance(searchTerm);
  };

  const filterMaintenance = (searchTerm) => {
    const searchTermsArray = searchTerm.toLowerCase().split(' ');

    const filteredMaintenance = maintenance.filter((request) => {
      if (!startDate || !endDate) {
        return true;
      }



      const maintenanceDate = new Date(request.date);
      const isWithinDateRange =
        maintenanceDate >= startDate && maintenanceDate <= endDate;

      const allWordsFound = searchTermsArray.every((word) => {
        for (const key in request) {
          if (Object.hasOwnProperty.call(request, key)) {
            const value = request[key];
            if (value && value.toString().toLowerCase().includes(word)) {
              return true;
            }
          }
        }
        return false;
      });

      return isWithinDateRange && allWordsFound;
    });

    setSearchedMaintenance(filteredMaintenance);
  };

  const togglePending = () => {
    setPendingStatus(!pendingStatus);

  };

  const toggleAcknowledged = () => {

    setAcknowledgedStatus(!acknowledgedStatus);

  };

  const toggleDeclined = () => {

    setDeclinedStatus(!declinedStatus);

  };
  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const downloadAsPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [['Date', 'Unit Number', 'Name', 'Subject', 'Description', "Status", "Charge"]],
      body: displayedMaintenance.map((request) => [
        request.date,
        request.unit,
        `${request.firstName} ${request.lastName}`,
        request.subject,
        request.description,
        request.status,
        request.charge,
      ]),
    });

    doc.save('Work Orders Request.pdf');
  };

  const createWorkOrderPDF = (request) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const imageWidth = 100;
    const centerX = (pageWidth - imageWidth) / 2;

    doc.addImage(diplomatIcon, 'PNG', centerX, 10, imageWidth, 0);

    doc.addFont('Arial');
    doc.setFont('Arial');
    doc.setFontSize(20);
    doc.text('Work Order Request', 75, 45);

    doc.setFontSize(16);
    doc.text('Date:', 25, 65);
    doc.text('Unit Number:', 25, 75);
    doc.text('Name:', 25, 85);
    doc.text('Subject:', 25, 95);
    doc.text('Description:', 25, 105);
    doc.text('Resolution:', 25, 115);
    doc.text('_______________________________________________________', 25, 125);
    doc.text('_______________________________________________________', 25, 135);
    doc.text('_______________________________________________________', 25, 145);
    doc.text('_______________________________________________________', 25, 155);
    doc.text('_______________________________________________________', 25, 165);

    // Add your dynamic data here
    doc.setFont('Arial');
    doc.setFontSize(16);
    doc.text(`${request.date}`, 70, 65);
    doc.text(`${request.unit}`, 70, 75);
    doc.text(`${request.firstName} ${request.lastName}`, 70, 85);
    doc.text(`${request.subject}`, 70, 95);
    doc.text(`${request.description}`, 70, 105);

    doc.setFontSize(16);
    doc.text('Total Charge:', 130, 275);

    doc.text("_________", 170, 275);

    doc.save(`WorkOrderRequest_Unit${request.unit}.pdf`);
  };
  return (

    <div className="admin-tenants-container">
      {popupInfo && (
        <div className="popup-form">
          <div className="popup-overlay" onClick={togglePopupInfo}></div>
          <div className="popup-box">

            <div className="popup-header">
              <h1>Work Order Information</h1>
              <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
            </div>
            <div className="popupInfo-content">

              <span><b>Date:</b> {selectedWorkOrder.date}</span>
              <span><b>Unit Number:</b> {selectedWorkOrder.unit}</span>
              <span><b>Name:</b> {`${selectedWorkOrder.firstName} ${selectedWorkOrder.lastName}`}</span>
              <span><b>Subject:</b> {selectedWorkOrder.subject}</span>
              <span><b>Description:</b> {selectedWorkOrder.description}</span>
              {selectedWorkOrder.charge && <span><b>Charge:</b> {selectedWorkOrder.charge}</span>}
            </div>
          </div>
        </div>
      )}
      <div className="admin-tenants-content">
        <div className="tenants-header">
          <h2>WORK ORDERS REQUEST</h2>

        </div>

        <div className="tenants-addSearch-div">

          <div className="left-header">

            <div style={{ display: !popupInfo ? 'block' : 'none' }}>
              <RangePicker className="rangedate" onChange={handleDateChange} />
            </div>


          </div>
          <div className="right-header">
            <div className="entries">
              <span>Show</span>
              <form>
                <select onChange={handleEntriesChange} value={entriesCount}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </form>
              <span>Entries</span>
            </div>
            <form>
              <div className='search-container'>
                <input type="text"
                  className="searchbar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </form>
          </div>


        </div>

        <div className="admin-table-div">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Unit No.</th>
                <th>Name</th>
                <th>Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                displayedMaintenance.map((request) => (
                  <tr key={request.id}>
                    <td style={{ backgroundColor: (pendingStatus && acknowledgedStatus & declinedStatus) ? getBackgroundColor(request.status) : "" }}>{request.date}</td>
                    <td style={{ backgroundColor: (pendingStatus && acknowledgedStatus & declinedStatus) ? getBackgroundColor(request.status) : "" }}>{request.unit}</td>
                    <td style={{ backgroundColor: (pendingStatus && acknowledgedStatus & declinedStatus) ? getBackgroundColor(request.status) : "" }}>{request.firstName} {request.lastName}</td>
                    <td style={{ backgroundColor: (pendingStatus && acknowledgedStatus & declinedStatus) ? getBackgroundColor(request.status) : "" }}>{request.subject}</td>
                    <td style={{ backgroundColor: (pendingStatus && acknowledgedStatus & declinedStatus) ? getBackgroundColor(request.status) : "" }}><button className="action-buttons" onClick={() => togglePopupInfo(request)}>View</button>
                      {request.status === "Pending" ? <><button className="action-buttons" onClick={() => acceptRequest(request.id)}>Accept</button>
                        <button className="action-buttons" onClick={() => declineRequest(request.id)}>Decline</button></> :
                        <>
                          <button className="action-buttons" onClick={() => createWorkOrderPDF(request)}>Download</button>
                          {!request.charge && (
                            <>
                              <button className="action-buttons" onClick={() => chargeTenant(request)}>
                                Charge
                              </button>
                              <input
                                type="number"
                                onChange={(e) => {
                                  const newChargeValues = { ...chargeValues };
                                  newChargeValues[request.id] = e.target.value;
                                  setChargeValues(newChargeValues);
                                }}
                                name="Number"
                                value={chargeValues[request.id] || ""}
                                className="charge-input"
                                placeholder="Charge Rate"
                              />
                            </>
                          )}
                        </>}

                    </td>
                  </tr>

                ))
              }


            </tbody>
          </table>

        </div>
        <div className="pdfBtn-div">
          <div className="statusBtn-div">
            <button
              className={`statusBtn ${pendingStatus ? 'active' : 'inactive'}`}
              onClick={togglePending}
              style={{ backgroundColor: !pendingStatus ? "" : "#dce92b3b" }}
            >
              Pending
            </button>

            <button
              className={`statusBtn ${acknowledgedStatus ? 'active' : 'inactive'}`}
              onClick={toggleAcknowledged}
              style={{ backgroundColor: !acknowledgedStatus ? "" : "#3c912b50" }}
            >
              Acknowledged
            </button>

            <button
              className={`statusBtn ${declinedStatus ? 'active' : 'inactive'}`}
              onClick={toggleDeclined}
              style={{ backgroundColor: !declinedStatus ? " " : "#6e16163d" }}
            >
              Declined
            </button>
          </div>

          <nav className="pagination" id="center-pagination">
            <ul>
              <li className="page-item">
                <a
                  href="*"
                  className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </a>
              </li>
              <li className="page-item">
                <a href="*" className="page-link">
                  {currentPage}
                </a>
              </li>
              <li className="page-item">
                <a
                  href="*"
                  className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                  onClick={nextPage}
                  disabled={currentPage === npage}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>

          <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
        </div>
      </div>
    </div>
  )
}