import React, { useContext, useEffect, useState } from "react";
import { DatePicker } from 'antd';
import { LoginContext } from "../../../../context/LoginContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";
import jsPDF from "jspdf";
const { RangePicker } = DatePicker;
export default function FrontdeskVisitorsLog(){

    const {visitors, setVisitors, visitorsCollection} = useContext(LoginContext)
    const [timeOut, setTimeOut] = useState(new Date().toLocaleTimeString("en-US", { hour12: false }).slice(0, 5))
    const verifyVisitor = async (id) => {
        try {
            const visitorDocRef = doc(db, 'visitors', id);
            
            await updateDoc(visitorDocRef, {status: "Verified"});

            
            setVisitors((prevVisitors) =>
                prevVisitors.map((visitor) => (visitor.id === id ? { ...visitor, status: "Verified" } : visitor))
            );
        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

    const DeclineVisitor = async (id) => {
        try {
            const visitorDocRef = doc(db, 'visitors', id);
            
            await updateDoc(visitorDocRef, {status: "Declined"});

            
            setVisitors((prevVisitors) =>
                prevVisitors.map((visitor) => (visitor.id === id ? { ...visitor, status: "Declined" } : visitor))
            );
        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

    function formatTime(date) {
        return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
    }
    const visitorTimeout = async (id) => {
        try {
            const visitorDocRef = doc(db, 'visitors', id);
            
            await updateDoc(visitorDocRef, {timeOut: formatTime(new Date())});

            
            setVisitors((prevVisitors) =>
                prevVisitors.map((visitor) => (visitor.id === id ? { ...visitor, timeOut: formatTime(new Date())} : visitor))
            );
        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [entriesCount, setEntriesCount] = useState(25);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedVisitors, setSearchedVisitors] = useState([]);
    const [verefiedStatus, setVerefiedStatus] = useState(false)
    const [declinedStatus, setDeclinedStatus] = useState(true)
    const [toVerifyStatus, setToVerifyStatus] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const lastIndex = currentPage * entriesCount;
    const firstIndex = lastIndex - entriesCount;
    const npage = Math.ceil(visitors.length / entriesCount)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const handleDateChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
        }else {
            setStartDate(null);
            setEndDate(null);
        }
    };


    const handleEntriesChange = (event) => {
        setEntriesCount(event.target.value);
    };

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        filterVisitor(searchTerm);     
    };

    const filterVisitor = (searchTerm) => {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        
        const filteredVisitors = visitors.filter((visitor) => {
          if (!startDate || !endDate) {
            return true; // If date range is not set, include the visitor.
          }
      
          const tenantContractDate = new Date(visitor.date);
          const isWithinDateRange =
          tenantContractDate >= startDate && tenantContractDate <= endDate;
      
          // Check if all words in the search term exist in the visitor's fields
          const allWordsFound = searchTermsArray.every((word) => {
            for (const key in visitor) {
              if (Object.hasOwnProperty.call(visitor, key)) {
                const value = visitor[key];
                if (value && value.toString().toLowerCase().includes(word)) {
                  return true; // Include the visitor if any word matches and is within the date range.
                }
              }
            }
            return false;
          });
      
          return isWithinDateRange && allWordsFound;
        });
      
        setSearchedVisitors(filteredVisitors); // Update searchedTenants, not filteredTenants
      };
      
    const displayedVisitors = visitors
    .filter((visitor) => {
        

        if (startDate && endDate) {
            const tenantContractDate = new Date(visitor.date);
            if (tenantContractDate < startDate || tenantContractDate > endDate) {
                return false;
            }
        }

        if (searchTerm) {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        const allWordsFound = searchTermsArray.every((word) => {
            for (const key in visitor) {
            if (Object.hasOwnProperty.call(visitor, key)) {
                const value = visitor[key];
                if (value && value.toString().toLowerCase().includes(word)) {
                return true;
                }
            }
            }
            return false;
        });
        return allWordsFound;
        }

        return true;
    })
    .slice(firstIndex, lastIndex);

    const toggleActive = () => {
        setVerefiedStatus(!verefiedStatus);
        
    };

    const toggleInactive = () => {

        setToVerifyStatus(!toVerifyStatus);
 
    };

    const toggleDeclined = () => {

        setDeclinedStatus(!declinedStatus);
 
    };
    

    const downloadAsPDF = () => {
        const doc = new jsPDF();

    doc.autoTable({
        head: [['Date', 'Name', 'Purpose', 'Unit', 'Time In', 'Time Out']],
        body: displayedVisitors.map((visitor) => [
            visitor.date,
            `${visitor.firstName} ${visitor.lastName}`,
            visitor.purpose,
            visitor.unit,
            visitor.timeIn,
            visitor.timeOut
        ]),
    });

    doc.save('Visitors List.pdf');
    };
    function nextPage() {
        if (currentPage < npage) {
            setCurrentPage(currentPage + 1);
        }
    }

    function prevPage() {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    return(
        <div className="admin-tenants-container">
            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>VISITORS LOG</h1>
                    
                </div>
                
                <div className="tenants-addSearch-div">
                
                    <div className="left-header">
                        

                        <div>
                            <RangePicker className="rangedate" onChange={handleDateChange}/>
                        </div>

                        
                        
                        
                    </div>
                    <div className="right-header">
                        <div className="entries">
                            <span>Show</span>

                            <form>
                                <select onChange={handleEntriesChange} value={entriesCount}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    
                                </select>
                            </form>
                            <span>Entries</span>
                        </div>
                        <form>
                            <input type="text"
                                className="searchbar"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search..." />
                        </form>
                    </div>
                    
                    
                </div>

                <div className="admin-table-div">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Purpose</th>
                                <th>Unit</th>
                                <th>Time In</th>
                                <th>Time Out</th>
                                <th>Action</th>
                            </tr>

                            {
                                displayedVisitors.map((visitor) => (
                                    <tr key={visitor.id}>
                                        <td>{visitor.date}</td>
                                        <td>{visitor.firstName} {visitor.lastName}</td>
                                        <td>{visitor.purpose}</td>
                                        <td>{visitor.unit}</td>
                                        <td>{visitor.timeIn}</td>
                                        <td>{visitor.timeOut}</td>
                                        <td>{
                                        visitor.timeOut === "" && visitor.status !== "Declined" ?
                                        visitor.status === "Pending" ? <><button className="action-buttons" onClick={() => verifyVisitor(visitor.id)}>Verify</button> 
                                        <button className="action-buttons" onClick={() => DeclineVisitor(visitor.id)}>Decline</button></>
                                        
                                        : <button className="action-buttons" onClick={() => visitorTimeout(visitor.id)}>Time Out</button>: ""}</td>
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody>
                            <tr>
                                
                                
                            </tr>
                        </tbody>
                    </table>
                    <div className="pdfBtn-div">
                        

                        <nav className="pagination" id="center-pagination">
                        <ul>
                            <li className="page-item">
                            <a
                                href="#"
                                className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                                onClick={prevPage}
                                disabled={currentPage === 1}
                            >
                                Prev
                            </a>
                            </li>
                            <li className="page-item">
                            <a href="#" className="page-link">
                                {currentPage}
                            </a>
                            </li>
                            <li className="page-item">
                            <a
                                href="#"
                                className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                                onClick={nextPage}
                                disabled={currentPage === npage}
                            >
                                Next
                            </a>
                            </li>
                        </ul>
                        </nav>

                        <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
                    </div>
                </div>
            </div>
        </div>
    )
}