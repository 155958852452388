import React from "react";
import "../../../global/sidebarStyle.css"
import userIcon from "../../../global/sidebarImages/person.png"
import dashboardIcon from "../../../global/images2/dashboard-icon.png"
import reportIcon from "../../../global/images2/reports-icon.png"
import inspectionIcon from "../../../global/images2/inspection-icon.png"
import helpIcon from "../../../global/images2/help-icon.png"
import settingsIcon from "../../../global/images2/settings-icon.png"
import workorderIcon from "../../../global/images2/workorders-icon.png"
import diplomatLogo from "../../../global/sidebarImages/diplomat-logo.png"
import { Link, Outlet, useNavigate} from "react-router-dom";
import {  useContext } from "react";
import { LoginContext } from "../../../../context/LoginContext";


export default function MaintenanceSidebar(){
    const {userData} = useContext(LoginContext)
    const navigate = useNavigate()

    const logOut = () => {
        navigate("/")
    }
    return(
        
        <div className="sidebar-container">
            <div className="adminSidebar">

                <div className="sidebar-content-div">

                    
                    <div className="links-div">
                        <header className="sidebar-header">
                            <img className="user-icon" src={userIcon} alt="User Icon" />

                            <div className="user-info-div">
                                <h2 className="user-info">{userData.name}</h2>
                                <h3 className="user-info">{userData.title}</h3>
                            </div>
                        </header>
                        
                        <ul className="link-list">
                            <Link to="" className="link">
                                <img src={dashboardIcon} className="icon" alt="Dashboard Icon"/>
                                <li>Dashboard</li>
                            </Link>

                            <Link to="inspection" className="link">
                                <img src={inspectionIcon} className="icon" alt="Payment Icon"/>
                                <li>Inspection</li>
                            </Link>

                            <Link to="workorders" className="link">
                                <img src={workorderIcon} className="icon" alt="document Icon"/>
                                <li>Work Orders</li>
                            </Link>

                            <Link to="reports" className="link">
                                <img src={reportIcon} className="icon" alt="Maintenance Icon"/>
                                <li>Reports</li>
                            </Link>

                            <Link to="help" className="link">
                                <img src={helpIcon} className="icon" alt="Help Icon"/>
                                <li>Help</li>
                            </Link>

                            <Link to="settings" className="link">
                            
                                <img src={settingsIcon} className="icon" alt="Settings Icon"/>
                                <li>Settings</li>
                            </Link>
                        
                            
                        </ul>
                         
                    </div>
                    <button onClick={logOut}>Log Out <i className="fa-solid fa-arrow-right-from-bracket"></i></button>

                </div>
            </div>

            <div className="topbar">
                <img src={diplomatLogo} alt="Diplomat Logo"/>
            </div>

            <div className="contentdiv">
                <Outlet />
            </div>

            <div className="footer">
                <div className="address-div">
                    <p><b>Property Address</b> GXMV+CC3, Russel Avenue, Baclaran, Pasay, Kalakhang Maynila</p>   
                </div>
                
            </div>
        </div>
    )
    
}