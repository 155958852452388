import React, { useContext, useState } from "react";
import addIcon from "../../../global/images2/add-icon.png";
import exitIcon from "../../../global/images2/exit-icon.png";
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";
import jsPDF from "jspdf";
const { RangePicker } = DatePicker;
export default function Complaints() {
    const { complaintsCollection, complaints, setComplaints, units } = useContext(LoginContext);
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    // const [occupiedUnit, setOccupiedUnit] = useState(null);
    const [formData, setformData] = useState({
        unit: "",
        subject: "",
        description: "",
    });
    // eslint-disable-next-line
    const [date, setDate] = useState(formatDate(new Date()));
    const [isEmptyField, setIsEmptyField] = useState(false);

    const [popup, setPopup] = useState(false)
    const [popupInfo, setPopupInfo] = useState(false)

    function formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric"
        }).format(date);
    }

    const togglePopupInfo = (complaint) => {
        setSelectedComplaint(complaint)
        setPopupInfo(!popupInfo)
    }

    const togglePopupForm = () => {
        setPopup(!popup)
    }

    const acceptRequest = async (id) => {
        try {
            const complaintsDocRef = doc(db, 'complaints', id);

            await updateDoc(complaintsDocRef, { status: "Acknowledged" });

            setComplaints((prevComplaints) =>
                prevComplaints.map((complaint) => (complaint.id === id ? { ...complaint, status: "Acknowledged" } : complaint))
            );

        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

    const declineRequest = async (id) => {
        try {
            const complaintsDocRef = doc(db, 'complaints', id);

            await updateDoc(complaintsDocRef, { status: "Declined" });


            setComplaints((prevComplaints) =>
                prevComplaints.map((complaint) => (complaint.id === id ? { ...complaint, status: "Declined" } : complaint))
            );

        } catch (error) {
            console.error('Error updating unit:', error);
        }
    }

    function handleChange(event) {
        const { name, value } = event.target
        setformData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value

            }
        })

    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (
            formData.unit.trim() === "" ||
            formData.subject.trim() === "" ||
            formData.description.trim() === ""
        ) {
            setIsEmptyField(true)
            return;
        }
        try {
            const currentTime = new Date();
            currentTime.setMinutes(currentTime.getMinutes() - 1);

            await addDoc(complaintsCollection, {
                date: date,
                unit: formData.unit,
                subject: formData.subject,
                description: formData.description,
                receiver: "Tenant",
            })

            setComplaints((prevComplaints) => [
                ...prevComplaints,
                {
                    date: date,
                    unit: formData.unit,
                    subject: formData.subject,
                    description: formData.description,
                    receiver: "Tenant",
                }
            ])

            setformData({
                unit: "",
                subject: "",
                description: "",
            });

            setPopup(false)
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }
    // date, entries, search, pdf, stattus
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [entriesCount, setEntriesCount] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    // eslint-disable-next-line
    const [searchedmaintenance, setSearchedMaintenance] = useState([]);
    // const [pendingStatus, setPendingStatus] = useState(true)
    // const [acknowledgedStatus, setAcknowledgedStatus] = useState(false)
    // const [declinedStatus, setDeclinedStatus] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [tenantsComplaints, setTenantsComplaints] = useState(false);
    const [sentComplaints, setSentComplaints] = useState(true);
    const lastIndex = currentPage * entriesCount;
    const firstIndex = lastIndex - entriesCount;
    const npage = Math.ceil(complaints.length / entriesCount);
    // const numbers = [...Array(npage + 1).keys()].slice(1)



    const displayedComplaints = complaints
        .filter((complaint) => {
            if (tenantsComplaints && complaint.receiver === "Admin") {
                return false
            } else if (sentComplaints && complaint.receiver === "Tenant") {
                return false
            }
            if (startDate && endDate) {
                const complaintDate = new Date(complaint.date);
                if (complaintDate < startDate || complaintDate > endDate) {
                    return false;
                }
            }


            if (searchTerm) {
                const searchTermsArray = searchTerm.toLowerCase().split(' ');
                const allWordsFound = searchTermsArray.every((word) => {
                    for (const key in complaint) {
                        if (Object.hasOwnProperty.call(complaint, key)) {
                            const value = complaint[key];
                            if (value && value.toString().toLowerCase().includes(word)) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
                return allWordsFound;
            }

            return true;
        }).slice(firstIndex, lastIndex);

    const handleDateChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    const handleEntriesChange = (event) => {
        setEntriesCount(event.target.value);
    };

    const handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
        filterMaintenance(searchTerm);
    };

    const filterMaintenance = (searchTerm) => {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');

        const filteredMaintenance = complaints.filter((complaint) => {
            if ((!startDate || !endDate)) {
                return true;
            }



            const maintenanceDate = new Date(complaint.date);
            const isWithinDateRange =
                maintenanceDate >= startDate && maintenanceDate <= endDate;

            const allWordsFound = searchTermsArray.every((word) => {
                for (const key in complaint) {
                    if (Object.hasOwnProperty.call(complaint, key)) {
                        const value = complaint[key];
                        if (value && value.toString().toLowerCase().includes(word)) {
                            return true;
                        }
                    }
                }
                return false;
            });

            return isWithinDateRange && allWordsFound;
        });

        setSearchedMaintenance(filteredMaintenance);
    };

    const toggleTenantsComplaints = () => {
        setTenantsComplaints(!tenantsComplaints)

    };

    const toggleSentComplaints = () => {

        setSentComplaints(!sentComplaints)

    };

    function nextPage() {
        if (currentPage < npage) {
            setCurrentPage(currentPage + 1);
        }
    }

    function prevPage() {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const downloadAsPDF = (receiver) => {
        const doc = new jsPDF();


        doc.autoTable({
            head: [['Date', 'Unit Number', 'Subject', 'Description']],
            body: displayedComplaints.map((request) => [
                request.date,
                request.unit,
                request.subject,
                request.description,
            ]),
        });

        doc.save('Complaints.pdf');
    };


    return (
        <div className="admin-tenants-container">

            {popup && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupForm}></div>
                    <div className="popup-box" id="popup-maintenance-req">

                        <div className="popup-header" >
                            <h1>Send Tenant Complain</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
                        </div>

                        <div className="popup-content-complains" >

                            <form onSubmit={handleSubmit}>

                                <div className="maintenance-req-input">
                                    <div className="dateUnit-input-div">

                                        <div>
                                            <label>Date: </label>
                                            <input
                                                type="text"
                                                placeholder=""
                                                value={date}
                                                id="maintenance-req"
                                                disabled
                                            />
                                        </div>

                                        <div className="unitsubj-input">
                                            <label>To: </label>
                                            <select
                                                value={formData.unit}
                                                onChange={handleChange}
                                                name="unit"
                                            >
                                                <option value="">--Choose--</option>
                                                {
                                                    units.map((unit) => (
                                                        unit.status === "Occupied" ?
                                                            <option value={unit.unitNumber}>{unit.unitNumber}</option> : ""
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div>


                                        </div>

                                    </div>

                                    <div className="unitsubj-input">

                                        <label>Subject:</label>
                                        <input
                                            type="text"
                                            placeholder="Title"
                                            id="maintenance-req"
                                            onChange={handleChange}
                                            name="subject"
                                            value={formData.subject}
                                        />

                                    </div>
                                </div>
                                <div className="complaints-desc-input">
                                    <label>Description:</label>
                                    <textarea
                                        onChange={handleChange}
                                        name="description"
                                        value={formData.description}
                                    />
                                </div>
                                {isEmptyField && <p className="emptyField">Please answer all the required fields.</p>}

                                <div className="btn-div" id="btn-div-complaints">

                                    <button className="createAccBtn" id="sendBtn" onClick={handleSubmit}>Send</button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            )}

            {popupInfo && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupInfo}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Complaint Information</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
                        </div>
                        <div className="popupInfo-content">

                            <span><b>Date:</b> {selectedComplaint.date}</span>
                            <span><b>Unit Number:</b> {selectedComplaint.unit}</span>
                            <span><b>Subject:</b> {selectedComplaint.subject}</span>
                            <span><b>Description:</b> {selectedComplaint.description}</span>
                        </div>

                    </div>
                </div>
            )}




            <div className="tenants-header">
                <h2>COMPLAINTS</h2>
            </div>

            <div className="tenants-addSearch-div">

                <div className="left-header">
                    <img src={addIcon} alt="Add Icon" className="add-icon" onClick={togglePopupForm} />

                    <div style={{ display: !popup ? 'block' : 'none' }}>
                        <div style={{ display: !popupInfo ? 'block' : 'none' }}>

                            <RangePicker className="rangedate" onChange={handleDateChange} />

                        </div>

                    </div>

                </div>
                <div className="right-header">
                    <div className="entries">
                        <span>Show</span>

                        <form>
                            <select onChange={handleEntriesChange} value={entriesCount}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                            </select>
                        </form>
                        <span>Entries</span>
                    </div>
                    <form>
                        <input type="text"
                            className="searchbar"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search..." />
                    </form>
                </div>

            </div>
            <div className="admin-table-div">
                <table className="admin-table" id="tenant-table">
                    <thead>
                        <tr>
                            <th>Date.</th>
                            <th>Unit Number</th>
                            <th>Subject</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedComplaints.map((tenant) => (
                            <tr key={tenant.id}>
                                <td>{tenant.date}</td>
                                <td>{tenant.unit}</td>
                                <td>{tenant.subject}</td>

                                <td>
                                    <button className="action-buttons" onClick={() => togglePopupInfo(tenant)}>
                                        View
                                    </button>
                                    {
                                        tenant.status === "Pending" ? <>
                                            <button className="action-buttons" onClick={() => acceptRequest(tenant.id)}>Acknowledge</button>
                                            <button className="action-buttons" onClick={() => declineRequest(tenant.id)}>Decline</button>
                                        </> : ""
                                    }



                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>



                <div className="pdfBtn-div">
                    <div className="statusBtn-div">
                        <button
                            className={`statusBtn ${tenantsComplaints ? 'active' : 'inactive'}`}
                            onClick={toggleTenantsComplaints}
                            style={{ backgroundColor: !tenantsComplaints ? "#dce92b3b" : "" }}
                        >
                            Tenants Complaints
                        </button>

                        <button
                            className={`statusBtn ${sentComplaints ? 'active' : 'inactive'}`}
                            onClick={toggleSentComplaints}
                            style={{ backgroundColor: !sentComplaints ? "#3c912b50" : "" }}
                        >
                            Sent Complaints
                        </button>
                    </div>

                    <nav className="pagination" id="center-pagination">
                        <ul>
                            <li className="page-item">
                                <a
                                    href="*"
                                    className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                                    onClick={prevPage}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </a>
                            </li>
                            <li className="page-item">
                                <a href="*" className="page-link">
                                    {currentPage}
                                </a>
                            </li>
                            <li className="page-item">
                                <a
                                    href="*"
                                    className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                                    onClick={nextPage}
                                    disabled={currentPage === npage}
                                >
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>

                    <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
                </div>
            </div>

        </div>
    )
}