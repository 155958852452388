// Note: install this npm packege'npm i react-pdf'

import { pdfjs } from 'react-pdf';
import PdfComp from './pdfComp';
import "./styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const PopOutPDF = ({ action }) => {

  const closeWindow = () => {
    action(false);
  }

  return (
    <div className='pop-out-container'>
      <h1>User Manual</h1>
      <PdfComp />
      <button onClick={closeWindow}><i className="fa-solid fa-xmark"></i></button>
    </div>
  )
}

export default PopOutPDF;