import React, { useContext, useEffect, useState } from "react";
import personIcon from "./images/person.png"
import editIcon from "./images/edit-icon.png"
import "./settingsStyle.css"
import { LoginContext } from "../../../context/LoginContext";
import { auth, db, storage } from "../../../firebase-config";
// eslint-disable-next-line
import { getAuth, updateEmail as firebaseUpdateEmail, updatePassword as firebaseUpdatePassword, sendEmailVerification, verifyBeforeUpdateEmail, updateProfile } from 'firebase/auth';
// import Password from "antd/es/input/Password";
import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export default function Settings() {
  // eslint-disable-next-line
  const { tenantsCollection, usersCollection, tenants, setTenants, user, setUser, admin, setAdmin, employees, setEmployees, vat, setVat, wht, setWht, units, tenantsLedger } = useContext(LoginContext)

  const [activeUser, setActiveUser] = useState("");
  const [role, setRole] = useState('tenant');

  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newNumber, setNewNumber] = useState();
  const [userdbID, setUserdbID] = useState();
  const [mobileNum, setMobileNum] = useState("");
  const [photoURL, setPhotoUrl] = useState(activeUser?.photoURL || personIcon);
  const [photoInput, setPhotoInput] = useState(null);

  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editNumber, setEditNumber] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [numberChanged, setNumberChanged] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [numberError, setNumberError] = useState("");

  const [commercialBaseRate, setCommercialBaseRate] = useState();
  const [residentialBaseRate, setResidentialBaseRate] = useState();
  const [studioBaseRate, setStudioBaseRate] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [surchargeRate, setSurchargeRate] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const studio = units.find((unit) => unit.type === 'Studio' && unit.status !== 'Occupied')
    const residential = units.find((unit) => unit.type === 'Residential' && unit.status !== 'Occupied')
    const commercial = units.find((unit) => unit.type === 'Commercial' && unit.status !== 'Occupied')
    setStudioBaseRate(localStorage.getItem("studioBaseRate"))
    setResidentialBaseRate(localStorage.getItem("commercialBaseRate"))
    setCommercialBaseRate(localStorage.getItem("residentialBaseRate"))
    
    
  }, [units])

  useEffect(() => {
    if (tenantsLedger.length > 0) {
      setSurchargeRate(tenantsLedger[0].surchargeRate || 0);
    }
  }, [tenantsLedger])

  const handleSurchargeRateSubmit = async (event) => {
    event.preventDefault();
    const updatePromises = tenantsLedger.map(async (ledger) => {
      const tenantDocRef = doc(db, 'tenantsLedger', ledger.id);
      const updatedTenant = { ...ledger, surchargeRate: surchargeRate };
      await updateDoc(tenantDocRef, updatedTenant);
    });

    try {
      await Promise.all(updatePromises);
    } catch (error) {
      debugger
    }
  };

  const handleStudioSubmit = async (event) => {
    event.preventDefault();
    const updatePromises = units.map(async (unit) => {
      if (unit.type === 'Studio' && unit.status !== 'Occupied') {
        const unitDocRef = doc(db, 'units', unit.id);
        const updatedUnit = { ...unit, rate: studioBaseRate };
        await updateDoc(unitDocRef, updatedUnit);
      }
      localStorage.setItem('studioBaseRate', studioBaseRate);
    
    
    });

    try {
      await Promise.all(updatePromises);
      setSuccessMessage('Studio Update successful!');
    } catch (error) {
      setSuccessMessage("Error updating rates");
    }
  };

  const handleResidentialSubmit = async (event) => {
    event.preventDefault();
    const updatePromises = units.map(async (unit) => {
      if (unit.type === 'Residential' && unit.status !== 'Occupied') {
        const unitDocRef = doc(db, 'units', unit.id);
        const updatedUnit = { ...unit, rate: residentialBaseRate };
        await updateDoc(unitDocRef, updatedUnit);
      }
      localStorage.setItem('residentialBaseRate', residentialBaseRate);
    });

    try {
      await Promise.all(updatePromises);
      setSuccessMessage('Residential Update successful!');
    } catch (error) {
      setSuccessMessage("Error updating rates");
    }
  };

  const handleCommercialSubmit = async (event) => {
    event.preventDefault();
    const updatePromises = units.map(async (unit) => {
      if (unit.type === 'Commercial' && unit.status !== 'Occupied') {
        const unitDocRef = doc(db, 'units', unit.id);
        const updatedUnit = { ...unit, rate: commercialBaseRate };
        await updateDoc(unitDocRef, updatedUnit);
      }
      localStorage.setItem('commercialBaseRate', commercialBaseRate);
    });

    try {
      await Promise.all(updatePromises);
      setSuccessMessage('Commercial Update successful!');
    } catch (error) {
      setSuccessMessage("Error updating rates");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage("");
    }, 10000);
  }, [successMessage])

  useEffect(() => {
    checkTitle();
    // eslint-disable-next-line
  }, [tenants, admin, employees, activeUser]);


  useEffect(() => {
    if (activeUser) {
      setPhotoUrl(activeUser.photoURL || personIcon);
    }
  }, [activeUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setActiveUser(user);

        const tenant = tenants.find((tenant) => tenant.userID === user.uid);
        if (tenant) {
          setMobileNum(tenant.mobileNum);
        }

      } else {
        setActiveUser(null);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const checkTitle = async () => {

    const user = auth.currentUser;
    // eslint-disable-next-line
    if (user) {
      try {
        // eslint-disable-next-line
        tenants.map((tenant) => {
          if (tenant.userID === activeUser.uid) {
            setRole('tenant');
            setMobileNum(tenant.mobileNum);
          }
          // eslint-disable-next-line
        });
        // eslint-disable-next-line
        admin.map((admin) => {
          if (admin.userID === activeUser.uid) {
            setRole('admin');
            // eslint-disable-next-line
            setMobileNum(admin.mobileNum);
          }
        });
        // eslint-disable-next-line
        employees.map((employee) => {
          if (employee.userID === activeUser.uid) {
            setRole('employee');
            setMobileNum(employee.mobileNum);
          }
        });
      } catch (error) {
        console.error("Error updating email: " + error.message);
      }
    } else {
      console.log("No user is signed in.");
    }
  }
  const changeAvatar = async (file, activeUser) => {
    const user = auth.currentUser;

    if (user && photoInput) {
      try {
        const fileRef = ref(storage, "avatar/" + user.uid + ".png");
        await uploadBytes(fileRef, photoInput);

        const downloadURL = await getDownloadURL(fileRef);

        await updateProfile(user, {
          photoURL: downloadURL,
        });

        setPhotoUrl(downloadURL);

        setPhotoInput(null);
      } catch (error) {
        console.error("Error changing avatar: " + error.message);
      }
    } else {
      console.log("No user is signed in or no file selected.");
    }

  };

  const changeEmail = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        await verifyBeforeUpdateEmail(user, newEmail);

        const updateUserEmail = async (collection, userArray) => {
          const userIndex = userArray.findIndex((userObj) => userObj.userID === user.uid);
          if (userIndex !== -1) {
            const userDocRef = doc(db, collection, userArray[userIndex].id);
            await updateDoc(userDocRef, { email: newEmail });
            setUserdbID(userArray[userIndex].id);
            setEmailChanged(true);
            setEditEmail(false);
          }
        };

        updateUserEmail('tenants', tenants);
        updateUserEmail('admin', admin);
        updateUserEmail('employees', employees);

        setTenants((prevTenants) =>
          prevTenants.map((renter) => (renter.id === userdbID ? { ...renter, email: newEmail } : renter))
        );

        setEmployees((prevEmployees) =>
          prevEmployees.map((employee) => (employee.id === userdbID ? { ...employee, email: newEmail } : employee))
        );
      } catch (error) {
        console.error(error.message);
      }
    } else {
      console.log("No user is signed in.");
    }
  };

  function handlePhotoChange(e) {
    e.preventDefault();
    if (e.target.files[0]) {
      setPhotoInput(e.target.files[0])
    }
  }

  function handlePasswordChange(e) {
    e.preventDefault();
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    // Validate the password here based on your requirements
    if (!isValidPassword(newPasswordValue)) {
      setPasswordError("Password does not meet the requirements.");
    } else {
      setPasswordError("");
    }
  }

  function isValidPassword(password) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }

  const changePassword = async () => {

    const user = auth.currentUser;
    if (admin[0].userID === user.uid) {
      const adminDocRef = doc(db, 'admin', admin[0].id);
      await updateDoc(adminDocRef, { password: newPassword });

      setAdmin((prevAdmin) =>
        [prevAdmin, { password: newPassword }
        ]);
    }
    if (user) {
      if (newPassword !== confirmPassword) {
        setPasswordError("Passwords do not match.");
        return;
      }

      if (!isValidPassword(newPassword)) {
        setPasswordError("Password does not meet the requirements.");
        return;
      }

      try {
        await firebaseUpdatePassword(user, newPassword);

        setPasswordChanged(true)
        setEditPassword(false)
      } catch (error) {
        console.error("Error updating password: " + error.message);

      }
    } else {
      console.log("No user is signed in.");
    }
  };

  function handleMobileNumberChange(e) {
    const inputValue = e.target.value;


    if (/^\d{11}$/.test(inputValue)) {
      setNewNumber(inputValue);
      setNumberError(false)
    }
    else {
      setNumberError("Mobile Number should be 11 digits.")
    }
  }

  async function saveMobileNumber() {
    const user = auth.currentUser;

    if (user) {
      try {

        for (let i = 0; i < tenants.length; i++) {
          if (user.uid === tenants[i].userID) {
            const tenantDocRef = doc(db, 'tenants', tenants[i].id);
            await updateDoc(tenantDocRef, { mobileNum: newNumber });
            if (tenants[i].id) {
              setUserdbID(tenants[i].id);
            } else {
              console.error("tenant id is undefined or null");
            }
            setMobileNum(newNumber)
            setNumberChanged(true)
            setEditNumber(false)

            break;
          }
        }

        for (let i = 0; i < admin.length; i++) {
          if (user.uid === admin[i].userID) {
            const adminDocRef = doc(db, 'admin', admin[i].id);
            await updateDoc(adminDocRef, { mobileNum: newNumber });
            if (admin[i].id) {
              setUserdbID(admin[i].id);
            } else {
              console.error("id is undefined or null");
            }
            setMobileNum(newNumber)
            setNumberChanged(true)
            setEditNumber(false)
            break;
          }
        }

        for (let i = 0; i < employees.length; i++) {
          if (user.uid === employees[i].userID) {
            const employeesDocRef = doc(db, 'employees', employees[i].id);
            await updateDoc(employeesDocRef, { mobileNum: newNumber });
            if (employees[i].id) {
              setUserdbID(employees[i].id);
            } else {
              console.error("id is undefined or null");
            }
            setMobileNum(newNumber)
            setNumberChanged(true)
            setEditNumber(false)
            break;
          }
        }

        setTenants((prevTenants) =>
          prevTenants.map((renter) => (renter.id === userdbID ? { ...renter, mobileNum: newNumber } : renter)
          ));

        setEmployees((prevEmployees) =>
          prevEmployees.map((employee) => (employee.id === userdbID ? { ...employee, mobileNum: newNumber } : employee)
          ));

        setAdmin((prevAdmin) =>
          prevAdmin.map((admin) => (admin.id === userdbID ? { ...admin, mobileNum: newNumber } : admin)
          ));

      } catch (error) {
        console.error("Error updating email: " + error.message);
      }
    } else {
      console.log("No user is signed in.");
    }
  }

  const updateUnitsCollection = async (vat, wht) => {
    const unitsCollection = collection(db, "units");
  
    try {
      // Create a query to get all documents in the "units" collection
      const unitsQuery = query(unitsCollection);
  
      // Get all documents from the "units" collection
      const unitsSnapshot = await getDocs(unitsQuery);
  
      unitsSnapshot.forEach(async (doc) => {
        // Assuming vat and wht are initially defined as strings or other types
        const vatAsNumber = Number(vat);
        const whtAsNumber = Number(wht);
  
        // Check if the conversion to numbers is successful
        if (!isNaN(vatAsNumber) && !isNaN(whtAsNumber)) {
          // Update the document with new VAT and WHT values
          await updateDoc(doc.ref, { vat: vatAsNumber, wht: whtAsNumber });
        } else {
          // Handle the case where vat or wht couldn't be converted to numbers
          console.error("Invalid VAT or WHT values for document with ID:", doc.id);
          // You might want to log an error, throw an exception, or handle it in some way
        }
      });
  
      console.log("VAT and WHT values updated in the units collection.");
    } catch (error) {
      console.error("Error updating units collection:", error.message);
    }
  };
  

  function handleApplyToAll() {
    // Call the updateUnitsCollection function with the current VAT and WHT values
    updateUnitsCollection(vat, wht);
  }
  return (
    <div className="settings-container">
      <div className="settings-content-wrapper">
        <div className="settings-header">
          <h1>Account Settings</h1>
        </div>
        <div className="settings-body">
          <div className="profilepic-div">
            <img src={photoURL} className="profpic" alt="avatar" />
            <form>
              <label for="picupload" className="upload-btn">Change Photo</label>
              <input type="file" id="picupload" accept="image/*" className="upload-input" onChange={handlePhotoChange} />
              {photoInput &&
                <>
                  <label className="upload-btn" onClick={changeAvatar}>Upload</label>
                </>
              }
            </form>
          </div>
          <div className="information-div">
            <div className="users-info">
              <div>
              </div>
              <h2>Name: {localStorage.getItem("userFullName")}</h2>
              <h2>Email: {activeUser ? activeUser.email : ""} {!editEmail && <img src={editIcon} className="edit-icon" alt="Edit Icon" onClick={() => setEditEmail(!editEmail)} />}</h2>
              {!activeUser ? <span><b>You must re-login because your email address has been updated.</b></span> : ""}
              {emailChanged && <>
                <p className="success">Please check the link verification sent to your email to completely update your email.</p></>}
              {editEmail && <div className="settings-edit-div">
                <input
                  type="email"
                  placeholder="New Email"
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <button onClick={changeEmail}>Save</button>
                <button onClick={() => setEditEmail(false)}>Cancel</button>
              </div>}
              <h2>Password:  {!editPassword && <img src={editIcon} className="edit-icon" alt="Edit Icon" onClick={() => setEditPassword(!editPassword)} />}</h2>
              {passwordChanged && <><p className="success">Your password was successfully changed.</p></>}
              {editPassword && <div className="settings-edit-div">
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={handlePasswordChange}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="password-reqs-div">
                  <div className="password-reqs-box">
                    <span>Password must contain:</span>
                    <span>8 Characters.</span>
                    <span>Uppercase letters: A-Z.</span>
                  </div>
                  <div className="password-reqs-box">
                    <span>Lowercase letters: a-z.</span>
                    <span>Numbers: 0-9.</span>
                    <span>One special character.</span>
                  </div>
                </div>
                <p>{passwordError}</p>
                <button onClick={changePassword}>Save</button>
                <button onClick={() => setEditPassword(false)}>Cancel</button>
              </div>}
              <h2>Mobile Number: {mobileNum}
                {!editNumber && <img src={editIcon} className="edit-icon" alt="Edit Icon" onClick={() => setEditNumber(!editNumber)} />}</h2>
              {numberChanged && <><p className="success">Your mobile number was successfully changed.</p></>}
              {editNumber && <div className="settings-edit-div">
                <input
                  type="text"
                  placeholder="New Number"
                  value={newNumber}
                  onChange={handleMobileNumberChange}
                />
                <span>{numberError}</span>
                <button onClick={saveMobileNumber}>Save</button>
                <button onClick={() => setEditNumber(false)}>Cancel</button>
              </div>}
            </div>
          </div>
        </div>
        <br/>
        {role === 'admin' && <div className="settings-tax">
          <div className="settings-header">
            <h1>Tax Settings</h1>
            <div className="settings-tax-fields">
              <div>
                <label>VAT%:</label>
                <input
                  type="number"
                  onChange={(event) => { setVat(event.target.value); localStorage.setItem('vat', event.target.value) }}
                  value={vat}
                  placeholder="VAT in %"
                />
              </div>

              <div>
                <label>WHT%:</label>
                <input
                  type="number"
                  value={wht}
                  onChange={(event) => { setWht(event.target.value); localStorage.setItem('wht', event.target.value) }}
                  placeholder="WHT in %"
                />
              </div>

              <div>
                <button onClick={handleApplyToAll} className="action-buttons">Apply to All</button>
              </div>
            </div>
          </div>
          <div className="settings-header">
            <h1>Base Rate</h1>
            <div> { successMessage } </div>
            <div className="settings-tax-fields">
              <div>
                <label>Studio:</label>
                <input
                  type="number"
                  onChange={(event) => { setStudioBaseRate(event.target.value) }}
                  value={studioBaseRate}
                  placeholder="Studio Base Rate"
                />

                <div className="settings-submit-container">
                  <button className="action-buttons" onClick={handleStudioSubmit}>Submit Studio</button>
                </div>

              </div>
              <div>
                <label>Residential:</label>
                <input
                  type="number"
                  onChange={(event) => { setResidentialBaseRate(event.target.value) }}
                  value={residentialBaseRate}
                  placeholder="Residential Base Rate"
                />

                <div className="settings-submit-container">
                  <button className="action-buttons" onClick={handleResidentialSubmit}>Submit Residential</button>
                </div>

              </div>
              <div>
                <label>Commercial:</label>
                <input
                  type="number"
                  onChange={(event) => { setCommercialBaseRate(event.target.value) }}
                  value={commercialBaseRate}
                  
                  placeholder="Commercial Base Rate"
                />

                <div className="settings-submit-container">
                  <button className="action-buttons" onClick={handleCommercialSubmit}>Submit Commercial</button>
                </div>
              </div>
            </div>
          </div>

          <div className="settings-header">
            <h1>Surcharge Rate Settings</h1>
            <div> { successMessage } </div>
            <div className="settings-tax-fields">
              <div>
                <label>Surcharge Rate in Percentage:</label>
                <input
                  type="number"
                  onChange={(event) => { setSurchargeRate(event.target.value) }}
                  value={surchargeRate}
                  placeholder="Surcharge Rate"
                />
                <div className="settings-submit-container">
                  <button className="action-buttons" onClick={handleSurchargeRateSubmit}>Submit Surcharge</button>
                </div>

              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}