import React, { useContext, useEffect, useState } from "react";
import diplomatLogo from "../../../global/sidebarImages/diplomat-logo.png"
// import editbtn from "../../../global/settings/images/edit-icon.png"

import "./paymentStyle.css"
// eslint-disable-next-line
import { LoginContext, units } from "../../../../context/LoginContext";
import { doc, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase-config";
// import Ledger from "../../tenants/ledger/ledger";
import Loader from "../../../sammarc/loader";
export default function CashierPayment() {
  const { tenantsLedger, tenantsLedgerCollection, units, setTenantsLedger, tenants } = useContext(LoginContext)
  const today = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = today.toLocaleDateString(undefined, options);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    referenceNumber: "",
    unit: "",
    floor: ""
  });

  const [unitAmount, setUnitAmount] = useState(0);
  const [unitPayment, setUnitPayment] = useState(0);
  const [unitBalance, setUnitBalance] = useState(0);
  const [unitVat, setUnitVat] = useState(0);
  const [unitWHT, setUnitWHT] = useState(0);
  const [unitTotalAmount, setUnitTotalAmount] = useState(0);
  const [parkingAmount, setParkingAmount] = useState(0);
  const [parkingVat, setParkingVat] = useState(0);
  const [parkingWHT, setParkingWHT] = useState(0);
  const [parkingTotalAmount, setParkingTotalAmount] = useState(0);
  const [occupiedUnits, setOccupiedUnits] = useState([]);
  // eslint-disable-next-line
  const [editMode, setEditMode] = useState(false);
  const [VATPercentage, setVATPercentage] = useState(0);
  const [WHTPercentage, setWHTPercentage] = useState(0);
  const [currentUnit, setCurrentUnit] = useState(0);
  const [cusaAmount, setCusaAmount] = useState("");
  const [overallAmount, setOverallAmount] = useState(0);
  const [overallAmountHolder, setOverallAmountHolder] = useState(0);
  const [cusaVat, setCusaVat] = useState(0);
  const [cusaWht, setCusaWht] = useState(0);
  const [cusaTotalAmount, setCusaTotalAmount] = useState(0);
  const [amountReceived, setAmountReceived] = useState("");
  const [cubicMeterUsed, setCubicMeterUsed] = useState(0);
  const [amountPerCubicMeter, setAmountPerCubicMeter] = useState(0);
  const [waterBillTotalAmount, setWaterBillTotalAmt] = useState(0);
  const [otherCosts, setOtherCosts] = useState(0);
  // eslint-disable-next-line
  const [tenantID, setTenantID] = useState("");
  // eslint-disable-next-line
  const [currentMonth, setCurrentMonth] = useState("");
  // eslint-disable-next-line
  const [currentYear, setCurrentYear] = useState("");

  const [loading, setLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [surchargeField, setSurchargeField] = useState(0);


  function occupiedUnit(floor) {

    const occupiedUnit = units.filter(
      (unit) => unit.floor === Number(floor) && unit.status === "Occupied"
    )
    setOccupiedUnits(occupiedUnit)

  }
  useEffect(() => {
    if (formData.unit) {
      getContents(formData.unit);
    }
    // eslint-disable-next-line
  }, [cusaAmount]);
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === 'floor' || name === 'unit') resetFields();

    // eslint-disable-next-line
    const unitAvailable = name === "floor" ? occupiedUnit(value) : value;

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });

    if (name === "unit") {
      getContents(value);
    }
  };
  // eslint-disable-next-line
  function formatNumberWithCommas(number) {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  const resetFields = () => {
    // TenantID
    setTenantID('');

    // Tax
    setVATPercentage(0);
    setWHTPercentage(0);
    setUnitVat(0);
    setUnitWHT(0);

    // Unit
    setUnitBalance(0);
    setUnitTotalAmount(0);
    setUnitAmount(0)
    // Parking
    setParkingTotalAmount(0);
    setParkingAmount(0);
    setParkingVat(0);
    setParkingWHT(0);

    // TODO: Water Bill

    // Other Costs
    setOtherCosts(0);
    setSurchargeField(0);

    // Overall
    setOverallAmount(0);
    setOverallAmountHolder(0);

    formData.firstName = ""
    formData.lastName = ""
  }

  const handleOtherCosts = (e) => {
    e.preventDefault();
    setOtherCosts(parseFloat(e.target.value));

    const total = parseFloat(overallAmountHolder) + parseFloat(waterBillTotalAmount) + (e.target.value.toString().length > 0 ? parseFloat(e.target.value) : 0);
    setOverallAmount(parseFloat(total).toFixed(2).toLocaleString());
  }

  const handleCubicMeterUsed = (e) => {
    e.preventDefault();
    setCubicMeterUsed(parseFloat(e.target.value));

    const computation = parseFloat(e.target.value * (amountPerCubicMeter || 0));
    setWaterBillTotalAmt(computation);

    const total = parseFloat(parseFloat(overallAmountHolder) + parseFloat(otherCosts) + computation);
    setOverallAmount(total.toFixed(2).toLocaleString());
  }

  const handleAmountPerCubicMeter = (e) => {
    e.preventDefault();
    setAmountPerCubicMeter(parseFloat(e.target.value));

    const computation = parseFloat(e.target.value * (cubicMeterUsed || 0));
    setWaterBillTotalAmt(computation);

    const total = parseFloat(parseFloat(overallAmountHolder) + otherCosts + computation);
    setOverallAmount(total.toFixed(2).toLocaleString());
  }

  const printPaymentData = (e) => {
    setIsPrinting(true);
    e.preventDefault();
    setTimeout(() => {
      let printContents = document.getElementById('receipt-print').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      setIsPrinting(false);
    }, 50);
    
  }

  const getContents = async (unit) => {
    setCurrentUnit(unit);
    // eslint-disable-next-line
    /*
    let monthlyRate = 0
    let monthlyBalance = 0
    let parkingRate = 0
    let vat = 0
    let wht = 0
    let unitVatAmount = 0
    let unitWhtAmount = 0
    let baseRate = 0
    let totalAmountPaid = 0
    */
    const today = new Date("6/28/2024");
    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);

    const currentMonth = nextMonth.toLocaleString('default', { month: 'long' });
    setCurrentMonth(currentMonth)

    const currentYear = nextMonth.getFullYear();
    setCurrentYear(currentYear)

    // Filter the Pending/Overdue Ledger of the current paying unit
    const pendingTenantsLedger = tenantsLedger.filter(ledger => ledger.unit == unit && (ledger.status === 'Pending' || ledger.status === 'Overdue'));
    const paidTenantsLedger = tenantsLedger.filter(ledger => ledger.unit == unit && (ledger.status === 'Partial Payment'));
    let ledger = null;
    let paidLedger = null;

    if (pendingTenantsLedger.length > 0) {
      // Sort the unit's ledger by dueDate and monthlyBalance
      ledger = pendingTenantsLedger.sort((a, b) => {
          const date = new Date(a.dueDate);
          const nextDate = new Date(b.dueDate);

          // Compare dueDate first
          if (date > nextDate) {
              return 1;
          } else if (date < nextDate) {
              return -1;
          } else {
              // If dueDate is the same, compare by monthlyBalance
              return b.monthlyBalance - a.monthlyBalance;
          }
      })[0];
    }

    tenants.map((tenant) => {
      if (tenant.unit === unit) {
        formData.firstName = tenant.firstName
        formData.lastName = tenant.lastName
      }
    })

    
    if (ledger) {
      const rentalBalanceWithVAT = parseFloat(ledger.baseRate) + parseFloat(ledger.vatAmount) - parseFloat(ledger.whtAmount);
      const parkingvatAmount = (parseFloat(ledger.vat) / 100) * parseFloat(ledger.parkingBalance);
      const parkingwhtAmount = (parseFloat(ledger.wht) / 100) * parseFloat(ledger.parkingBalance);
      const parkingBaseAmount = parseFloat(ledger.parkingBalance) - parseFloat(parkingvatAmount) + parseFloat(parkingwhtAmount);
      const surcharge = (!ledger.surcharge && ledger.surcharge != 0) && today > new Date(ledger.dueDate) ? calculateSurcharge(ledger) : 0;
      let overallAmount = parseFloat(rentalBalanceWithVAT + ledger.parkingBalance + otherCosts + waterBillTotalAmount + surcharge);
      // const unitPayment = parseFloat(overallAmount - (parseFloat(ledger.monthlyBalance) + surcharge));
      let unitPayment = 0;
      if (paidTenantsLedger.length > 0) {
        // Sort the unit's ledger by dueDate and monthlyBalance
        paidLedger = paidTenantsLedger.sort((a, b) => {
            const date = new Date(a.dueDate);
            const nextDate = new Date(b.dueDate);

            // Compare dueDate first
            if (date > nextDate) {
                return 1;
            } else if (date < nextDate) {
                return -1;
            } else {
                // If dueDate is the same, compare by monthlyBalance
                return b.monthlyBalance - a.monthlyBalance;
            }
        });

        paidLedger = paidLedger.filter(l => l.dueDate == ledger.dueDate);

        if (paidLedger.length > 0) {
          unitPayment = parseFloat(paidLedger.reduce(
            (accumulator, currentValue) => accumulator + currentValue.payment,
            0,
          ));
          overallAmount -= unitPayment;
        }
      }

      if (parseFloat(ledger.monthlyBalance) > overallAmount) {
        overallAmount += (parseFloat(ledger.monthlyBalance) - overallAmount);
      }

      // TenantID
      setTenantID(ledger.tenantID);

      // Tax
      setVATPercentage(ledger.vat.toFixed(2).toLocaleString());
      setWHTPercentage(ledger.wht.toFixed(2).toLocaleString());
      setUnitVat(ledger.vatAmount.toFixed(2).toLocaleString());
      setUnitWHT(ledger.whtAmount.toFixed(2).toLocaleString());

      // Unit
      setUnitAmount(ledger.baseRate.toFixed(2).toLocaleString());
      setUnitPayment(unitPayment.toFixed(2).toLocaleString());
      setUnitBalance(ledger.monthlyBalance.toFixed(2).toLocaleString());
      setUnitTotalAmount(rentalBalanceWithVAT.toFixed(2).toLocaleString());

      // Parking
      setParkingTotalAmount(ledger.parkingBalance.toFixed(2).toLocaleString());
      setParkingAmount(parkingBaseAmount.toFixed(2).toLocaleString());
      setParkingVat(parkingvatAmount.toFixed(2).toLocaleString());
      setParkingWHT(parkingwhtAmount.toFixed(2).toLocaleString());

      // Surcharge
      setSurchargeField(surcharge);

      // Overall
      setOverallAmount(overallAmount.toFixed(2).toLocaleString());
      setOverallAmountHolder(overallAmount.toFixed(2).toLocaleString());
    }

    /* OLD
    await tenantsLedger.map((ledger) => {
      if ((unit === ledger.unit && (ledger.status === "Pending" || ledger.status === "Overdue"))) {
        monthlyRate += ledger.monthlyRate
        parkingRate += ledger.parkingBalance
        unitVatAmount += ledger.vatAmount
        unitWhtAmount += ledger.whtAmount
        vat = ledger.vat
        wht = ledger.wht
        baseRate += ledger.baseRate
        totalAmountPaid += ledger.payment
        setTenantID(ledger.tenantID)
      }
    })

    const rentalRateWithVAT = baseRate + unitVatAmount - unitWhtAmount;
    const rentalBalanceWithVAT = monthlyBalance + unitVatAmount - unitWhtAmount;
    const parkingwhtAmount = (wht / 100) * parkingRate;
    const parkingvatAmount = (vat / 100) * parkingRate;

    // Add VAT and WHT to the monthlyRate
    const parkingBaseAmount = parkingRate - parkingvatAmount + parkingwhtAmount;

    const cusaVat = (vat / 100) * Number(cusaAmount);
    const cusaWht = ((wht - 3) / 100) * Number(cusaAmount);
    const cusaTotal = Number(cusaAmount) + cusaVat - cusaWht;
    const overallAmount = rentalRateWithVAT + parkingRate + cusaTotal - totalAmountPaid;
    setCusaVat(cusaVat.toFixed(2).toLocaleString());
    setCusaWht(cusaWht.toFixed(2).toLocaleString());
    setCusaTotalAmount(cusaTotal.toFixed(2).toLocaleString());

    setVATPercentage(vat.toFixed(2).toLocaleString())
    setWHTPercentage(wht.toFixed(2).toLocaleString())
    setUnitAmount(baseRate.toFixed(2).toLocaleString());

    setUnitBalance(monthlyBalance.toFixed(2).toLocaleString());

    // setUnitTotalAmount(rentalRateWithVAT.toFixed(2).toLocaleString());
    setUnitTotalAmount(rentalBalanceWithVAT.toFixed(2).toLocaleString());
    setUnitVat(unitVatAmount.toFixed(2).toLocaleString());
    setUnitWHT(unitWhtAmount.toFixed(2).toLocaleString());


    setParkingAmount(parkingBaseAmount.toFixed(2).toLocaleString());
    setParkingVat(parkingvatAmount.toFixed(2).toLocaleString());
    setParkingWHT(parkingwhtAmount.toFixed(2).toLocaleString());
    setParkingTotalAmount(parkingRate.toFixed(2).toLocaleString());

    setOverallAmount(overallAmount.toFixed(2).toLocaleString());
    */
  }

  const calculateMonthsDifference = (currentDate, givenDate) => {
    const diffInMonths = (givenDate.getFullYear() - currentDate.getFullYear()) * 12 +
      givenDate.getMonth() - currentDate.getMonth();
    return diffInMonths;
  }

  const calculateSurcharge = (ledger) => {
    const today = new Date();
    // Set surcharge for late payments
    const addSurcharge = (!ledger.surcharge && ledger.surcharge != 0) && today > new Date(ledger.dueDate);
    const penaltyMonths = Math.abs(calculateMonthsDifference(new Date(), new Date(ledger.dueDate))) + 1;
    // Surcharge Fee is controlled in admin settings or 3% of Monthly Rate multiplied by count of Months not fully paid
    const surchargeRate = (parseFloat(ledger.surchargeRate) / 100) || 0.03;
    const surchargeValue =  penaltyMonths * (ledger.monthlyRate * surchargeRate);
    const surcharge = addSurcharge ? surchargeValue : 0;
    setSurchargeField(surcharge);
    return surcharge;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let remainingPayment = Number(amountReceived);

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: Months are 0-based, so we add 1.
    const year = today.getFullYear();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const hours = String(today.getHours());
    const minutes = String(today.getMinutes());
    const seconds = String(today.getSeconds());
    const transactionDate = `${year}-${months[month - 1]}-${day} ${hours}:${minutes}:${seconds}`;

    const promises = [];

    // Filter the Pending/Overdue Ledger of the current paying unit
    const currentTenantsLedger = tenantsLedger.filter(ledger => ledger.unit == currentUnit && (ledger.status === 'Pending' || ledger.status === 'Overdue'));

    // Sort the unit's ledger by dueDate and monthlyBalance
    currentTenantsLedger.sort((a, b) => {
        const date = new Date(a.dueDate);
        const nextDate = new Date(b.dueDate);

        // Compare dueDate first
        if (date > nextDate) {
            return 1;
        } else if (date < nextDate) {
            return -1;
        } else {
            // If dueDate is the same, compare by monthlyBalance
            return b.monthlyBalance - a.monthlyBalance;
        }
    });

    // Loop through unit's ledger
    currentTenantsLedger.map(ledger => {
        if (remainingPayment == 0) return;

        let ledgerDocRef = doc(db, 'tenantsLedger', ledger.id);
        const newBalance = ledger.monthlyBalance - remainingPayment;

        // Set surcharge for late payments
        const addSurcharge = (!ledger.surcharge && ledger.surcharge != 0) && new Date() > new Date(ledger.dueDate);
        const surcharge = calculateSurcharge(ledger);

        // Set the monthlyBalance to the correct amount
        const newMonthlyBalance = newBalance + surcharge;

        // Set the status as Paid if the balance is 0
        const status = newBalance <= 0 ? 'Paid' : 'Partial Payment';

        const payment = newBalance <= 0 ? remainingPayment - Math.abs(newBalance) : remainingPayment;

        // Set the Excess Payment to be deducted on the next ledger
        if (newBalance <= 0) remainingPayment = Math.abs(newBalance);
        else {
            remainingPayment = 0;

            // Create new ledger transaction for next payment on the same dueDate
            let newLedger = { ...ledger };

            newLedger.monthlyBalance = newMonthlyBalance + waterBillTotalAmount + otherCosts;
            newLedger.transactionDate = '';
            newLedger.status = 'Pending';
            newLedger.payment = '';
            newLedger.surcharge = 0;

            // Remove id property because we will create a new row
            delete newLedger.id;

            // push the add promise to the array for faster and optimized way of adding the doc
            promises.push(addDoc(tenantsLedgerCollection, newLedger))
        }

        let updateDocObj = {
          referenceNumber: formData.referenceNumber,
          monthlyBalance: ledger.monthlyBalance + waterBillTotalAmount + otherCosts + ((!ledger.surcharge && ledger.surcharge != 0) ? surcharge : 0),
          transactionDate: transactionDate,
          status: status,
          payment: payment
        }

        // for backwards compatibility
        if (ledger.hasOwnProperty('surcharge')) {
          updateDocObj['surcharge'] = addSurcharge ? surcharge : ledger.surcharge;
        }

        // push the update promise to the array for faster and optimized way of updating the doc
        promises.push(
          updateDoc(ledgerDocRef, updateDocObj)
        );

    });
    setLoading(true);

    // Run the promises
    // Will run all of the updateDoc asynchronously
    Promise.all(promises).then(() => {
      // Reload the page after all promises are done
      window.location.reload();
    });

    /** OLD
    e.preventDefault();
    let paymentReceived = Number(amountReceived);
    const currentTenantLedger = tenantsLedger.filter((ledger) => ledger.unit === currentUnit).slice().sort((a, b) => {
      return new Date(a.dueDate) - new Date(b.dueDate);
    });

    currentTenantLedger.forEach(async (ledger) => {
      const ledgerDocRef = doc(db, 'tenantsLedger', ledger.id);
      if (ledger.monthlyBalance !== 0 && paymentReceived !== 0) {
        const monthlyBalance = ledger.monthlyBalance - paymentReceived;
        let ledgerObject = {}
        if (monthlyBalance <= 0) {
          // update this to carry over the payment to the next month and set status to paid
          ledgerObject = {
            monthlyBalance: 0,
            transactionDate: new Date().toISOString().split('T')[0],
            status: "Paid",
            payment: ledger.monthlyRate,
          };
          paymentReceived = paymentReceived - ledger.monthlyBalance;
        }
        else if (monthlyBalance > 0) {
          ledgerObject = {
            monthlyBalance: monthlyBalance,
            transactionDate: new Date().toISOString().split('T')[0],
            status: "Pending",
            payment: paymentReceived + (parseInt(ledger.payment) || 0),
          };
          paymentReceived = 0;
        }
        await updateDoc(ledgerDocRef, ledgerObject);
        setTenantsLedger((prevTenantsLedger) => [
          ...prevTenantsLedger,
          ledgerObject
        ]);
      }
    });
    setLoading(true)

    setTimeout(() => {

      window.location.reload(false);
    }, 1000);
    */
  };

  return (
    <div className="cashier-payment-container">
      {loading && <Loader />}
      <div className="cashier-payment-content">
        <div className="cashier-payment-header">
          <h1>HANDLE A PAYMENT</h1>
        </div>
        <div className="cashier-payment-receipt" id="receipt-print">
          <div className="receipt-header">
            <img src={diplomatLogo} className="receipt-logo" alt='receipt-logo' />
          </div>
          <div className="receipt-body">
            <div className="receipt-form-div">
              <form className="receipt-form">
                <div id="print-receipt-container">
                  <div className="receipt-inputs">
                    <div className="name-input">
                      <label>Cashier:</label>
                      <div className="name-input-fields">
                        <input type="text" placeholder="First Name" value={localStorage.getItem("userFullName")} disabled />

                      </div>
                    </div>
                    <div className="date-input">
                      <div>
                        <label>Date:</label>
                        <input type="text"
                          value={formattedDate}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="receipt-col">
                    <div className="receipt-col-contents">
                      <h3>UNIT</h3>
                      <div className="receipt-col-div">
                        <div className="receipt-labels">
                          <label>Reference Number:</label>
                          <label>First Name:</label>
                          <label>Last Name:</label>
                          <label>Floor:</label>
                          <label>Unit:</label>
                          <label>Base Rate:</label>
                          <label>VAT:</label>
                          <label>WHT:</label>
                          <label>Total Amount:</label>
                        </div>
                        <div className="receipt-col-inputs">
                          <input
                            type="text"
                            placeholder=""
                            name="referenceNumber"
                            value={formData.referenceNumber}
                            onChange={handleInputChange}
                          />
                          <input type="text" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                          <input type="text" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleInputChange} />
                          {isPrinting && <input
                            type="number"
                            value={formData.floor}
                            disabled
                          />}
                          {!isPrinting && <select
                            name="floor"
                            value={formData.floor}
                            onChange={handleInputChange}
                          >
                            <option value="">--Choose--</option>
                            <option value="2">Second</option>
                            <option value="3">Third</option>
                            <option value="4">Fourth</option>
                            <option value="5">Fifth</option>
                            <option value="6">Sixth</option>
                            <option value="7">Seventh</option>
                            <option value="8">Eight</option>
                          </select>}
                          {isPrinting && <input
                            type="number"
                            value={formData.unit}
                            disabled
                          />}
                          {!isPrinting && <select
                            name="unit"
                            value={formData.unit}
                            onChange={handleInputChange}
                          >
                            <option value="">--Choose--</option>
                            {occupiedUnits.map((unit) => (
                              <option key={unit.unitNumber} value={unit.unitNumber}>{unit.unitNumber}</option>
                            ))}
                          </select>}
                          <input
                            type="number"
                            value={unitAmount}
                            disabled
                          />
                          <div className="tax-div">
                            <input type="number" disabled className="tax-input"
                              value={unitVat}
                            />
                            <div className="tax-edit">
                              <span>+{VATPercentage}%</span>
                            </div>
                          </div>
                          <div className="tax-div">
                            <input type="number" disabled className="tax-input" value={unitWHT} />
                            <span>-{WHTPercentage}%</span>
                          </div>
                          <input type="number" disabled value={unitTotalAmount} />
                        </div>
                      </div>
                    </div>
                    <div className="receipt-col-contents">
                      <h3>PARKING</h3>
                      <div className="receipt-col-div">
                        <div className="receipt-labels">
                          <label>Base Rate:</label>
                          <label>VAT:</label>
                          <label>WHT:</label>
                          <label>Total Amount:</label>
                        </div>
                        <div className="receipt-col-inputs">

                          <input type="text"
                            value={parkingAmount}
                            disabled
                          />
                          <div className="tax-div">
                            <input type="number" disabled className="tax-input" value={parkingVat} />
                            <span>+{VATPercentage}%</span>
                          </div>
                          <div className="tax-div">
                            <input type="number" disabled className="tax-input" value={parkingWHT} />
                            <span>-
                              {WHTPercentage}%</span>
                          </div>
                          <input type="number" disabled value={parkingTotalAmount} />
                        </div>
                      </div>
                    </div>
                    <div className="receipt-col-contents">
                      <h3>WATER BILL</h3>
                      <div className="receipt-col-div">
                        <div className="receipt-labels">
                          <label>Cubic Meter Used:</label>
                          <label>Amount / Cubic Meter:</label>
                          <label>Total Amount:</label>
                        </div>
                        
                        <div className="receipt-col-inputs">
                          <input
                            type="number"
                            value={cubicMeterUsed}
                            onChange={ handleCubicMeterUsed }
                          />
                          <input
                            type="number"
                            value={amountPerCubicMeter}
                            onChange={ handleAmountPerCubicMeter }
                          />
                          <input
                            type="number"
                            value= { waterBillTotalAmount }
                            disabled
                          />
                          {isPrinting && <div className="authorized">
                            <br></br><br></br>
                        <h4>_______<u>{localStorage.getItem("userFullName")}</u>______</h4>
                        <h5>Authorized By</h5>
                      </div>}
                        </div>
                        
                      </div>
                    </div>
                    
                    <div className="receipt-col-contents">
                      <div className="overall-amount">
                        
                        <div className="overall-contents">
                        
                          <label>Other Costs:</label>
                          <input type="number" id="otherCosts" value={otherCosts} onChange={handleOtherCosts} />
                          <label>Already Paid:</label>
                          <input type="number" id="alreadyPaid" disabled value={unitPayment} />
                          <label>Surcharge:</label>
                          <input type="number" id="surchargeField" disabled value={surchargeField || 0} />
                          <label>Overall Amount Balance: </label>
                          <input type="number" disabled value={overallAmount} />
                          <label>Amount Received: </label>
                          <input type="number" id="amountReceived" value={amountReceived} onChange={(e) => setAmountReceived(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="receipt-btn-div">
                  <div className="saveprint">
                    <button className="receipt-btn" onClick={handleSubmit}>Save</button>
                  </div>
                  <div className="printpayment">
                    <button className="receipt-btn" onClick={(e) => { printPaymentData(e); }}>Print</button>
                  </div>
                  <button className="receipt-btn">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}