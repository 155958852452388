import React, { useContext, useState } from "react";
import exitIcon from "../../../global/images2/exit-icon.png"
// import { db } from "../../../../firebase-config";
import { LoginContext } from "../../../../context/LoginContext";
// import { addDoc, doc, updateDoc } from "firebase/firestore";
export default function Unit() {
  // const {units, setUnits, unitsCollection} = useContext(LoginContext)
  const { units, } = useContext(LoginContext)
  const [floor, setFloor] = useState("2ND FLOOR")
  const [curFloor, setCurFloor] = useState(2)
  const [selectedUnit, setSelectedUnit] = useState(null);
  // eslint-disable-next-line
  const [edit, setEdit] = useState(false);
  const [popupInfo, setPopupInfo] = useState(false)

  const togglePopupInfo = (unit) => {
    setPopupInfo(!popupInfo)
    setSelectedUnit(unit)
    setEdit(false)
  }

  const statusColors = {
    "Not Available": "gray",
    Reserved: "blue",
    Occupied: "#6b1313",
    Available: "green",
  };

  return (
    <div className="adminUnitContainer">
      {popupInfo && (
        <div className="popup-form">
          <div className="popup-overlay" onClick={togglePopupInfo}></div>
          <div className="popup-box" >
            <div className="popup-header">
              <h1>Unit Information</h1>
              <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
            </div>
            <div className="popupInfo-content" >
            <span><b>Unit Number: </b> 
                            {edit && selectedUnit.status !== "Occupied"? (
                            <input 
                            type="number" 
                            name="unitNumber" 
                            className="unitInput"
                            value={selectedUnit.unitNumber} 
                             
                            />) 
                            :(
                            selectedUnit.unitNumber
                            )}
                            </span>
                            <span><b>Type: </b> 
                            {edit ? (
                            <select name="type" className="unitInput" value={selectedUnit.type} >
                                <option value="Studio">Studio</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Residential">Residential</option>
                            </select>
                            ) : (
                            selectedUnit.type
                            )}
                            </span>
                            
                            <span><b>Status: </b> 
                            {edit && selectedUnit.status !== "Occupied" ? (
                            <select name="status" className="unitInput" value={selectedUnit.status} >
                                <option value="Available">Available</option>
                                <option value="Occupied">Occupied</option>
                                <option value="Reserved">Reserved</option>
                                <option value="Not Available">Not Available</option>
                            </select>
                            ) : (
                            selectedUnit.status
                            )}   
                            </span>


                            <span><b>Base Rate(₱): </b> 
                            {edit && selectedUnit.status !== "Occupied" ?  (
                            <input type="number" name="rate" className="unitInput" value={selectedUnit.rate}  />) 
                            :(
                            selectedUnit.rate
                            )}
                            </span>
      
                            <span><b>VAT%: </b> 
                            {edit && selectedUnit.status !== "Occupied" ? (
                            <input type="number" name="vat" className="unitInput" value={selectedUnit.vat}  />) 
                            :(
                            selectedUnit.vat
                            )}
                            </span>

                            <span><b>WHT%: </b> 
                            {edit  && selectedUnit.status !== "Occupied" ? (
                            <input type="number" name="wht" className="unitInput" value={selectedUnit.wht}  />) 
                            :(
                            selectedUnit.wht
                            )}
                            </span>

                            <span><b>Size: </b> 
                            {edit ? (
                            <input type="text" name="size" className="unitInput" value={selectedUnit.size}  />) 
                            :(
                            selectedUnit.size  
                            )}
                            {' '} Sqm</span>
 
            </div>
          </div>
        </div>
      )}
      <div className="unit-header"><h2>UNITS</h2></div>
      <div className="unit-content">
        <div className="unit-floors">
          <button className="floor-buttons"
            onClick={() => {
              setFloor("2ND FLOOR")
              setCurFloor(2)
            }}>2ND FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons"
            onClick={() => {
              setFloor("3RD FLOOR")
              setCurFloor(3)
            }}>3RD FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons"
            onClick={() => {
              setFloor("4TH FLOOR")
              setCurFloor(4)
            }}>4TH FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons"
            onClick={() => {
              setFloor("5TH FLOOR")
              setCurFloor(5)
            }}>5TH FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons"
            onClick={() => {
              setFloor("6TH FLOOR")
              setCurFloor(6)
            }}>6TH FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons"
            onClick={() => {
              setFloor("7TH FLOOR")
              setCurFloor(7)
            }}>7TH FLOOR &nbsp; &gt;</button>
          <button className="floor-buttons" onClick={() => {
            setFloor("8TH FLOOR")
            setCurFloor(8)
          }}>8TH FLOOR &nbsp; &gt;</button>
          <div className="unit-box-footer">
            <div className="legend-div">
              <div className="legend" id="avail"></div>
              <span>Available</span>
            </div>
            <div className="legend-div">
              <div className="legend" id="occ"></div>
              <span>Occupied</span>
            </div >
            <div className="legend-div">
              <div className="legend" id="res"></div>
              <span>Reserved</span>
            </div>
            <div className="legend-div">
              <div className="legend" id="notavail"></div>
              <span>Not Available</span>
            </div>
          </div>
        </div>
        <div className="unit-box">
          <div className="unit-box-header">
            <h2>{floor}</h2>
          </div>
          <div className="units">
            {units.map((unit) => (
              curFloor === unit.floor ?
                <div
                  className="unit"
                  key={unit.id}
                  style={{
                    backgroundColor: statusColors[unit.status],
                  }}
                  onClick={() => togglePopupInfo(unit)}
                >
                  <h3>{unit.unitNumber}</h3>
                </div>
                : ""
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}