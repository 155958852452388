// Get the tenant's name by tenantID in tenants collection
export const getTenantName = (tenantID, tenants) => {
    const tenant = tenants.filter(tenant => tenant.userID == tenantID);
    return `${tenant ? tenant[0].firstName : ''} ${tenant ? tenant[0].lastName : ''}`;
}

// Get the correct floor prefix by text format
export const getFloor = (unit) => {
    const floors = [
        {
            name: 'First',
            prefix: '1'
        },
        {
            name: 'Second',
            prefix: '2'
        },
        {
            name: 'Third',
            prefix: '3'
        },
        {
            name: 'Fourth',
            prefix: '4'
        },
        {
            name: 'Fifth',
            prefix: '5'
        },
        {
            name: 'Sixth',
            prefix: '6'
        },
        {
            name: 'Seventh',
            prefix: '7'
        },
        {
            name: 'Eight',
            prefix: '8'
        }
    ]

    const prefix = unit.toString().charAt(0);
    return floors.filter(f => f.prefix == prefix)[0]['name'];
}

// Parse new format of date with backwards compatibility
export const parseCustomDate = (dateString) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const [date, time] = dateString.split(' ');
    const [year, month, day] = date.split('-');
    const monthIndex = months.indexOf(month);

    // for valid dates
    if (monthIndex === -1) {
        return new Date(dateString);
    }
    
    // if date has time included
    if (time) {
        const [hour, minute, second] = time.split(':');
        return new Date(year, monthIndex, day, hour, minute, second);
    }

    // e.g. 2023-11-25
    return new Date(`${year}-${monthIndex}-${day}`);
}