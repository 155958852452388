import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyAmAWfJ7MVoU_ktd_PTsApOalewzmYUwrU",
  authDomain: "authentication-35f85.firebaseapp.com",
  projectId: "authentication-35f85",
  storageBucket: "authentication-35f85.appspot.com",
  messagingSenderId: "1006534317623",
  appId: "1:1006534317623:web:c6e93400177cb63365d1b2",
  measurementId: "G-BZNXNLL4RC",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);

// const analytics = getAnalytics(app);