import React, { useContext, useEffect, useState } from "react";
import diplomatLogo from "../../global/sidebarImages/diplomat-logo.png"
import "./visitorStyle.css"
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase-config";
import { LoginContext } from "../../../context/LoginContext";
import { useNavigate } from "react-router-dom";
export default function Visitor(){
    const {tenantsCollection,usersCollection, tenants,setTenants,user, setUser, units, setUnits, visitorsCollection, visitors, setVisitors} = useContext(LoginContext)
    
    const [date, setDate] = useState(formatDate(new Date()));
    const [timeIn, setTimeIn] = useState(formatTime(new Date()));
    const [empty, setEmpty] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        floor: "",
        unit: "",
        purpose: "",
        timeOut: ""
    });

    function formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric"
        }).format(date);
      }
    
      function formatTime(date) {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        }).format(date);
      }

    
    function capitalizeWords(str) {
        // Split the string by spaces and capitalize each word
        const words = str.split(" ");
        const capitalizedWords = words.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        
        // Join the capitalized words back into a single string with spaces
        return capitalizedWords.join(" ");
    }

    function handleChange(event){
        const {name, value} = event.target
        const containsNumbers = /\d/.test(value);
        const containsSpecialCharacters = /[!@#$%^&*()_+{}/"'=\[\]:;<>,.?~\\]/.test(value);

        if (containsNumbers && (name === 'firstName' || name === 'lastName') || containsSpecialCharacters && (name === 'firstName' || name === 'lastName')) {
        // If the input contains numbers and it's the first name or last name field, don't update the state
        return;
        }
        
        const formattedValue = name === "firstName" || name === "lastName" ? capitalizeWords(value) : value;

        
        
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]:  formattedValue
                
            }
        })   
          
    
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            if(formData.firstName === "" ||
            formData.lastName === "" ||
            formData.purpose === "" ||
            formData.unit === "" ||
            formData.timeIn === "" 
                
            ){
                setEmpty(true)
            }else{
                await addDoc(visitorsCollection, {
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    date: date,
                    timeIn: timeIn,
                    floor: formData.floor,
                    unit: formData.unit,
                    purpose: formData.purpose,
                    timeOut: "",
                    status: "Pending"
                });
                
                setVisitors((prevVisitors) => [
                    ...prevVisitors,
                    {
                        ffirstName: formData.firstName,
                        lastName: formData.lastName,
                        date: date,
                        timeIn: timeIn,
                        floor: formData.floor,
                        unit: formData.unit,
                        purpose: formData.purpose,
                        timeOut: "",
                        status: "Pending"
                    }
                ])
                
                setFormData({
                    firstName: "",
                    lastName: "",
                    floor: "",
                    unit: "",
                    purpose: ""
                });
            }
            
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };


    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate("/")
    }

    return(
        
        <div className="visitor-container">
            <div className="visitor-content">

                <div className="topbar">
                    <img src={diplomatLogo} alt="Diplomat Logo"/>
                </div>
                
                <div className="visitor-body">
                    <div className="visitor-header">
                        <h1>Visitors Form</h1>
                    </div>
                    <div className="visitor-form-div">
                        <form className="visitor-form">
                            <div className="visitor-form-left">
                                
                                <label>First Name:</label>
                                <input 
                                type="text"
                                onChange={handleChange}
                                name="firstName"
                                value={formData.firstName}
                                
                                />

                                <label>Last Name:</label>
                                <input 
                                type="text"
                                onChange={handleChange}
                                name="lastName"
                                value={formData.lastName}
                                
                                />

                                <label>Date:</label>
                                <input 
                                type="text"
                                autoFocus
                                value={date}
                                onChange={handleChange}
                                disabled
                                />

                                <label>Time in:</label>
                                <input 
                                type="text"
                                value={timeIn}
                                onChange={handleChange}
                                disabled
                                />
                            </div>
                            <div className="visitor-form-right">
                                <label>Floor:</label>
                                <select
                                onChange={handleChange}
                                name="floor"
                                value={formData.floor}
                                
                                >
                                    <option value="">--Choose--</option>
                                    <option value="2">Second</option>
                                    <option value="3">Third</option>
                                    <option value="4">Fourth</option>
                                    <option value="5">Fifth</option>
                                    <option value="6">Sixth</option>
                                    <option value="7">Seventh</option>
                                    <option value="8">Eigth</option>
                                </select>

                                <label>Unit:</label>
                                <select
                                onChange={handleChange}
                                name="unit"
                                value={formData.unit}
                                >
                                <option value="">--Choose--</option>
                                    {units.map((unit) => (
                                        unit.floor === Number(formData.floor) ?
                                        <option value={unit.unitNumber}>{unit.unitNumber}</option> : ""
                                    ))}
                                    
                                </select>
                                
                                <label>Purpose:</label>
                                <select
                                onChange={handleChange}
                                name="purpose"
                                value={formData.purpose}
                                >
                                <option value="">--Choose--</option>
                                <option value="Occular">Occular</option>
                                <option value="Visit">Visit</option>
                                    
                                    
                                </select>
                                <div className="btn-div" id="visitor-btn-div">
                                    <button className="createAccBtn" id="visitor-form-submit" onClick={handleSubmit}>Submit</button>
                                </div>
                                
                                </div>
                        </form>
                        {empty &&  <span id="incorrectInp" style={{display: empty ? 'block' : 'none' }}>Please answer all fields.</span>}

                    </div>
                    <div className="back-to-login">
                        <button className="login"  onClick={handleNavigate}>Back to Log in form</button>
                    </div>
                </div>
            </div>
        </div>
    )
}