import React, { useEffect, useState } from "react";
import tenantsIcon from "./image/tenants.png"
import employeesIcon from "./image/employees.png"
import maintenanceIcon from "./image/workorders.png"
import tenantsMaintenanceIcon from "./image/maintenance.png"
import collectionIcon from "./image/collectionIcon.png"
import ledgerIcon from "./image/ledger.png"
import unitIcon from "./image/unit.png"
import occupiedIcon from "./image/occupied.png"
import complaintIcon from "./image/complaint.png"
// import paymentIcon from "./image/payment.png"
import visitorslogIcon from "./image/visitorslog.png"
import exitIcon from "../../global/images2/exit-icon.png"
import "./dashboardStyle.css"

import { useContext } from "react";
import { LoginContext } from "../../../context/LoginContext";
import { auth } from "../../../firebase-config";
import { addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";


export default function Dashboard() {

  const { tenants, admin, employees, maintenance, units, complaints, announcements, setAnnouncements, announcementsCollection, visitors, tenantsLedger } = useContext(LoginContext)
  const [activeUser, setActiveUser] = useState("")
  const [title, setTitle] = useState("")
  const [tenantCount, setTenantCount] = useState(0)
  const [employeeCount, setEmployeeCount] = useState(0)
  const [workordersCount, setWorkordersCount] = useState(0)
  const [unitCount, setUnitCount] = useState(0)
  const [occupiedUnitCount, setOccupiedUnitCount] = useState(0)
  const [pendingComplaintsCount, setPendingComplaintsCount] = useState(0)
  const [tenantMaintenanceCount, setTenantMaintenanceCount] = useState(0)
  const [tenantComplaintsCount, setTenantComplaintsCount] = useState(0)
  const [visitorsCount, setVisitorsCount] = useState(0)
  const [popup, setPopup] = useState(false)
  const [isEmptyField, setIsEmptyField] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null)
  const [formData, setformData] = useState({
    receiver: "Everyone",
    subject: "",
    description: "",
  });
  useEffect(() => {
    checkTitle()
    // eslint-disable-next-line
  }, [tenants, admin, employees, maintenance, units, complaints, activeUser]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setActiveUser(user);
      } else {
        setActiveUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkTitle = async () => {

    const user = auth.currentUser;
    let tenantCount = 0;
    let employeeCount = 0;
    let workordersCount = 0;
    let unitCount = 0;
    let occupiedUnitCount = 0;
    let complaintCount = 0;
    let visitorsCount = 0;
    let tenantRequestCount = 0;
    let tenantComplaintCount = 0;
    if (user) {

      try {

        const getTenantAnnouncementsOnDue = () => {
          // Display an announcement for tenant if due is within 15 days
          tenantsLedger
            .filter(v =>
              !v.payment &&
              v.tenantID == activeUser.uid &&
              ((new Date(v.dueDate)).getTime() - (new Date()).getTime() <= (15*24*60*60*1000)) &&
              ((new Date(v.dueDate)).getTime() - (new Date()).getTime() >= 0)
            )
            .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
            .map(v => {
              announcements.push({
                receiver: "Tenants",
                subject: `Friendly Reminder that your balance of P${v.monthlyBalance} is due on ${v.dueDate}`,
                description: `This is a friendly reminder that your balance of P${v.monthlyBalance} is due on ${v.dueDate}.
                  Please ensure that you settle your bill on or before ${v.dueDate}. Thank you.`,
              })
            });

          // Display an announcement for tenant with past due (at least 1 day after due date)
          tenantsLedger
            .filter(v =>
              !v.payment &&
              v.tenantID == activeUser.uid &&
              ((new Date()).getTime() - (new Date(v.dueDate)).getTime() >= (1*24*60*60*1000))
            )
            .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
            .map(v => {
              announcements.push({
                receiver: "Tenants",
                subject: `Friendly Reminder that your balance of P${v.monthlyBalance} is past its due date of ${v.dueDate}`,
                description: `We wanted to remind you that your balance of P${v.monthlyBalance} is past its due date on ${v.dueDate}.
                  Please take care of this payment as soon as possible. Thank you.`,
              })
            });
        };

        tenants.map((tenant) => {
          if (tenant.status === "Active") {
            tenantCount++;
          }

          if (tenant.userID === activeUser.uid) {

            setTitle("Tenant");
            getTenantAnnouncementsOnDue();
          }
        });

        setTenantCount(tenantCount);
        admin.map((admin) => {
          if (admin.userID === activeUser.uid) {
            setTitle("Admin");
          }
        });

        employees.map((employee) => {
          employeeCount++
          if (employee.userID === activeUser.uid) {

            if (employee.title === "Cashier") {
              setTitle("Cashier");
            } else if (employee.title === "Chief Security Guard") {
              setTitle("Cfg");
            }

          }
        });
        // eslint-disable-next-line
        setEmployeeCount(employeeCount)

        maintenance.map((request) => {

          if (request.status === "Pending" && request.senderID === activeUser.uid) {
            tenantRequestCount++
          }
          if (request.status === "Pending") {
            workordersCount++
          }
        });
        setTenantMaintenanceCount(tenantRequestCount)
        setWorkordersCount(workordersCount)

        units.map((unit) => {
          unitCount++
          if (unit.status === "Occupied") {
            occupiedUnitCount++
          }
        });
        setUnitCount(unitCount)
        setOccupiedUnitCount(occupiedUnitCount)

        complaints.map((complaint) => {

          if (complaint.status === "Pending" && complaint.senderID === activeUser.uid) {
            tenantComplaintCount++
          }
          if (complaint.status === "Pending") {
            complaintCount++
          }
        });
        setTenantComplaintsCount(tenantComplaintCount)
        setPendingComplaintsCount(complaintCount)


        visitors.map((visitor) => {
          if (!visitor.verify) {
            visitorsCount++
          }
        });
        setVisitorsCount(visitorsCount)
      } catch (error) {
        console.error("Error updating email: " + error.message);
      }
    } else {
      console.log("No user is signed in.");
    }
  }

  const togglePopupForm = () => {
    setPopup(!popup)
  }

  function handleChange(event) {
    const { name, value } = event.target
    setformData(prevFormData => {
      return {
        ...prevFormData,
        [name]: value

      }
    })

  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (
      formData.receiver.trim() === "" ||
      formData.subject.trim() === "" ||
      formData.description.trim() === ""
    ) {
      setIsEmptyField(true)
      return;
    }
    try {
      const htmlDescription = formData.description.replace(/\n/g, "<br>");

      await addDoc(announcementsCollection, {
        receiver: formData.receiver,
        subject: formData.subject,
        description: htmlDescription,
        createdAt: new Date()
      })

      setAnnouncements((prevAnnouncements) => [
        ...prevAnnouncements,
        {
          receiver: formData.receiver,
          subject: formData.subject,
          description: formData.description,
        }
      ])

      setformData({
        receiver: "Everyone",
        subject: "",
        description: "",
      });

      setPopup(false)
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  const selectedAnnDesc = (announcement) => {
    if (selectedAnnouncement === announcement) {
      setSelectedAnnouncement(null);
    } else {
      setSelectedAnnouncement(announcement);
    }
  }

  const currentDate = new Date();

  // Set the startDate to the beginning of the current month
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Set the endDate to the end of the current month
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const getTotalPaymentForCashier = () => {
    let totalPayment = 0;
    // Get the current date

    tenantsLedger.filter((ledger) => {
      return ledger.transactionDate && (new Date(ledger.transactionDate) >= startDate && new Date(ledger.transactionDate) <= endDate)
    }).map((ledger) => {
      totalPayment += parseInt(ledger.payment) || 0;
    })
    return totalPayment.toLocaleString();
  }
  const getPendingForThisMonth = () => {
    const pendingLedgers = tenantsLedger.filter((ledger) => {
      return ledger.status === "Pending" && (new Date(ledger.transactionDate) >= startDate && new Date(ledger.transactionDate) <= endDate)
    })
    return pendingLedgers.length;
  }

  const getOverDueForThisMonth = () => {
    const overDueLedgers = tenantsLedger.filter((ledger) => {
      return (ledger.status === "Pending" || ledger.status === "Overdue") && (new Date(ledger.transactionDate) <= startDate)
    })
    return overDueLedgers.length;
  }

  const getRemainingBalance = () => {
    const currentTenantLedger = tenantsLedger.filter((ledger) => {
      if (!activeUser) {
        return false;
      }
      if (ledger.tenantID !== activeUser.uid) {
        return false;
      }
      return true;
    })

    let totalBalance = 0;
    currentTenantLedger.map((ledger) => {
      totalBalance += parseInt(ledger.monthlyBalance) || 0;
    })
    return totalBalance.toLocaleString();
  }

  return (
    <div className="dashboard-container">
      {popup && (
        <div className="popup-form">
          <div className="popup-overlay" onClick={togglePopupForm}></div>
          <div className="popup-box" id="popup-maintenance-req">

            <div className="popup-header" >
              <h1>Send Announcement</h1>
              <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
            </div>

            <div className="popup-content-complains" >

              <form onSubmit={handleSubmit}>

                <div className="maintenance-req-input">
                  <div className="dateUnit-input-div">

                    <div className="unitsubj-input">
                      <label>To: </label>
                      <select
                        value={formData.receiver}
                        onChange={handleChange}
                        name="receiver"
                      >
                        <option
                          value="Everyone"
                        >Everyone</option>
                        <option
                          value="Tenants"
                        >Tenants</option>
                        <option
                          value="Chief Security Guard"
                        >Chief Security Guard</option>
                        <option
                          value="Cashier"
                        >Cashier</option>
                      </select>
                    </div>

                  </div>

                  <div className="unitsubj-input">

                    <label>Subject:</label>
                    <input
                      type="text"
                      placeholder="Title"
                      id="maintenance-req"
                      onChange={handleChange}
                      name="subject"
                      value={formData.subject}
                    />

                  </div>
                </div>
                <div className="complaints-desc-input">
                  <label>Description:</label>
                  <textarea
                    onChange={handleChange}
                    name="description"
                    value={formData.description}
                    placeholder="Type your message..."
                  />
                </div>
                {isEmptyField && <p className="emptyField">Please answer all the required fields.</p>}

                <div className="btn-div" id="btn-div-complaints">

                  <button className="createAccBtn" id="sendBtn" onClick={handleSubmit}>Send</button>
                </div>
              </form>



            </div>

          </div>
        </div>
      )}
      {title && title === "Admin" &&
        <>
          <Link to="tenants" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{tenantCount}</h2>
                <h3>Active Tenants</h3>
              </div>

              <div>
                <img src={tenantsIcon} className="box-icon" />
              </div>
            </div>
          </Link>
          <Link to="employees" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{employeeCount}</h2>
                <h3>Employees</h3>
              </div>

              <div>
                <img src={employeesIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="workorders" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{workordersCount}</h2>
                <h3>Pending Work Orders</h3>
              </div>

              <div>
                <img src={maintenanceIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="unit" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{unitCount}</h2>
                <h3>Units</h3>
              </div>

              <div>
                <img src={unitIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="unit" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{occupiedUnitCount}</h2>
                <h3>Occupied Units</h3>
              </div>

              <div>
                <img src={occupiedIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="complaints" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{pendingComplaintsCount}</h2>
                <h3>Pending Complaints</h3>
              </div>

              <div>
                <img src={complaintIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <div className="announcement-card">
            <div className="announcement-header">
              <h2>Announcement</h2>

              {title === "Admin" ? <button className="announcement-add" onClick={togglePopupForm}>ADD</button> : ""}

            </div>

            <div className="announcements-div">
              {announcements.map((announcement) => (
                <div className="announcement-div" onClick={() => selectedAnnDesc(announcement)}>
                  <div className="announcement-header">
                    <h3>{announcement.subject}</h3>
                    <h2>{selectedAnnouncement === announcement ? "▲" : "▼"}</h2>
                  </div>
                  {selectedAnnouncement === announcement && (
                    <div className="announcement-desc">{announcement.description}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>}

      {title && title === "Cfg" &&
        <>
          <Link to="unit" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{unitCount}</h2>
                <h3>Units</h3>
              </div>

              <div>
                <img src={unitIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="unit" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{occupiedUnitCount}</h2>
                <h3>Occupied Units</h3>
              </div>

              <div>
                <img src={occupiedIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="visitorslog" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{visitorsCount}</h2>
                <h3>Visitors to Verify</h3>
              </div>

              <div>
                <img src={visitorslogIcon} className="box-icon" />
              </div>
            </div>
          </Link>
          <div className="announcement-card">
            <div className="announcement-header">
              <h2>Announcement</h2>

              {title === "Admin" ? <button className="announcement-add" onClick={togglePopupForm}>ADD</button> : ""}

            </div>

            <div className="announcements-div">
              {announcements.map((announcement) => (
                (announcement.receiver === "Everyone" || announcement.receiver === "Chief Security Guard") && (
                  <div className="announcement-div" key={announcement.id} onClick={() => selectedAnnDesc(announcement)}>
                    <div className="announcement-header">
                      <h3>{announcement.subject}</h3>
                      <h2>{selectedAnnouncement === announcement ? "▲" : "▼"}</h2>
                    </div>
                    {selectedAnnouncement === announcement && (
                      <div className="announcement-desc">{announcement.description}</div>
                    )}
                  </div>
                )))}
            </div>
          </div>
        </>}

      {title && title === "Tenant" &&
        <>

          <Link to="maintenance" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{tenantMaintenanceCount}</h2>
                <h3>Pending Maintenance</h3>
              </div>
              <div>
                <img src={tenantsMaintenanceIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="complaints" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{tenantComplaintsCount}</h2>
                <h3>Pending Complaints</h3>
              </div>

              <div>
                <img src={complaintIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="ledger" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{getRemainingBalance()}</h2>
                <h3>Remaining Balance</h3>
              </div>

              <div>
                <img src={ledgerIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <div className="announcement-card">
            <div className="announcement-header">
              <h2>Announcement</h2>

              {title === "Admin" ? <button className="announcement-add" onClick={togglePopupForm}>ADD</button> : ""}

            </div>

            <div className="announcements-div">
              {announcements.map((announcement) => (
                (announcement.receiver === "Everyone" || announcement.receiver === "Tenants") && (
                  <div className="announcement-div" key={announcement.id} onClick={() => selectedAnnDesc(announcement)}>
                    <div className="announcement-header">
                      <h3>{announcement.subject}</h3>
                      <h2>{selectedAnnouncement === announcement ? "▲" : "▼"}</h2>
                    </div>
                    {selectedAnnouncement === announcement && (
                      <div className="announcement-desc">{announcement.description}</div>
                    )}
                  </div>
                )))}
            </div>
          </div>
        </>}

      {title && title === "Cashier" &&
        <>

          <Link to="collection" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{getTotalPaymentForCashier()}</h2>
                <h3>Total Payment</h3>
              </div>

              <div>
                <img src={collectionIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="ledger" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{getPendingForThisMonth().toLocaleString()}</h2>
                <h3>Pending</h3>
              </div>

              <div>
                <img src={ledgerIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <Link to="ledger" className="dashboard-link">
            <div className="card">
              <div>
                <h2>{getOverDueForThisMonth().toLocaleString()}</h2>
                <h3>Overdue</h3>
              </div>

              <div>
                <img src={ledgerIcon} className="box-icon" />
              </div>
            </div>
          </Link>

          <div className="announcement-card">
            <div className="announcement-header">
              <h2>Announcement</h2>

              {title === "Admin" ? <button className="announcement-add" onClick={togglePopupForm}>ADD</button> : ""}

            </div>

            <div className="announcements-div">
              {announcements.map((announcement) => (
                (announcement.receiver === "Everyone" || announcement.receiver === "Cashier") && (
                  <div className="announcement-div" key={announcement.id} onClick={() => selectedAnnDesc(announcement)}>
                    <div className="announcement-header">
                      <h3>{announcement.subject}</h3>
                      <h2>{selectedAnnouncement === announcement ? "▲" : "▼"}</h2>
                    </div>
                    {selectedAnnouncement === announcement && (
                      <div className="announcement-desc">{announcement.description}</div>
                    )}
                  </div>
                )))}
            </div>
          </div>
        </>}
    </div>
  )
}