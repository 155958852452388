import React, { useContext, useEffect, useState } from "react";
import addIcon from "../../../global/images2/add-icon.png"
import exitIcon from "../../../global/images2/exit-icon.png"
import editIcon from "../../../global/settings/images/edit-icon.png"
import { LoginContext } from "../../../../context/LoginContext";
import "./tenants.css"
import { DatePicker } from 'antd';
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../../../../firebase-config"
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Loader from "../../../sammarc/loader";
import { parseCustomDate } from "../../../../utils";
import diplomatLogo from "../../../global/sidebarImages/diplomat-logo.png"

const { RangePicker } = DatePicker;

export default function Tenants() {
  // const {tenantsCollection,usersCollection, tenants,setTenants,user, setUser, units, setUnits, admin, tenantsLedgerCollection, tenantsLedger, setTenantsLedger} = useContext(LoginContext)
  const { tenantsCollection, tenants, setTenants, units, setUnits, admin, tenantsLedgerCollection, setTenantsLedger } = useContext(LoginContext);
  // eslint-disable-next-line
  const [firstName, setFirstName] = useState("");
  // eslint-disable-next-line
  const [lastName, setLastName] = useState("");

  const [availUnit, setAvailUnit] = useState([])
  const [selectedTenant, setSelectedTenant] = useState(null);
  // const [adminEmail, setAdminEmail] = useState("")
  // const [adminPassword, setAdminPassword] = useState("")
  const [popup, setPopup] = useState(false)
  const [popupInfo, setPopupInfo] = useState(false)
  const [popupConfirmation, setPopupConfirmation] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false)
  const [emailExisted, setEmailExisted] = useState(false)
  const [mobileNumError, setmobileNumError] = useState(false)
  const [edit, setEdit] = useState(false)
  const [selectedYear, setSelectedYear] = useState('')

  const [validID1, setValidID1] = useState(null);
  const [validID2, setValidID2] = useState(null);
  const [isSameValidID, setIsSameValidID] = useState(false)
  const [contractInput, setContractInput] = useState(null);
  const [businessPermitInput, setBusinessPermitInput] = useState(null);

  const [loading, setLoading] = useState(false);

  const [formData, setformData] = useState(
    {
      accountNumber: "",
      password: "",
      email: "",
      phoneNum: "",
      floor: "",
      unit: "",
      firstName: "",
      lastName: "",
      paymentScheme: "",
      parkingSpace: "",
      leasetime: "",
      taxrate: "",
    });

  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handlePhotoChange(e, identifier) {
    e.preventDefault();
    if (e.target.files[0]) {
      if (identifier === 'photoInput1') {
        setValidID1(e.target.files[0]);
      } else if (identifier === 'photoInput2') {
        setValidID2(e.target.files[0]);
      } else if (identifier === 'contractInput') {
        setContractInput(e.target.files[0]);
      } else if (identifier === 'businessPermitInput') {
        setBusinessPermitInput(e.target.files[0]);
      }
    }
  }

  const reLogin = async () => {
    try {
      const adminData = admin[0]; // Assuming you have a single admin document
      if (adminData) {
        const { email, password } = adminData;
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Admin re-logged in successfully.");
      } else {
        console.error("No admin data found.");
      }
    } catch (error) {
      console.error("Error re-logging in:", error);
    } finally { setLoading(false) }
  }

  const signNewTenantOut = async () => {
    try {
      await signOut(auth);

    } catch (error) {
      console.error("Error signing out admin:", error);
    }
  }


  const addTenant = async () => {
    try {
      setLoading(true);
      const tenant = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        "Diplomat1!",
        { disableAutoSignIn: true }
      )
      const tenantID = tenant.user.uid

      await sendEmailVerification(tenant.user)

      const today = new Date();
      uploadFiles(tenantID)
      const futureDate = new Date(today);
      futureDate.setFullYear(today.getFullYear() + Number(formData.leasetime));

      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      const formattedDate = `${months[futureDate.getMonth()]} ${futureDate.getDate()}, ${futureDate.getFullYear()}`;

      let unitMonthlyRate = 0
      let totalOutstandingBalance = 0
      let vatAmount = 0
      let whtAmount = 0
      let vat = 0
      let wht = 0
      let baseRate = 0
      let monthlyBalance = 0
      let debit = 0
      for (let i = 0; i < units.length; i++) {
        if (units[i].unitNumber === Number(formData.unit)) {
          updateUnit(units[i].id, { status: "Occupied" })
        }
        if (units[i].unitNumber === Number(formData.unit)) {
          vat = units[i].vat
          wht = units[i].wht
          baseRate = units[i].rate
          vatAmount = (vat / 100) * baseRate
          whtAmount = (wht / 100) * baseRate
          unitMonthlyRate = baseRate + vatAmount - whtAmount;
          totalOutstandingBalance = unitMonthlyRate * (formData.leasetime * 12)
          debit = unitMonthlyRate
        }
      }

      // eslint-disable-next-line
      const tenantsDocRef = await addDoc(tenantsCollection, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        userID: tenantID,
        unit: formData.unit,
        email: formData.email,
        mobileNum: formData.phoneNum,
        paymentScheme: formData.paymentScheme,
        parkingSpace: formData.parkingSpace,
        endOfContract: formattedDate,
        status: "Active",
        createdAt: new Date()
      });

      const nextMonth = new Date(today);
      nextMonth.setMonth(today.getMonth() + 1, 1);
      // eslint-disable-next-line
      const formattedDueDate = `${months[nextMonth.getMonth()]} ${nextMonth.getDate()}, ${nextMonth.getFullYear()}`;
      // eslint-disable-next-line
      const formattedDateToday = `${months[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`;

      let parkingBalance = 0;
      if (formData.parkingSpace === "Yes") {
        monthlyBalance = unitMonthlyRate + 8000
        parkingBalance = 8000
        debit += 8000
        totalOutstandingBalance = totalOutstandingBalance + 8000
      } else {
        monthlyBalance = unitMonthlyRate
        parkingBalance = 0
      }
      const leaseTime = Number(formData.leasetime); // Assuming formData.leasetime is the lease time in years.



      if (formData.paymentScheme === "full") {
        // Create a single ledger entry for full payment
        const formattedDueDate = `${months[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`;
        const ledgerData = {
          tenantID: tenantID,
          monthlyRate: totalOutstandingBalance,
          credit: "",
          debit: debit,
          parkingBalance: parkingBalance,
          monthlyBalance: totalOutstandingBalance,
          outstandingBalance: totalOutstandingBalance,
          dueDate: formattedDueDate,
          unit: formData.unit,
          status: "Pending",
          vatAmount: vatAmount,
          whtAmount: whtAmount,
          vat: vat,
          wht: wht,
          baseRate: baseRate,
          createdAt: new Date(),
          description: "Full Payment",
          firstPayment: true,
          surcharge: null
        };
        console.log(formattedDueDate);

        await addDoc(tenantsLedgerCollection, ledgerData);
        setTenantsLedger((prevTenantsLedger) => [...prevTenantsLedger, ledgerData]);
        monthlyBalance += unitMonthlyRate;
      } else if (formData.paymentScheme === "once") {
        // Create ledger entries for each month in the lease period
        for (let year = 1; year <= leaseTime; year++) {
          for (let month = 1; month <= 12; month++) {
            const nextMonth = new Date(today);
            nextMonth.setMonth(today.getMonth() + (year - 1) * 12 + month, 1);
            const formattedDueDate = `${months[nextMonth.getMonth()]} ${nextMonth.getDate()}, ${nextMonth.getFullYear()}`;

            let description;
            if (year === 1 && month <= 2) {
              description = "Advance";
            } else {
              description = `${months[nextMonth.getMonth()]} Rate`;
            }

            const ledgerData = {
              tenantID: tenantID,
              monthlyRate: monthlyBalance,
              credit: "",
              debit: debit,
              parkingBalance: parkingBalance,
              monthlyBalance: monthlyBalance,
              outstandingBalance: totalOutstandingBalance,
              dueDate: formattedDueDate,
              unit: formData.unit,
              status: "Pending",
              vatAmount: vatAmount,
              whtAmount: whtAmount,
              vat: vat,
              wht: wht,
              baseRate: baseRate,
              createdAt: new Date(),
              description: description,
              firstPayment: year === 1 && month <= 2,
              surcharge: null
            };

            await addDoc(tenantsLedgerCollection, ledgerData);
            setTenantsLedger((prevTenantsLedger) => [...prevTenantsLedger, ledgerData]);
            // monthlyBalance += unitMonthlyRate;
            monthlyBalance = unitMonthlyRate;

            if (formData.parkingSpace === "Yes") {
              monthlyBalance += 8000;
            }
          }
        }
      }
      setTenants((prevTenants) => [
        ...prevTenants,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          userID: tenantID,
          unit: formData.unit,
          email: formData.email,
          mobileNum: formData.phoneNum,
          paymentScheme: formData.paymentScheme,
          parkingSpace: formData.parkingSpace,
          endOfContract: formattedDate,
          status: "Active",
          createdAt: new Date()
        }
      ])


      await signNewTenantOut();

      await reLogin();

      setPopup(!popup);

    } catch (error) {
      setEmailExisted(true)
      console.log(error.message);
    }

  }

  const uploadFiles = async (tenantID) => {

    try {
      if (validID1) {
        const validID1Ref = ref(storage, `validIDs/${tenantID}_validID1.png`);
        await uploadBytes(validID1Ref, validID1);
        setValidID1(null);
      }

      if (validID2) {
        const validID2Ref = ref(storage, `validIDs/${tenantID}_validID2.png`);
        await uploadBytes(validID2Ref, validID2);
        setValidID2(null);
      }

      if (contractInput) {
        const contractRef = ref(storage, `contracts/${tenantID}_contract.pdf`);
        await uploadBytes(contractRef, contractInput);
        setContractInput(null);
      }

      if (businessPermitInput) {
        const businessPermitRef = ref(storage, `permits/${tenantID}_permit.pdf`);
        await uploadBytes(businessPermitRef, businessPermitInput);
        setBusinessPermitInput(null);
      }
    } catch (error) {
      console.error("Error uploading files: " + error.message);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const updateTenant = async (id, currentEndOfContract, addedYear) => {
    try {
      const tenantDocRef = doc(db, 'tenants', id);
      const dateObject = new Date(currentEndOfContract)
      const month = dateObject.getMonth()
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
      const day = dateObject.getDate()
      const year = dateObject.getFullYear()
      const finalDate = `${months[month]} ${day}, ${Number(year) + Number(addedYear)}`
      await updateDoc(tenantDocRef, { endOfContract: finalDate });


      setTenants((prevTenants) =>
        prevTenants.map((tenant) => (tenant.id === id ? { ...tenant, endOfContract: finalDate } : tenant))
      );

      setEdit(false)
      setPopupInfo(false)
      setSelectedYear("")
    } catch (error) {
      console.error('Error updating unit:', error);
    }
  }

  const updateUnit = async (id, updatedUnitData) => {
    try {
      console.log(id);
      const unitDocRef = doc(db, 'units', id);
      await updateDoc(unitDocRef, updatedUnitData);


      setUnits((prevUnits) =>
        prevUnits.map((unit) => (unit.id === id ? { ...unit, ...updatedUnitData } : unit))
      );

    } catch (error) {
      console.error('Error updating unit:', error);
    }
  }


  const togglePopupForm = () => {
    setPopup(!popup)
    setIsEmpty(false)
    setmobileNumError(false)
    setEmailExisted(false)
    setIsSameValidID(false);
    setformData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNum: "",
      floor: "",
      unit: "",
      paymentScheme: "",
      parkingSpace: "",
      leasetime: "",
      taxrate: "",
    });
  }

  const togglePopupConfirmation = (tenant) => {
    setPopupConfirmation(!popupConfirmation)
    setSelectedTenant(tenant)
  }

  const togglePopupInfo = async (tenant) => {
    setPopupInfo(!popupInfo);
    setSelectedTenant(tenant);
    setEdit(false);

    // Define the paths for different files
    const validID1Path = `validIDs/${tenant.userID}_validID1.png`;
    const validID2Path = `validIDs/${tenant.userID}_validID2.png`;
    const contractPath = `contracts/${tenant.userID}_contract.pdf`;
    const permitPath = `permits/${tenant.userID}_permit.pdf`;

    // Create an object to store download URLs
    const downloadUrls = {};

    try {
      // Fetch download URLs for validID1, validID2, and contract
      downloadUrls.validID1URL = await getDownloadURL(ref(storage, validID1Path));
      downloadUrls.validID2URL = await getDownloadURL(ref(storage, validID2Path));
      downloadUrls.contractURL = await getDownloadURL(ref(storage, contractPath));
    } catch (error) {
      console.error("Error fetching file URLs: " + error.message);
    }

    // Handle the case where the business permit might be missing
    if (!permitPath) {
      downloadUrls.businessPermitURL = null;
    } else {
      try {
        downloadUrls.businessPermitURL = await getDownloadURL(ref(storage, permitPath));
      } catch (error) {
        console.error("Error fetching business permit URL: " + error.message);
        downloadUrls.businessPermitURL = null; // Set it to null if the permit is missing
      }
    }

    // Update the selected tenant with download URLs
    setSelectedTenant({
      ...tenant,
      ...downloadUrls
    });
  };

  function capitalizeWords(str) {
    // Split the string by spaces and capitalize each word
    const words = str.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the capitalized words back into a single string with spaces
    return capitalizedWords.join(" ");
  }


  function availableUnit(floor) {

    const availableUnit = units.filter(
      (unit) => unit.floor === Number(floor) && unit.status === "Available"
    )
    setAvailUnit(availableUnit)

  }
  //Get the user input
  function handleChange(event) {
    const { name, value } = event.target;
    const containsNumbers = /\d/.test(value);
    const containsSpecialCharacters = /[!@#$%^&*()_+{}/"'=[\]:;<>,.?~\\]/.test(value);

    if ((containsNumbers && (name === 'firstName' || name === 'lastName')) || (containsSpecialCharacters && (name === 'firstName' || name === 'lastName'))) {
      return;
    }

    let formattedValue = value;

    if (name === "phoneNum") {
      // Remove any non-digit characters
      formattedValue = value.replace(/\D/g, '');

      // Limit the phone number to exactly 11 digits
      if (formattedValue.length > 11) {
        formattedValue = formattedValue.slice(0, 11);
      }
    } else if (name === "firstName" || name === "lastName") {
      formattedValue = capitalizeWords(value);
    }

    // eslint-disable-next-line
    const unitAvailable = name === "floor" ? availableUnit(value) : value;

    setformData(prevFormData => {
      return {
        ...prevFormData,
        [name]: formattedValue
      }
    });

  }

  useEffect(() => {
    setFirstName(formData.firstName);
    setLastName(formData.lastName);
  }, [formData.firstName, formData.lastName]);


  function handleSubmit(event) {
    event.preventDefault()

    if (formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.phoneNum === "" ||
      formData.leasetime === "" ||
      formData.floor === "" ||
      formData.unit === "" ||
      formData.paymentScheme === "" ||
      formData.parkingSpace === "" ||
      !validID1 ||
      !validID2 ||
      !contractInput ||
      validID1.name === "" ||
      validID2.name === "" ||
      contractInput.name === ""
    ) {
      setIsEmpty(true)
    } else {
      if (formData.phoneNum.length === 11) {
        if (!validID1 || !validID2 || !contractInput) {
          setIsEmpty(true);
        } else {
          if (!validID1 || !validID2 || !contractInput) {
            setIsEmpty(true);
          } else if (validID1.name === validID2.name) {
            setIsSameValidID(true);
            console.log("hehe");
          } else if (
            validID1.type.startsWith("image") &&
            validID2.type.startsWith("image") &&
            validID1.name !== validID2.name &&
            (contractInput.type === "application/pdf" || contractInput.type === "application/msword")
          ) {
            console.log("in");
            addTenant();
          } else {
            console.log("Invalid file types for validID1, validID2, or contractInput.");
          }
        }
      } else {
        console.log(formData.phoneNum.length);
        setmobileNumError(true)
      }
    }
  }
  const inactivateTenant = async (tenant) => {
    const tenantDocRef = doc(db, 'tenants', tenant.id);
    await updateDoc(tenantDocRef, {status: "Inactive"});

    setTenants((prevTenants) =>
        prevTenants.map((prevTenant) =>
        prevTenant.id === tenant.id ? { ...prevTenant, status: 'Inactive' } : prevTenant
        )
    );
    let unitID = ""
    units.map((unit) => {
        if(unit.unitNumber === Number(tenant.unit)){
            unitID = unit.id
        }
    })
    const unitDocRef = doc(db, 'units', unitID);
    await updateDoc(unitDocRef, {status: "Available"});
    
    setUnits((prevUnits) =>
        prevUnits.map((prevUnit) =>
        prevUnit.id === unitID ? { ...prevUnit, status: 'Available' } : prevUnit
        )
    );

    setPopupConfirmation(false)
}

  const activateTenant = async (tenant) => {
    const tenantDocRef = doc(db, 'tenants', tenant.id);
    await updateDoc(tenantDocRef, { status: "Active" });

    setTenants((prevTenants) =>
      prevTenants.map((prevTenant) =>
        prevTenant.id === tenant.id ? { ...prevTenant, status: 'Active' } : prevTenant
      )
    );
    setPopupConfirmation(false);
  }

  // date, entries, search, pdf, stattus
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [entriesCount, setEntriesCount] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line
  const [searchedTenants, setSearchedTenants] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * entriesCount;
  const firstIndex = lastIndex - entriesCount;
  const npage = Math.ceil(tenants.length / entriesCount);
  // eslint-disable-next-line
  const numbers = [...Array(npage + 1).keys()].slice(1);

  /* OLD
  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  */


  const handleEntriesChange = (event) => {
    setEntriesCount(event.target.value);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterTenants(searchTerm);
  };

  const filterTenants = (searchTerm) => {
    const searchTermsArray = searchTerm.toLowerCase().split(' ');

    const searchFilteredTenants = tenants.filter((tenant) => {
      if (!startDate || !endDate) {
        return true; // If date range is not set, include the tenant.
      }

      const tenantContractDate = new Date(tenant.endOfContract);
      const isWithinDateRange =
        tenantContractDate >= startDate && tenantContractDate <= endDate;

      // Check if all words in the search term exist in the tenant's fields
      const allWordsFound = searchTermsArray.every((word) => {
        for (const key in tenant) {
          if (Object.hasOwnProperty.call(tenant, key)) {
            const value = tenant[key];
            if (value && value.toString().toLowerCase().includes(word)) {
              return true; // Include the tenant if any word matches and is within the date range.
            }
          }
        }
        return false;
      });

      return isWithinDateRange && allWordsFound;
    });

    setSearchedTenants(searchFilteredTenants); // Update searchedTenants, not setFilteredTenants
  };

  const displayedTenants = tenants
    .filter((tenant) => {
      if (activeStatus && tenant.status !== 'Inactive') {
        return false;
      } else if (inactiveStatus && tenant.status !== 'Active') {
        return false;
      }

      if (startDate && endDate) {
        const tenantContractDate = new Date(tenant.endOfContract);
        if (tenantContractDate < startDate || tenantContractDate > endDate) {
          return false;
        }
      }

      if (searchTerm) {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        const allWordsFound = searchTermsArray.every((word) => {
          for (const key in tenant) {
            if (Object.hasOwnProperty.call(tenant, key)) {
              const value = tenant[key];
              if (value && value.toString().toLowerCase().includes(word)) {
                return true;
              }
            }
          }
          return false;
        });
        return allWordsFound;
      }
      return true;
    }).slice(firstIndex, lastIndex);

  if(displayedTenants && displayedTenants.length > 0 && filteredTenants.length == 0) setFilteredTenants(displayedTenants.slice());

  // Calendar Date Range Filter
  useEffect(() => {
      const filtered = displayedTenants.filter(tenant => {
          const parsedEndOfContract = parseCustomDate(tenant.endOfContract);
          // tenants endOfContract is within the selected date range
          return parsedEndOfContract >= startDate && parsedEndOfContract <= endDate;
      });
      setFilteredTenants(filtered);
  }, [startDate, endDate])

  // Search Filter
  useEffect(() => {
      const filtered = displayedTenants.filter(tenant => {
        if (searchTerm.length > 0) {
          const searchTermsArray = searchTerm.toLowerCase().split(' ');
          const allWordsFound = searchTermsArray.every((word) => {
            for (const key in tenant) {
              if (Object.hasOwnProperty.call(tenant, key)) {
                const value = tenant[key];
                if (value && value.toString().toLowerCase().includes(word)) {
                  return true;
                }
              }
            }
            return false;
          });
          return allWordsFound;
        }
        return true;
      });
      setFilteredTenants(filtered);
  }, [searchTerm])

  const handleDateChange = (dateArr) => {
      if (dateArr && Array.isArray(dateArr) && dateArr.length === 2) {
          setStartDate(new Date(dateArr[0].toDate().setHours(0,0,0,0))); // set to start of day
          setEndDate(new Date(dateArr[1].toDate().setHours(23, 59, 59, 999))); // set to end of day
      }
  }

  const toggleActive = () => {
    setActiveStatus(!activeStatus);
  };

  const toggleInactive = () => {
    setInactiveStatus(!inactiveStatus);
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    
    doc.autoTable({
      head: [['Unit No.', 'Name', 'Email', 'Mobile', 'End of Contract']],
      body: displayedTenants.map((tenant) => [
        tenant.unit,
        `${tenant.firstName} ${tenant.lastName}`,
        tenant.email,
        tenant.mobileNum,
        tenant.endOfContract,
      ]),
    });
    doc.save('Tenants.pdf');
  };

  const getBackgroundColor = (status) => {
    if (status === "Active") {
      return "#3c912b50"
    } else if (status === "Inactive") {
      return "#6e16163d"
    }
  }
  return (
    <div className="admin-tenants-container">
      {loading && <Loader />}
      <div className="admin-tenants-content">
        {popup && (
          <div className="popup-form">
            <div className="popup-overlay" onClick={togglePopupForm}></div>
            <div className="popup-box">

              <div className="popup-header">
                <h1>Add a Tenant</h1>
                <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
              </div>
              <div className="popup-content">
                <form onSubmit={handleSubmit}>
                  <div className="formleft">
                    <label>First Name:*</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="firstName"
                      value={formData.firstName}
                    />

                    <label>Last Name:*</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="lastName"
                      value={formData.lastName}
                    />

                    <label>Email:*</label>
                    <input
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={formData.email}
                    />

                    <label>Mobile Number:*</label>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="phoneNum"
                      value={formData.phoneNum}

                    />

                    <label>Lease Time:*</label>
                    <select
                      onChange={handleChange}
                      name="leasetime"
                      value={formData.leasetime}
                    >
                      <option value="">--Choose--</option>
                      <option value="1">1 Year </option>
                      <option value="2">2 Years</option>
                      <option value="3">3 Years</option>
                      <option value="4">4 Years</option>
                      <option value="5">5 Years</option>
                      <option value="6">6 Years</option>
                      <option value="7">7 Years</option>
                      <option value="8">8 Years</option>
                      <option value="9">9 Years</option>
                      <option value="10">10 Years</option>
                    </select>

                    <label>Floor:*</label>
                    <select
                      onChange={handleChange}
                      name="floor"
                      value={formData.floor}
                    >
                      <option value="">--Choose--</option>
                      <option value="2">Second</option>
                      <option value="3">Third</option>
                      <option value="4">Fourth</option>
                      <option value="5">Fifth</option>
                      <option value="6">Sixth</option>
                      <option value="7">Seventh</option>
                      <option value="8">Eigth</option>
                    </select>

                    <label>Unit:*</label>
                    <select
                      onChange={handleChange}
                      name="unit"
                      value={formData.unit}
                    >
                      <option value="">--Choose--</option>
                      {availUnit.map((unit) => (
                        <option key={unit.unitNumber} value={unit.unitNumber}>{unit.unitNumber}</option>
                      ))}
                    </select>
                  </div>

                  <div className="formright">
                    <label>Payment Scheme:*</label>
                    <select
                      onChange={handleChange}
                      name="paymentScheme"
                      value={formData.paymentScheme}

                    >
                      <option value="">--Choose--</option>
                      <option value="once">Once a Month</option>
                      <option value="full">Full Payment</option>

                    </select>
                    <label>Valid ID 1:*</label>
                    <input type="file" accept="image/*" id="file-upload" onChange={(e) => handlePhotoChange(e, 'photoInput1')} />

                    <label>Valid ID 2:*</label>
                    <input type="file" accept="image/*" id="file-upload" onChange={(e) => handlePhotoChange(e, 'photoInput2')} />

                    <label>Contract:*</label>
                    <input type="file" accept=".pdf, .doc, .docx" id="file-upload" onChange={(e) => handlePhotoChange(e, 'contractInput')} />

                    <label>Business Permit:</label>
                    <input type="file" accept=".pdf, .doc, .docx" id="file-upload" onChange={(e) => handlePhotoChange(e, 'businessPermitInput')} />

                    <label>Parking Space:*</label>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div>
                        <input type="radio" id="yes" name="parkingSpace" value="Yes" onChange={handleChange} checked={formData.parkingSpace === "Yes"} />
                        <label htmlFor="yes">Yes</label>
                      </div>

                      <div>
                        <input type="radio" id="no" name="parkingSpace" value="No" onChange={handleChange} checked={formData.parkingSpace === "No"} />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                    <div className="btn-div">
                      <div></div>
                      <button className="createAccBtn" onClick={handleSubmit}>Create Account</button>
                    </div>
                  </div>
                  <div>
                    <br></br>
                    {isEmpty && <p style={{ color: "red", fontSize: "80%" }}><b>Other Fields are empty.</b></p>}
                    {emailExisted && <p style={{ color: "red", fontSize: "80%" }}><b>Email Already Exist.</b></p>}
                    {mobileNumError && <p style={{ color: "red", fontSize: "80%" }}><b>Mobile Number should be 11 digits.</b></p>}
                    {isSameValidID && <p style={{ color: "red", fontSize: "80%" }}><b>Same Valid IDs.</b></p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {popupConfirmation && (
          <div className="popup-form" >
            <div className="popup-overlay" onClick={togglePopupConfirmation}></div>
            <div className="popup-box" id="confirmation-popup">
              {selectedTenant.status === "Active" &&
                <>
                  <p>Are you sure you want to deactivate the account of <b>{`${selectedTenant.firstName} ${selectedTenant.lastName}`}</b>?</p>
                  <div>
                    <button className="action-buttons" onClick={() => inactivateTenant(selectedTenant)}>Confirm</button>
                    <button className="action-buttons" onClick={() => setPopupConfirmation(false)}>Cancel</button>
                  </div>
                </>}

              {selectedTenant.status === "Inactive" &&
                <>
                  <p>Activate the account of <b>{`${selectedTenant.firstName} ${selectedTenant.lastName}`}</b>?</p>
                  <div>
                    <button className="action-buttons" onClick={() => activateTenant(selectedTenant)}>Confirm</button>
                    <button className="action-buttons" onClick={() => setPopupConfirmation(false)}>Cancel</button>
                  </div>
                </>}
            </div>
          </div>
        )}
        {popupInfo && (
          <div className="popup-form">
            <div className="popup-overlay" onClick={togglePopupInfo}></div>
            <div className="popup-box">

              <div className="popup-header">
                <h1>Tenant Information</h1>
                <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
              </div>
              <div className="popupInfo-content">

                <span><b>Name:</b> {`${selectedTenant.firstName} ${selectedTenant.lastName}`}</span>
                <span><b>Unit Number:</b> {selectedTenant.unit}</span>
                <span><b>Email:</b> {selectedTenant.email}</span>

                {selectedTenant.validID1URL && (
                  <span>
                    <b>Valid ID 1:</b>{" "}
                    <a href={selectedTenant.validID1URL} target="_blank" rel="noopener noreferrer">
                      <img src={selectedTenant.validID1URL} alt="Valid ID 1" style={{ maxWidth: '100px' }} />
                    </a>
                  </span>
                )}

                {selectedTenant.validID2URL && (
                  <span>
                    <b>Valid ID 2:</b>{" "}
                    <a href={selectedTenant.validID2URL} target="_blank" rel="noopener noreferrer">
                      <img src={selectedTenant.validID2URL} alt="Valid ID 2" style={{ maxWidth: '100px' }} />
                    </a>
                  </span>
                )}

                {selectedTenant.contractURL && (
                  <span>
                    <b>Contract:</b>{" "}
                    <a href={selectedTenant.contractURL} target="_blank" rel="noopener noreferrer">
                      View Contract
                    </a>
                  </span>
                )}

                {selectedTenant.businessPermitURL && (
                  <span>
                    <b>Business Permit:</b>{" "}
                    <a href={selectedTenant.businessPermitURL} target="_blank" rel="noopener noreferrer">
                      View Business Permit
                    </a>
                  </span>
                )}
                <span><b>End of Contract:</b> {selectedTenant.endOfContract}{edit && edit ?
                  <span className="tenantEditDiv">
                    <select
                      name="addedYear"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      <option value="">Choose</option>
                      <option value="1">+1 Year </option>
                      <option value="2">+2 Years</option>
                      <option value="3">+3 Years</option>
                      <option value="4">+4 Years</option>
                      <option value="5">+5 Years</option>
                      <option value="6">+6 Years</option>
                      <option value="7">+7 Years</option>
                      <option value="8">+8 Years</option>
                      <option value="9">+9 Years</option>
                      <option value="10">+10 Years</option>
                    </select>
                    <button onClick={() => updateTenant(selectedTenant.id, selectedTenant.endOfContract, selectedYear)}>Save</button>
                    <button onClick={() => setEdit(false)}>Cancel</button>
                  </ span>
                  : <img src={editIcon} className="edit-icon" alt="Edit Icon" onClick={() => setEdit(true)} />}</span>
                <span><b>Parking Space:</b> {selectedTenant.parkingSpace}</span>
                <span><b>Contact Number:</b> {selectedTenant.mobileNum}</span>
              </div>
            </div>
          </div>
        )}
        <div className="tenants-header">
          <h2>LIST OF TENANTS</h2>
        </div>
        <div className="tenants-addSearch-div">
          <div className="left-header">
            <img src={addIcon} alt="Add Icon" className="add-icon" onClick={togglePopupForm} />
            <div style={{ display: !popup ? 'block' : 'none' }}>
              <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                <div style={{ display: !popupConfirmation ? 'block' : 'none' }}>
                  <RangePicker className="rangedate" onChange={handleDateChange} />
                </div>
              </div>
            </div>
          </div>
          <div className="right-header">
            <div className="entries">
              <span>Show</span>

              <form>
                <select onChange={handleEntriesChange} value={entriesCount}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </form>
              <span>Entries</span>
            </div>
            <form>
              <div className='search-container'>
                <input type="text"
                  className="searchbar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </form>
          </div>

        </div>
        <div className="admin-table-div">
          <table className="admin-table" id="tenant-table">
            <thead>
              <tr>
                <th>Unit No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>End of Contract</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {displayedTenants.map((tenant) => (
                <tr key={tenant.id}>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>{tenant.unit}</td>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>{`${tenant.firstName} ${tenant.lastName}`}</td>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>{tenant.email}</td>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>{tenant.mobileNum}</td>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>{tenant.endOfContract}</td>
                  <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) ? getBackgroundColor(tenant.status) : "" }}>
                    <div className='action-buttons-container '>
                      <button
                        className="action-buttons"
                        onClick={() => togglePopupInfo(tenant)}
                      >
                        View
                      </button>
                      <button
                        className="action-buttons"
                        onClick={() => togglePopupConfirmation(tenant)}
                      >
                        {tenant.status === "Active" ? "Disable" : "Enable"}
                      </button>
                    </div>


                  </td>
                </tr>
              ))}

            </tbody>
          </table>

          <div className="pdfBtn-div">
            <div className="statusBtn-div">
              <button
                className={`statusBtn ${activeStatus ? 'active' : 'inactive'}`}
                onClick={toggleActive}
                style={{ backgroundColor: !activeStatus ? "#3c912b50" : "" }}
              >
                Active Tenant
              </button>

              <button
                className={`statusBtn ${activeStatus ? 'active' : 'inactive'}`}
                onClick={toggleInactive}
                style={{ backgroundColor: !inactiveStatus ? "#6e16163d" : "" }}
              >
                Inactive Tenant
              </button>
            </div>

            <nav className="pagination" id="center-pagination">
              <ul>
                <li className="page-item">
                  <a
                    href="#"
                    className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    {currentPage}
                  </a>
                </li>
                <li className="page-item">
                  <a
                    href="#"
                    className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                    onClick={nextPage}
                    disabled={currentPage === npage}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>

            <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
          </div>
        </div>
      </div>


    </div>
  )
}