import React, { useState } from "react";
import exitIcon from "../../../global/images2/exit-icon.png"
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
export default function Maintenance() {
    const [popupInfo, setPopupInfo] = useState(false)
    const [popupInfo2, setPopupInfo2] = useState(false)

    const togglePopupInfo = () => {
        setPopupInfo(!popupInfo)
    }
    const togglePopupInfo2 = () => {
        setPopupInfo2(!popupInfo2)
    }
    return (

        <div className="admin-tenants-container">
            {popupInfo && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupInfo}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Work Order Report</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
                        </div>
                        <div className="popupInfo-content">

                            <span><b>Date:</b> 04/25/2023</span>
                            <span><b>Unit Number:</b> 104</span>
                            <span><b>Work Order No.:</b> 1</span>
                            <span><b>Worker Name:</b> Popoy Garfield</span>
                            <span><b>Status:</b> Done</span>
                            <span><b>Subject:</b> Aircon Not Working</span>
                            <span><b>Description:</b> I already fixed the aircon in this unit and consider this work order done</span>
                        </div>

                    </div>
                </div>
            )}
            {popupInfo2 && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupInfo2}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Inspection Report</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo2} className="exit-icon" />
                        </div>
                        <div className="popupInfo-content">

                            <span><b>Date:</b>05/18/2023</span>
                            <span><b>Inspection No.:</b> 2</span>
                            <span><b>Location:</b> Elevator</span>
                            <span><b>Subject:</b> Broken</span>
                            <span><b>Description:</b> The elevator is inoperable since it is stuck on the third floor.</span>

                        </div>
                    </div>
                </div>
            )}
            <div className="tenant-maintenance-content">
                <div className="tenant-maintenance-header">
                    <h1>MAINTENANCE</h1>
                </div>

                <div className="admin-table-div" id="tenant-maintenance-req">

                    <div className="maintenance-req-header">
                        <h2>Work Order Reports</h2>

                    </div>

                    <div className="tenants-addSearch-div">

                        <div className="left-header">
                            <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                                <div style={{ display: !popupInfo2 ? 'block' : 'none' }}>
                                    <RangePicker className="rangedate" />
                                </div>
                            </div>
                        </div>
                        <div className="right-header">
                            <div className="entries">
                                <span>Show</span>

                                <form>
                                    <select>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                    </select>
                                </form>
                                <span>Entries</span>
                            </div>
                            <form>
                                <input type="text" className="searchbar" />
                            </form>
                        </div>

                    </div>

                    <table className="admin-table" id="maintenance-rec-tbl">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Unit No.</th>
                                <th>Work Order No.</th>
                                <th>Worker Name</th>
                                <th>Status</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>04/25/2023</td>
                                <td>104</td>
                                <td>1</td>
                                <td>Popoy Garfield</td>
                                <td>Done</td>
                                <td>Aircon Not Working</td>
                                <td><button className="action-buttons" onClick={togglePopupInfo}>View</button><button className="action-buttons">Download</button></td>

                            </tr>

                            <tr>
                                <td>04/253/2023</td>
                                <td>706</td>
                                <td>2</td>
                                <td>Lincoln Velasquez</td>
                                <td>Ongoing</td>
                                <td>Faucet Problem</td>
                                <td><button className="action-buttons">View</button><button className="action-buttons">Download</button></td>

                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="admin-table-div" id="tenant-maintenance-rec">
                    <div className="maintenance-req-header">
                        <h2>Inspection Reports</h2>
                    </div>
                    <div className="tenants-addSearch-div">

                        <div className="left-header">

                            <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                                <div style={{ display: !popupInfo2 ? 'block' : 'none' }}>
                                    <RangePicker className="rangedate" />
                                </div>
                            </div>
                        </div>
                        <div className="right-header">
                            <div className="entries">
                                <span>Show</span>

                                <form>
                                    <select>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                    </select>
                                </form>
                                <span>Entries</span>
                            </div>
                            <form>
                                <input type="text" className="searchbar" />
                            </form>
                        </div>


                    </div>
                    <table className="admin-table" id="maintenance-rec-tbl">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Inspection No.</th>
                                <th>Worker Name</th>
                                <th>Location</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>05/18/2023</td>
                                <td>2</td>
                                <td>John Doe</td>
                                <td>Elevator</td>
                                <td>Broken</td>
                                <td><button className="action-buttons" onClick={togglePopupInfo2}>View</button><button className="action-buttons">Download</button></td>

                            </tr>

                            <tr>
                                <td>04/18/2023</td>
                                <td>1</td>
                                <td>June Roe Nasayao</td>
                                <td>3rd Floor Staircase</td>
                                <td>Blinking Lights</td>
                                <td><button className="action-buttons">View</button><button className="action-buttons">Download</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}