import React from "react";
// import maintenanceRepIcon from "../../../global/images2/maintenance-icon.png"
// import paymentIcon from "../../../global/images2/payment-icon.png"
// import visitorslogIcon from "../../../global/images2/visitorslog-icon.png"
import "./reportsStyle.css"
import { Link } from "react-router-dom";

export default function Reports() {
  return (

    <div className="admin-report-container">
      <div className="admin-report-content">
        <div className="admin-report-boxes-header">
          <h1>Employee's Reports</h1>
        </div>
        <div className="admin-report-boxes-container">
          <Link to="payments" className="report-link">
            {/* <div className="report-boxes" id="payment-box">*/}
            <i class="fa-regular fa-paste"></i>
            <p>Payment Report</p>
            {/* </div> */}
          </Link>
          <Link to="visitorslog" className="report-link">
            {/* <div className="report-boxes" id="visitorslog-box"> */}
            <i class="fa-solid fa-users"></i>
            <p>Visitors Log</p>
            {/* </div> */}
          </Link>
        </div>
      </div >
    </div >
  )
}