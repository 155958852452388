import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import pdf from './user-manual.pdf';

function PdfComp() {
  const [numPages, setNumPages] = useState();
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className='pdf-div'>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, index) => {
            return <Page key={index}
              pageNumber={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          })}
      </Document>
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
}

export default PdfComp;