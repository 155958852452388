import React, { useContext, useEffect, useState } from "react";
import exitIcon from "../../../global/images2/exit-icon.png"
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { parseCustomDate, getTenantName } from "../../../../utils";
const { RangePicker } = DatePicker;
export default function CashierCollection(){
    const { tenantsLedger, tenants } = useContext(LoginContext)
    const [popupInfo, setPopupInfo] = useState(false)
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(nextMonthDate);
    const [filteredCollection, setFilteredLedger] = useState([]);

    /* OLD
    const togglePopupInfo = () => {
        setPopupInfo(!popupInfo)
    }

    const parseCustomDate = (dateString) => {
        const regex1 = /^(\d{4})-(\d{2})-(\d{2})$/;
        const regex2 = /^(\d{4})-(\w+)-(\d{1,2}),$/;
        const regex3 = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        const regex4 = /^(\w+) (\d{1,2}), (\d{4})$/;
        const regex5 = /^(\d{4})-(\w+)-(\d{1,2})$/;

        let match;
        if ((match = dateString.match(regex1)) !== null) {
            return new Date(match[1], match[2] - 1, match[3]);
        } else if ((match = dateString.match(regex2)) !== null) {
            const monthIndex = getMonthIndex(match[2]);
            return new Date(match[1], monthIndex, match[3]);
        } else if ((match = dateString.match(regex3)) !== null) {
            return new Date(match[3], match[1] - 1, match[2]);
        } else if ((match = dateString.match(regex4)) !== null) {
            const monthIndex = getMonthIndex(match[1]);
            return new Date(match[3], monthIndex, match[2]);
        } else if ((match = dateString.match(regex5)) !== null) {
            const monthIndex = getMonthIndex(match[2]);
            return new Date(match[1], monthIndex, match[3]);
        } else {
            console.error('Invalid date format:', dateString);
            return null;
        }
    };

    const getMonthIndex = (monthString) => {
        const months = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];

        const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());

        if (monthIndex !== -1) {
            return monthIndex;
        } else {
            console.error('Invalid month:', monthString);
            return 0;
        }
    };

    const rebuildLedger = () => {
        return tenantsLedger.map((ledger) => ({
            unit: ledger.unit,
            tenantId: ledger.tenantID,
            status: ledger.status,
            payment: parseInt(ledger.payment) || 0,
            transactionDate: ledger.transactionDate,
        }));
    }
    */

    // Filter ledger with payments only
    const groupedByTenant = tenantsLedger.filter(ledger => ledger.payment).reduce((acc, ledger) => {
        const tenantID = ledger.tenantID;

        if (!acc[tenantID]) {
            // If the tenantId doesn't exist in the accumulator, create a new entry
            acc[tenantID] = {
                tenantID: tenantID,
                unit: ledger.unit,
                name: getTenantName(tenantID, tenants),
                ledger: ledger.unit,
                payment: parseFloat(ledger.payment) || 0,
                transactionDate: ledger.transactionDate,
            };
        } else {
            // If the tenantId exists, update the payment by adding the current ledger's payment
            acc[tenantID].payment += parseFloat(ledger.payment) || 0;

            // Update the transactionDate if the current ledger's date is more recent
            if (ledger.transactionDate > acc[tenantID].transactionDate) {
                acc[tenantID].transactionDate = ledger.transactionDate;
                /* OLD
                try {
                    acc[tenantID].transactionDate = parseCustomDate(ledger.transactionDate).toISOString().split('T')[0];
                } catch (error) {
                    console.error(error);
                }
                */
            }
        }

        return acc;
    }, {});

    /* OLD
    const groupedArray = Object.values(groupedByTenant)
        .filter(entry => entry.transactionDate)
        .filter(entry => {
            const transactionDate = new Date(entry.transactionDate);
            return transactionDate >= startDate && transactionDate <= endDate;
        })
        .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));
    */

    // Sort ledger by transactionDate
    const groupedArray = Object.values(groupedByTenant).sort((a, b) => {
        const date = new Date(a.transactionDate);
        const nextDate = new Date(b.transactionDate);
        return date - nextDate;
    });

    if(groupedArray && groupedArray.length > 0 && filteredCollection.length == 0) setFilteredLedger(groupedArray.slice());

    // Calendar Date Range Filter
    useEffect(() => {
        const filtered = groupedArray.filter(ledger => {
            const parsedTransactionDate = parseCustomDate(ledger.transactionDate);
            // ledger transactionDate is within the selected date range
            return parsedTransactionDate >= startDate && parsedTransactionDate <= endDate;
        });
        setFilteredLedger(filtered);
    }, [startDate, endDate])

    const handleDateChange = (dateArr) => {
        if (dateArr && Array.isArray(dateArr) && dateArr.length === 2) {
            setStartDate(new Date(dateArr[0].toDate().setHours(0,0,0,0))); // set to start of day
            setEndDate(new Date(dateArr[1].toDate().setHours(23, 59, 59, 999))); // set to end of day
        }
    }

    const downloadAsPdf = (data) => {
        const doc = new jsPDF();
        doc.text(`Collection Report ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`, 10, 10)
        doc.autoTable({
            head: [['Date', 'Name', 'Unit No.', 'Overall Amount']],
            body: data.map((ledger) => [
                ledger.transactionDate,
                ledger.name,
                ledger.unit,
                `P${ledger.payment || 0}`,
            ]),
        });
        doc.save(`Collection Report ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}.pdf`);
    }

    return(
        <div className="admin-tenants-container">

            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>COLLECTION</h1>
                </div>
                <div className="tenants-addSearch-div">

                    <div className="left-header">
                        <div style={{display: !popupInfo ? 'block' : 'none' }}>
                            <RangePicker className="rangedate" onChange={handleDateChange}/>
                        </div>
                        <button className="pdfBtn" onClick={() => downloadAsPdf(filteredCollection)}>Download Record</button>
                    </div>
                    <div className="right-header">
                        <div className="entries">
                            <span>Show</span>
                            <form>
                                <select>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </form>
                            <span>Entries</span>

                            <form>
                                <input type="text" className="searchbar" />
                            </form>
                        </div>

                    </div>


                </div>

                <div className="admin-table-div">

                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Last Transaction Date</th>
                                <th>Name</th>
                                <th>Unit No.</th>
                                <th>Overall Amount(₱)</th>
                            </tr>
                        </thead>
                        <tbody>
                            { filteredCollection.map((ledger, index) => (
                                <tr key={`${ledger.tenantID}-${index}`}>
                                    <td>{ledger.transactionDate}</td>
                                    <td>{ledger.name}</td>
                                    <td>{ledger.unit}</td>
                                    <td>₱{ledger.payment}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div className="total-payment-container">
                                        Total Revenue/Payment: ₱{filteredCollection.reduce((acc, ledger) => acc + ledger.payment, 0)}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}