import React, { useEffect, useState } from "react";
import "../../../global/sidebarStyle.css";
import personIcon from "../../../global/sidebarImages/person.png";
import dashboardIcon from "../../../global/images2/dashboard-icon.png";
import unitIcon from "../../../global/images2/unit-icon.png";
import tenantsIcon from "../../../global/images2/tenants-icon.png";
import employeesIcon from "../../../global/images2/employees-icon.png";
import reportsIcon from "../../../global/images2/reports-icon.png";
// import calendarIcon from "../../../global/images2/calendar-icon.png"
// import contractIcon from "../../../global/images2/contract-icon.png"
import helpIcon from "../../../global/images2/help-icon.png";
import settingsIcon from "../../../global/images2/settings-icon.png";
import diplomatLogo from "../../../global/sidebarImages/diplomat-logo.png";
// import maintenanceEmpIcon from "../../../global/images2/maintenanceemp-icon.png"
// import frontdeskIcon from "../../../global/images2/frontdesk-icon.png"
// import cashierIcon from "../../../global/images2/cashier-icon.png"
// import maintenanceRepIcon from "../../../global/images2/maintenance-icon.png"
import paymentIcon from "../../../global/images2/payment-icon.png";
import visitorslogIcon from "../../../global/images2/visitorslog-icon.png";
import workordersIcon from "../../../global/images2/workorders-icon.png";
import complaintsIcon from "../../../global/images2/complaints-icon.png";

import { Link, Outlet } from "react-router-dom";
import { useContext } from "react";
import { LoginContext } from "../../../../context/LoginContext";
// import { useNavigate } from "react-router-dom";
// import { signOut } from "firebase/auth";
import { auth } from "../../../../firebase-config";
export default function AdminSidebar() {

  // const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn} = useContext(LoginContext)
  const { setIsLoggedIn } = useContext(LoginContext);
  const [activeUser, setActiveUser] = useState("");
  const [empDropdown, setEmpDropdown] = useState(false);
  const [repDropdown, setRepDropdown] = useState(false);
  const [photoURL, setPhotoUrl] = useState(activeUser?.photoURL || personIcon);
  // const navigate = useNavigate();

  const toggleEmpDropdown = () => {
    setEmpDropdown(!empDropdown)
  }

  const toggleRepDropdown = () => {
    setRepDropdown(!repDropdown)
  }

  const logOut = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('userFullName')
  }

  useEffect(() => {
    // Reinitialize the photoURL when the user object changes
    if (activeUser) {
      setPhotoUrl(activeUser.photoURL || personIcon);
    }
  }, [activeUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setActiveUser(user);

      } else {
        setActiveUser(null);
      }
    });

    return () => unsubscribe();
  }, []);
  return (

    <div className="sidebar-container">
      <div className="adminSidebar">
        <div className="sidebar-content-div">
          <div className="links-div">
            <header className="sidebar-header">
              <img className="user-icon" src={photoURL} alt="User Icon" />
              <div className="user-info-div">
                <h2 className="user-info">{localStorage.getItem("userFullName")}</h2>
                <h3 className="user-info">Admin</h3>
              </div>
            </header>
            <ul className="link-list">
              <Link to="" className="link">
                <img src={dashboardIcon} className="icon" alt="Dashboard Icon" />
                <li>Dashboard</li>
              </Link>
              <Link to="unit" className="link">
                <img src={unitIcon} className="icon" alt="Unit Icon" />
                <li>Units</li>
              </Link>
              <Link to="tenants" className="link">
                <img src={tenantsIcon} className="icon" alt="Tenants Icon" />
                <li>Tenants</li>
              </Link>
              <Link to="employees" className="link" onClick={toggleEmpDropdown}>
                <img src={employeesIcon} className="icon" alt="Employees Icon" />
                <li>Employees</li>
              </Link>
              <Link to="workorders" className="link">
                <img src={workordersIcon} className="icon" alt="Unit Icon" />
                <li>Work Orders</li>
              </Link>
              <Link to="complaints" className="link">
                <img src={complaintsIcon} className="icon" alt="Unit Icon" />
                <li>Complaints</li>
              </Link>

              <Link to="reports/visitorslog" className="link">
                <img src={visitorslogIcon} className="icon" alt="Employees Icon" />
                <li>Visitor's Log</li>
              </Link>
              
                
              
              <Link to="help" className="link">
                <img src={helpIcon} className="icon" alt="Help Icon" />
                <li>Help</li>
              </Link>
              <Link to="settings" className="link">
                <img src={settingsIcon} className="icon" alt="Settings Icon" />
                <li>Settings</li>
              </Link>
            </ul>
          </div>
          <button onClick={logOut}>Log Out <i className="fa-solid fa-arrow-right-from-bracket"></i></button>
        </div>
      </div>
      <div className='main-content'>
        <div className="topbar">
          <img src={diplomatLogo} alt="Diplomat Logo" />
        </div>
        <div className="contentdiv">
          <Outlet />
        </div>
        <div className="footer">
          <div className="address-div">
            <p><b>Property Address</b> GXMV+CC3, Russel Avenue, Baclaran, Pasay, Kalakhang Maynila</p>
          </div>
        </div>
      </div>
    </div>
  )
}