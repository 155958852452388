import React, { useContext, useState, useEffect } from "react";
import exitIcon from "../../../global/images2/exit-icon.png";
import { DatePicker } from 'antd';
import { LoginContext } from "../../../../context/LoginContext";
import { auth } from "../../../../firebase-config";
import jsPDF from "jspdf";
import { parseCustomDate } from "../../../../utils";

const { RangePicker } = DatePicker;

export default function Ledger() {
    const { tenantsLedger } = useContext(LoginContext);
    const [activeUser, setActiveUser] = useState(null);
    const [selectedLedger, setSelectedLedger] = useState(null);
    const [popupInfo, setPopupInfo] = useState(false);
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + 12);
    const [startDate, setStartDate] = useState(new Date("2020-1-10"));
    const [endDate, setEndDate] = useState(nextMonthDate);
    const [filteredLedger, setFilteredLedger] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setActiveUser(user);
            } else {
                setActiveUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const togglePopupInfo = (ledger) => {
        setPopupInfo(!popupInfo);
        setSelectedLedger(ledger);
    };

    const formatNumber = (number) => {
        if (typeof number === 'number' && !isNaN(number)) {
            return number.toLocaleString();
        }
        return '';
    };

    const displayedLedger = tenantsLedger
        .filter((ledger) => {
            if (!activeUser) {
                return false;
            }
            if (ledger.tenantID !== activeUser.uid) {
              return false;
            }
            return true;
        })
        .map((ledger) => ({
            ...ledger,
            credit: formatNumber(ledger.credit),
            debit: formatNumber(ledger.debit),
            balance: formatNumber(ledger.monthlyBalance),
            parkingBalance: formatNumber(ledger.parkingBalance),
            outstandingBalance: formatNumber(ledger.outstandingBalance),
            monthlyRate: formatNumber(ledger.monthlyRate)
        }));

    // {
    //   transactionDate: "January 1, 2021",
    //   payment: "",
    //   dsecription: "",
    //   balance: "",
    //   dueDate: "",
    //   status: "",
    // }

    const sortedLedger = displayedLedger.sort((a, b) => {
        const date = new Date(a.dueDate);
        const nextDate = new Date(b.dueDate);
        const transactionDate = new Date(a.transactionDate);
        const transactionDateNext = new Date(b.transactionDate);
        
        if (date > nextDate) {
            return 1;
          } else if (date < nextDate) {
            return -1;
          } else if (a.status === 'Pending' && b.status !== 'Pending') {
            return 1;
          } else if (a.status !== 'Pending' && b.status === 'Pending') {
            return -1;
          } else if (transactionDate > transactionDateNext) {
            return 1;
          } else if (transactionDate < transactionDateNext) {
            return -1;
          } else if (a.monthlyBalance > b.monthlyBalance) {
            return -1;
          } else if (a.monthlyBalance < b.monthlyBalance) {
            return 1;
          } else {
            // If dueDate, balance, and status are the same, they are considered equal
            return 0;
          }
    });

    /* OLD
    const rebuildLedger = () => {
      return displayedLedger.map((ledger) => ({
        transactionDate: ledger.transactionDate,
        payment: ledger.payment,
        description: ledger.description,
        balance: ledger.monthlyBalance,
        dueDate: ledger.dueDate,
        status: ledger.status,
      }));
    }

    const sortedLedger = rebuildLedger().slice().sort((a, b) => {
      return new Date(a.dueDate) - new Date(b.dueDate);
    }).filter((ledger) => {
        return new Date(ledger.dueDate) >= startDate && new Date(ledger.dueDate) <= endDate
    });
    */

    if(sortedLedger && sortedLedger .length > 0 && filteredLedger.length == 0) setFilteredLedger(sortedLedger.slice());

    // Calendar Date Range Filter
    useEffect(() => {
        const filtered = sortedLedger.filter(ledger => {
            const parsedTransactionDate = parseCustomDate(ledger.dueDate);
            // ledger transactionDate is within the selected date range
            return parsedTransactionDate >= startDate && parsedTransactionDate <= endDate;
        });
        setFilteredLedger(filtered);
    }, [startDate, endDate])

    const handleDateChange = (dateArr) => {
        if (dateArr && Array.isArray(dateArr) && dateArr.length === 2) {
            setStartDate(new Date(dateArr[0].toDate().setHours(0,0,0,0))); // set to start of day
            setEndDate(new Date(dateArr[1].toDate().setHours(23, 59, 59, 999))); // set to end of day
        }
    }

    const downloadAsPdf = (data) => {
        const doc = new jsPDF();
        doc.text(`Ledger ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`, 10, 10)
        doc.autoTable({
            head: [['Transaction Date', 'Payment', 'Description', 'Balance', 'Due Date', 'Status']],
            body: data.map((ledger) => [
                ledger.transactionDate,
                ledger.payment,
                ledger.description,
                `P${ledger.balance}`,
                ledger.dueDate,
                ledger.status,
            ]),
        });

        doc.save(`Ledger ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}.pdf`);
    }
    return(
        <div className="admin-tenants-container">
            {popupInfo && (
            <div className="popup-form">
                <div className="popup-overlay" onClick={togglePopupInfo}></div>
                <div className="popup-box">

                    <div className="popup-header">
                        <h1>Payment Report</h1>
                        <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon"/>
                    </div>
                    <div className="popupInfo-content">

                        <span><b>Transaction Date:</b> {selectedLedger.transactionDate}</span>
                        <span><b>Debit(₱):</b> {selectedLedger.debit}</span>
                        <span><b>Credit(₱):</b> {selectedLedger.credit}</span>
                        <span><b>Balance(₱):</b> {selectedLedger.balance}</span>
                        <span><b>Duedate:</b> {selectedLedger.dueDate}</span>
                        <span><b>Unit Montly Rate(₱):</b> {selectedLedger.monthlyRate}</span>
                        <span><b>Parking Balance(₱):</b> {selectedLedger.parkingBalance}</span>
                        <span><b>Outstanding Balance(₱):</b> {selectedLedger.outstandingBalance}</span>
                    </div>

                </div>
            </div>
            )}
            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>LEDGER</h1>

                </div>

                <div className="tenants-addSearch-div">

                    <div className="left-header">
                        <div style={{display: !popupInfo ? 'block' : 'none' }}>
                            <RangePicker className="rangedate" onChange={handleDateChange}/>
                        </div>
                        <button className="pdfBtn" onClick={() => downloadAsPdf(filteredLedger)}>Download Record</button>
                    </div>
                    <div className="right-header">
                        <div className="entries">
                            <span>Show</span>

                            <form>
                                <select>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </form>
                            <span>Entries</span>
                        </div>
                        <form>
                        <input type="text" className="searchbar" />
                    </form>
                    </div>
                </div>

                <div className="admin-table-div">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Transaction Date</th>
                                <th>Payment</th>
                                <th>Description</th>
                                <th>Balance</th>
                                <th>Surcharge</th>
                                <th>Due Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredLedger.map((ledger) => (

                                        <tr key={ledger.id}>
                                            <td>{ledger.transactionDate}</td>
                                            <td>{ledger.payment}</td>
                                            <td>{ledger.description}</td>
                                            <td>₱{ledger.balance}</td>
                                            <td>₱{ledger.surcharge ? ledger.surcharge : 0}</td>
                                            <td>{ledger.dueDate}</td>
                                            <td>{ledger.status}</td>
                                        </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}