import React, { useState } from "react";
import exitIcon from "../../../global/images2/exit-icon.png"
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
export default function MaintenanceWorkorders() {
    const [popupInfo, setPopupInfo] = useState(false)

    const togglePopupInfo = () => {
        setPopupInfo(!popupInfo)
    }

    return (
        <div className="admin-tenants-container">
            {popupInfo && (
                <div className="popup-form">
                    <div className="popup-overlay" onClick={togglePopupInfo}></div>
                    <div className="popup-box">

                        <div className="popup-header">
                            <h1>Work Order Information</h1>
                            <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
                        </div>
                        <div className="popupInfo-content">

                            <span><b>Date:</b> 03/18/2023</span>
                            <span><b>Unit Number:</b>502</span>
                            <span><b>Work Order No.:</b> 2</span>
                            <span><b>Name:</b> Lucy Antonio</span>
                            <span><b>Subject:</b> Drain Clogging</span>
                            <span><b>Description:</b> The drain in my bathroom is jammed and requires immediate attention.</span>
                        </div>
                    </div>
                </div>
            )}
            <div className="admin-tenants-content">
                <div className="tenants-header">
                    <h1>WORK ORDERS</h1>

                </div>

                <div className="tenants-addSearch-div">

                    <div className="left-header">


                        <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                            <RangePicker className="rangedate" />
                        </div>




                    </div>
                    <div className="right-header">
                        <div className="entries">
                            <span>Show</span>

                            <form>
                                <select>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </form>
                            <span>Entries</span>
                        </div>
                        <form>
                            <input type="text" className="searchbar" />
                        </form>
                    </div>


                </div>

                <div className="admin-table-div">
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Unit #</th>
                                <th>Workorder #</th>
                                <th>Subject</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>04/22/2023</td>
                                <td>502</td>
                                <td>2</td>
                                <td>Drain Clogging</td>
                                <td><button className="action-buttons" onClick={togglePopupInfo}>View</button><button className="action-buttons">Accept</button></td>

                            </tr>



                            <tr>
                                <td>02/2/2023</td>
                                <td>202</td>
                                <td>1</td>
                                <td>Roof Leak</td>
                                <td><button className="action-buttons">View</button><button className="action-buttons">Done</button></td>

                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}