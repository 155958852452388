import React, { useContext, useState, useEffect } from "react";
import addIcon from "../../../global/images2/add-icon.png"
import exitIcon from "../../../global/images2/exit-icon.png"
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "../../../../firebase-config";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { format } from "date-fns";
import jsPDF from "jspdf";
import { parseCustomDate } from "../../../../utils";
const { RangePicker } = DatePicker;

export default function Employees() {

  const { employees, setEmployees, employeesCollection, admin } = useContext(LoginContext)

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [popup, setPopup] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [emailExisted, setEmailExisted] = useState(false)
  const [mobileNumError, setmobileNumError] = useState(false)
  const [popupInfo, setPopupInfo] = useState(false)
  const [popupConfirmation, setPopupConfirmation] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const togglePopupForm = () => {
    setPopup(!popup)
    setformData({
      email: "",
      phoneNum: "",
      firstName: "",
      lastName: "",
      title: "",
      birthDate: "",
      numberOfChild: ""
    });
    setEmailExisted(false)
    setmobileNumError(false)
    setIsEmpty(false)
  }

  const togglePopupInfo = async (employee) => {
    setPopupInfo(!popupInfo);
    setSelectedEmployee(employee);
  }

  const togglePopupConfirmation = (employee) => {
    setPopupConfirmation(!popupConfirmation)
    setSelectedEmployee(employee)
  }

  const [formData, setformData] = useState(
    {
      email: "",
      phoneNum: "",
      firstName: "",
      lastName: "",
      title: "",
      birthDate: "",
      numberOfChild: ""
    }
  )

  function capitalizeWords(str) {
    const words = str.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    return capitalizedWords.join(" ");
  }

  //Get the user input
  function handleChange(event) {
    const { name, value } = event.target
    const containsNumbers = /\d/.test(value);
    const containsSpecialCharacters = /[!@#$%^&*()_+{}/"'=[\]:;<>,.?~\\]/.test(value);

    if ((containsNumbers && (name === 'firstName' || name === 'lastName')) || (containsSpecialCharacters && (name === 'firstName' || name === 'lastName'))) {
      return;
    }


    let formattedValue = value;

    if (name === "phoneNum") {
      formattedValue = value.replace(/\D/g, '');

      if (formattedValue.length > 11) {
        formattedValue = formattedValue.slice(0, 11);
      }
    } else if (name === "firstName" || name === "lastName") {
      formattedValue = capitalizeWords(value);
    } else if (name === "numberOfChild") {
      formattedValue = value.replace(/\D/g, '');

      if (formattedValue.length > 2) {
        formattedValue = formattedValue.slice(0, 2);
      }
    }
    setformData(prevFormData => {
      return {
        ...prevFormData,
        [name]: formattedValue

      }
    })

  }


  function handleSubmit(event) {
    event.preventDefault()
    if (formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.phoneNum === "" ||
      formData.title === "" ||
      formData.numberOfChild === "" ||
      formData.birthDate === ""
    ) {
      setIsEmpty(true)
    } else {
      if (formData.phoneNum.length === 11) {
        addEmployees()
      } else {
        setmobileNumError(true)
      }

    }

  }
  const reLogin = async () => {
    try {
      const adminData = admin[0]; // Assuming you have a single admin document
      if (adminData) {
        const { email, password } = adminData;
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Admin re-logged in successfully.");
      } else {
        console.error("No admin data found.");
      }
    } catch (error) {
      console.error("Error re-logging in:", error);
    }
  }

  const signNewTenantOut = async () => {
    try {
      await signOut(auth);

    } catch (error) {
      console.error("Error signing out admin:", error);
    }
  }
  const addEmployees = async () => {
    try {
      const formattedBirthDate = format(new Date(formData.birthDate), "MMMM dd, yyyy");
      const currentDate = new Date();
      const formattedCurrentDate = format(currentDate, "MMMM dd, yyyy");
      const employee = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        "Diplomat1!",
        { disableAutoSignIn: true }
      )

      const employeeID = employee.user.uid

      await sendEmailVerification(employee.user)

      await addDoc(employeesCollection, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        userID: employeeID,
        email: formData.email,
        mobileNum: formData.phoneNum,
        birthDate: formattedBirthDate,
        numberOfChild: formData.numberOfChild,
        dateHired: formattedCurrentDate,
        createdAt: new Date(),
        status: "Active"
      })

      setEmployees((prevEmployee) => [
        ...prevEmployee,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          title: formData.title,
          userID: employeeID,
          email: formData.email,
          mobileNum: formData.phoneNum,
          birthDate: formattedBirthDate,
          numberOfChild: formData.numberOfChild,
          dateHired: formattedCurrentDate,
          createdAt: new Date(),
          status: "Active"
        }
      ])

      await signNewTenantOut();

      await reLogin();

      setPopup(!popup);
    } catch (error) {
      setEmailExisted(true)
      console.log(error.message);
    }

  }
  const inactivateEmployee = async (employee) => {
    const employeeDocRef = doc(db, 'employees', employee.id);
    await updateDoc(employeeDocRef, { status: "Inactive" });

    setEmployees((prevEmployees) =>
      prevEmployees.map((prevEmployee) =>
        prevEmployee.id === employee.id ? { ...prevEmployee, status: 'Inactive' } : prevEmployee
      )
    );

    setPopupConfirmation(false)
  }

  const activateEmployee = async (employee) => {
    const employeeDocRef = doc(db, 'employees', employee.id);
    await updateDoc(employeeDocRef, { status: "Active" });

    setEmployees((prevEmployees) =>
      prevEmployees.map((prevEmployee) =>
        prevEmployee.id === employee.id ? { ...prevEmployee, status: 'Active' } : prevEmployee
      )
    );

    setPopupConfirmation(false)
  }

  const downloadAsPDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [['Name.', 'Email', 'Contact Number', 'Title', 'Date Hired', "Number of Children", "Status", "Birth Date"]],
      body: displayedEmployees.map((employee) => [
        `${employee.firstName} ${employee.lastName}`,
        employee.email,
        employee.mobileNum,
        employee.title,
        employee.dateHired,
        employee.numberOfChild,
        employee.status,
        employee.birthDate
      ]),
    });

    doc.save('Employees.pdf');
  };

  // date, entries, search, pdf, stattus
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [entriesCount, setEntriesCount] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line
  const [searchedEmployees, setSearchedEmployees] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * entriesCount;
  const firstIndex = lastIndex - entriesCount;
  const npage = Math.ceil(employees.length / entriesCount);
  // eslint-disable-next-line
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const displayedEmployees = employees
    .filter((employee) => {
      if (activeStatus && employee.status !== 'Inactive') {
        return false;
      } else if (inactiveStatus && employee.status !== 'Active') {
        return false;
      }

      if (startDate && endDate) {
        const employeeDateHired = new Date(employee.dateHired);
        if (employeeDateHired < startDate || employeeDateHired > endDate) {
          return false;
        }
      }

      if (searchTerm) {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        const allWordsFound = searchTermsArray.every((word) => {
          for (const key in employee) {
            if (Object.hasOwnProperty.call(employee, key)) {
              const value = employee[key];
              if (value && value.toString().toLowerCase().includes(word)) {
                return true;
              }
            }
          }
          return false;
        });
        return allWordsFound;
      }

      return true;
    }).slice(firstIndex, lastIndex);

  if(displayedEmployees && displayedEmployees.length > 0 && filteredEmployees.length == 0) setFilteredEmployees(displayedEmployees.slice());

  // Calendar Date Range Filter
  useEffect(() => {
      const filtered = displayedEmployees.filter(employee => {
          const parsedDateHired = parseCustomDate(employee.dateHired);
          // employees dateHired is within the selected date range
          return parsedDateHired >= startDate && parsedDateHired <= endDate;
      });
      setFilteredEmployees(filtered);
  }, [startDate, endDate])

  // Search Filter
  useEffect(() => {
      const filtered = displayedEmployees.filter(employee => {
        if (searchTerm.length > 0) {
          const searchTermsArray = searchTerm.toLowerCase().split(' ');
          const allWordsFound = searchTermsArray.every((word) => {
            for (const key in employee) {
              if (Object.hasOwnProperty.call(employee, key)) {
                const value = employee[key];
                if (value && value.toString().toLowerCase().includes(word)) {
                  return true;
                }
              }
            }
            return false;
          });
          return allWordsFound;
        }
        return true;
      });
      setFilteredEmployees(filtered);
  }, [searchTerm])

  const handleDateChange = (dateArr) => {
      if (dateArr && Array.isArray(dateArr) && dateArr.length === 2) {
          setStartDate(new Date(dateArr[0].toDate().setHours(0,0,0,0))); // set to start of day
          setEndDate(new Date(dateArr[1].toDate().setHours(23, 59, 59, 999))); // set to end of day
      }
  }

  /* OLD
  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  */

  const handleEntriesChange = (event) => {
    setEntriesCount(event.target.value);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterEmployees(searchTerm);
  };

  const filterEmployees = (searchTerm) => {
    const searchTermsArray = searchTerm.toLowerCase().split(' ');

    const searchFilteredEmployees = filteredEmployees.filter((employee) => {
      if (!startDate || !endDate) {
        return true;
      }

      // const employeeDateHired = new Date(employee.dateHired);
      // const isWithinDateRange =
      //   employeeDateHired >= startDate && employeeDateHired <= endDate;

      // Check if all words in the search term exist in the employee's fields
      const allWordsFound = searchTermsArray.every((word) => {
        for (const key in employee) {
          if (Object.hasOwnProperty.call(employee, key)) {
            const value = employee[key];
            if (value && value.toString().toLowerCase().includes(word)) {
              return true; // Include the employee if any word matches and is within the date range.
            }
          }
        }
        return false;
      });

      return allWordsFound;
    });

    setSearchedEmployees(searchFilteredEmployees);
  };

  const toggleActive = () => {
    setActiveStatus(!activeStatus);

  };

  const toggleInactive = () => {

    setInactiveStatus(!inactiveStatus);

  };
  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  return (
    <div className="admin-tenants-container">

      <div className="admin-tenants-content">

        {popup && (
          <div className="popup-form">
            <div className="popup-overlay" onClick={togglePopupForm}></div>
            <div className="popup-box">

              <div className="popup-header">
                <h1>Add a Employee</h1>
                <img src={exitIcon} alt="Exit Icon" onClick={togglePopupForm} className="exit-icon" />
              </div>
              <div className="popup-content">

                <form onSubmit={handleSubmit}>
                  <div className="formleft">

                    <label>First Name:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="firstName"
                      value={formData.firstName}
                    />

                    <label>Last Name:</label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="lastName"
                      value={formData.lastName}
                    />

                    <label>Email:</label>
                    <input
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={formData.email}
                    />

                    <label>Mobile Number:</label>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="phoneNum"
                      value={formData.phoneNum}
                    />

                  </div>

                  <div className="formright">

                    <label>Position:</label>
                    <select
                      onChange={handleChange}
                      name="title"
                      value={formData.title}

                    >
                      <option value="">--Choose--</option>
                      <option value="Chief Security Guard">Chief Security Guard</option>
                      <option value="Cashier">Cashier</option>
                    </select>

                    <label>Number of Children:</label>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="numberOfChild"
                      value={formData.numberOfChild}
                    />

                    <label>Birth Date:</label>
                    <input
                      type="date"
                      onChange={handleChange}
                      name="birthDate"
                      value={formData.birthDate}
                    />


                    <div className="btn-div">
                      <button className="createAccBtn" onClick={handleSubmit}>Create Account</button>
                    </div>


                  </div>

                  <div>
                    <br></br>
                    {isEmpty && <p style={{ color: "red", fontSize: "80%" }}><b>Other Fields are empty.</b></p>}
                    {emailExisted && <p style={{ color: "red", fontSize: "80%" }}><b>Email Already Exist.</b></p>}
                    {mobileNumError && <p style={{ color: "red", fontSize: "80%" }}><b>Mobile Number should be 11 digits.</b></p>}
                  </div>
                </form>



              </div>

            </div>
          </div>
        )}

        {popupConfirmation && (
          <div className="popup-form" >
            <div className="popup-overlay" onClick={togglePopupConfirmation}></div>
            <div className="popup-box" id="confirmation-popup">
              {selectedEmployee.status === "Active" &&
                <>
                  <p>Are you sure you want to deactivate the account of <b>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</b>?</p>
                  <div>
                    <button className="action-buttons" onClick={() => inactivateEmployee(selectedEmployee)}>Confirm</button>
                    <button className="action-buttons" onClick={() => setPopupConfirmation(false)}>Cancel</button>
                  </div>
                </>}

              {selectedEmployee.status === "Inactive" &&
                <>
                  <p>Activate the account of <b>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</b>?</p>
                  <div>
                    <button className="action-buttons" onClick={() => activateEmployee(selectedEmployee)}>Confirm</button>
                    <button className="action-buttons" onClick={() => setPopupConfirmation(false)}>Cancel</button>
                  </div>
                </>}
            </div>
          </div>
        )}
        {popupInfo && (
          <div className="popup-form">
            <div className="popup-overlay" onClick={togglePopupInfo}></div>
            <div className="popup-box">

              <div className="popup-header">
                <h1>Tenant Information</h1>
                <img src={exitIcon} alt="Exit Icon" onClick={togglePopupInfo} className="exit-icon" />
              </div>
              <div className="popupInfo-content">

                <span><b>Name:</b> {`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</span>
                <span><b>Position:</b> {selectedEmployee.title}</span>
                <span><b>Date Hired:</b> {selectedEmployee.dateHired}</span>
                <span><b>Email:</b> {selectedEmployee.email}</span>
                <span><b>Contact Number:</b> {selectedEmployee.mobileNum}</span>
                <span><b>Birth Date:</b> {selectedEmployee.birthDate}</span>
                <span><b>Number of Children:</b> {selectedEmployee.birthDate}</span>

              </div>

            </div>
          </div>
        )}

        <div className="tenants-header">
          <h2>LIST OF EMPLOYEES</h2>

        </div>

        <div className="tenants-addSearch-div">

          <div className="left-header">
            <img src={addIcon} alt="Add Icon" className="add-icon" onClick={togglePopupForm} />

            <div style={{ display: !popup ? 'block' : 'none' }}>
              <div style={{ display: !popupInfo ? 'block' : 'none' }}>
                <div style={{ display: !popupConfirmation ? 'block' : 'none' }}>
                  <RangePicker className="rangedate" onChange={handleDateChange} />
                </div>
              </div>

            </div>

          </div>
          <div className="right-header">
            <div className="entries">
              <span>Show</span>

              <form>
                <select onChange={handleEntriesChange} value={entriesCount}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </form>
              <span>Entries</span>
            </div>
            <form>
              <div className='search-container'>
                <input type="text"
                  className="searchbar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </form>
          </div>

        </div>

        <div className="admin-table-div">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Title</th>
                <th>Date Hired</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>

              {
                displayedEmployees.map((employee) => (
                  <tr key={employee.id}>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>{`${employee.firstName} ${employee.lastName}`}</td>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>{employee.email}</td>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>{employee.mobileNum}</td>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>{employee.title}</td>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>{employee.dateHired}</td>
                    <td style={{ backgroundColor: (!activeStatus && !inactiveStatus) && employee.status === "Inactive" ? "#6e16163d" : "" }}>
                      <button className="action-buttons" onClick={() => togglePopupInfo(employee)}>
                        View
                      </button>

                      <button className="action-buttons" onClick={() => togglePopupConfirmation(employee)}>
                        {employee.status === "Active" ? "Disable" : "Enable"}
                      </button>
                    </td>
                  </tr>
                ))
              }




            </tbody>
          </table>

          <div className="pdfBtn-div">
            <div className="statusBtn-div">
              <button
                className={`statusBtn ${activeStatus ? 'active' : 'inactive'}`}
                onClick={toggleActive}
                style={{ border: !activeStatus ? "1px solid #da0f3181" : "" }}
              >
                Active Employees
              </button>

              <button
                className={`statusBtn ${activeStatus ? 'active' : 'inactive'}`}
                onClick={toggleInactive}
                style={{ border: !inactiveStatus ? "1px solid #da0f3181" : "" }}
              >
                Inactive Employees
              </button>
            </div>

            <nav className="pagination" id="center-pagination">
              <ul>
                <li className="page-item">
                  <a
                    href="#"
                    className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link">
                    {currentPage}
                  </a>
                </li>
                <li className="page-item">
                  <a
                    href="#"
                    className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                    onClick={nextPage}
                    disabled={currentPage === npage}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>

            <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
          </div>
        </div>
      </div>
    </div>
  )
}