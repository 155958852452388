import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import data from "./components/userData"
import Login from "./components/login/login"
// import Admin from "./components/profile/admin/admin";
// import Tenant from "./components/profile/tenants/tenants"
import CashierSidebar from "./components/profile/cashier/cashierSidebar/cashierSidebar";
import MaintenanceSidebar from "./components/profile/maintenance/maintenanceSidebar/maintenaceSidebar";
import AdminSidebar from "./components/profile/admin/adminSidebar/adminSidebar";
import Unit from "./components/profile/admin/units/unit";
import TenantSidebar from "./components/profile/tenants/tenantsSidebar/tenantsSidebar";
import Dashboard from "./components/global/dashboard/Dashboard";
import { useState } from "react";
import { LoginContext } from "./context/LoginContext";
import FrontDeskSidebar from "./components/profile/frontdesk/frontdeskSidebar/frontdeskSidebar";
import Tenants from "./components/profile/admin/tenants/tenants";
import Employees from "./components/profile/admin/employees/employees";
// import Contracts from "./components/profile/admin/contracts/contracts";
import AdminWorkOrders from "./components/profile/admin/workorder/workorder";
import Complaints from "./components/profile/admin/complaints/complaints";
import Reports from "./components/profile/admin/reports/reports";
import Maintenance from "./components/profile/admin/reports/maintenance";
import Payments from "./components/profile/admin/reports/payment";
import VisitorsLog from "./components/profile/admin/visitorslog/visitorslog";
// import Calendar from "./components/profile/admin/calendar/calendar";
import Help from "./components/global/help/help";
import Ledger from "./components/profile/tenants/ledger/ledger";
import MaintenanceWorkorders from "./components/profile/maintenance/workorders/workorders";
import Settings from "./components/global/settings/settings";
import TenantMaintenance from "./components/profile/tenants/maintenance/maintenance";
import TenantComplaints from "./components/profile/tenants/complaints/complaints";
import Documents from "./components/profile/tenants/documents/documents";
import Inspection from "./components/profile/maintenance/inspection/inspection";
import MaintenanceReports from "./components/profile/maintenance/reports/reports";
import FrontdeskUnit from "./components/profile/frontdesk/unit/unit";
import FrontdeskVisitorsLog from "./components/profile/frontdesk/visitorslog/visitorslog";
import CashierLedger from "./components/profile/cashier/ledger/ledger";
import CashierCollection from "./components/profile/cashier/collection/collection";
import CashierPayment from "./components/profile/cashier/payment/payment";
import Visitor from "./components/profile/visitor/visitor";
import ForgotPassword from "./components/login/forgotpassword";
import ResetPassword from "./components/login/resetpassword";
import PrivateRoutes from "./components/PrivateRoutes";
// import { db, auth } from "./firebase-config";
import { db } from "./firebase-config";
import { collection, getDocs } from "firebase/firestore";
// import { onAuthStateChanged } from "firebase/auth";

function App() {
  const usersCollection = collection(db, "users")
  const adminCollection = collection(db, "admin")
  const tenantsCollection = collection(db, "tenants")
  const employeesCollection = collection(db, "employees")
  const unitsCollection = collection(db, "units")
  const visitorsCollection = collection(db, "visitors")
  const maintenanceCollection = collection(db, "maintenance")
  const complaintsCollection = collection(db, "complaints")
  const announcementsCollection = collection(db, "announcements")
  const tenantsLedgerCollection = collection(db, "tenantsLedger")

  const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('isLoggedIn')) === true)
  const [admin, setAdmin] = useState([])
  const [tenants, setTenants] = useState([])
  const [units, setUnits] = useState([])
  const [employees, setEmployees] = useState([])
  const [visitors, setVisitors] = useState([])
  const [maintenance, setMaintenance] = useState([])
  const [complaints, setComplaints] = useState([])
  const [announcements, setAnnouncements] = useState([])
  const [tenantsLedger, setTenantsLedger] = useState([])
  const [authUser, setAuthUser] = useState(null)
  const [currentUser, setCurrentUser] = useState([])

  const [vat, setVat] = useState(localStorage.getItem('vat') || 12);
  const [wht, setWht] = useState(localStorage.getItem('wht') || 5);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(!!isLoggedIn));
    // eslint-disable-next-line no-use-before-define
  }, [isLoggedIn])




  useEffect(() => {

    const getAdmin = async () => {
      try {
        const querySnapshot = await getDocs(adminCollection);
        const adminData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAdmin(adminData);

      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };
    getAdmin();

    const getTenants = async () => {
      try {
        const querySnapshot = await getDocs(tenantsCollection);
        const tenantsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Sort the tenants by createdAt in ascending order
        tenantsData.sort((b, a) => a.createdAt - b.createdAt);

        setTenants(tenantsData);
      } catch (error) {
        console.error("Error retrieving tenants:", error);
      }
    };

    getTenants();

    const getUnits = async () => {
      try {
        const querySnapshot = await getDocs(unitsCollection);
        const unitsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        unitsData.sort((a, b) => {

          return a.unitNumber - b.unitNumber;
        });
        setUnits(unitsData);

      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };
    getUnits();

    const getEmployees = async () => {
      try {
        const querySnapshot = await getDocs(employeesCollection);
        const employeesData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Sort the employees by createdAt in descending order
        employeesData.sort((a, b) => b.createdAt - a.createdAt); // Reverse the comparison

        setEmployees(employeesData);
      } catch (error) {
        console.error("Error retrieving tenants:", error);
      }
    };

    getEmployees();

    const getVisitors = async () => {
      try {
        const querySnapshot = await getDocs(visitorsCollection);
        const visitorsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Sort the visitorsData array by the date string
        visitorsData.sort((a, b) => {
          // Convert the date strings to Date objects
          const dateA = new Date(a.date); // Assuming the date field is called "date"
          const dateB = new Date(b.date);

          // Compare the Date objects
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            return 0;
          }
        });

        setVisitors(visitorsData);

      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };

    getVisitors();

    const getMaintenance = async () => {
      try {
        const querySnapshot = await getDocs(maintenanceCollection);
        const maintenanceData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Sort the maintenanceData array based on the lastUpdate field in descending order
        maintenanceData.sort((a, b) => {
          const dateA = new Date(a.lastUpdate);
          const dateB = new Date(b.lastUpdate);
          return dateB - dateA;
        });

        setMaintenance(maintenanceData);
      } catch (error) {
        console.error("Error retrieving maintenance:", error);
      }
    };

    getMaintenance();

    const getComplaints = async () => {
      try {
        const querySnapshot = await getDocs(complaintsCollection);
        const complaintsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Sort the complaintsData by lastUpdateTime in descending order
        complaintsData.sort((a, b) => b.lastUpdateTime - a.lastUpdateTime);

        setComplaints(complaintsData);
      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };

    getComplaints();

    const getTenantsLedger = async () => {
      try {
        const querySnapshot = await getDocs(tenantsLedgerCollection);
        const tenantsLedgerData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setTenantsLedger(tenantsLedgerData);

      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };
    getTenantsLedger()

    const getAnnouncements = async () => {
      try {
        const querySnapshot = await getDocs(announcementsCollection);
        const announcementsData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAnnouncements(announcementsData);

      } catch (error) {
        console.error("Error retrieving users:", error);
      }
    };
    getAnnouncements()

  }, []); // eslint-disable-line

  return (
    <>
      <LoginContext.Provider value={{ 
        isLoggedIn, setIsLoggedIn, 
        authUser, setAuthUser, 
        currentUser, setCurrentUser, 
        admin, setAdmin, 
        tenants, setTenants, 
        employees, setEmployees, 
        units, setUnits, 
        visitors, setVisitors, 
        maintenance, setMaintenance, 
        complaints, setComplaints, 
        announcements, setAnnouncements, 
        usersCollection, adminCollection, 
        tenantsCollection, unitsCollection, 
        employeesCollection, visitorsCollection, 
        maintenanceCollection, complaintsCollection, 
        announcementsCollection, tenantsLedger, 
        setTenantsLedger, tenantsLedgerCollection,
        vat, setVat,
        wht, setWht
        }}>
        <Routes>

          <Route path="/" element={<Login />} />

          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="visitor" element={<Visitor />} />

          <Route element={<PrivateRoutes />}>
            <Route path="resetpassword" element={<ResetPassword />} />

            <Route path="admin" element={<AdminSidebar />}>
              <Route index element={<Dashboard />} />
              <Route path="unit" element={<Unit />} />
              <Route path="tenants" element={<Tenants />} />
              <Route path="employees" element={<Employees />} />
              <Route path="workorders" element={<AdminWorkOrders />} />
              <Route path="reports" element={<Reports />} />
              <Route path="reports/maintenance" element={<Maintenance />} />
              <Route path="reports/payments" element={<Payments />} />
              <Route path="reports/visitorslog" element={<FrontdeskVisitorsLog />} />
              <Route path="complaints" element={<Complaints />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="tenant" element={<TenantSidebar />}>
              <Route index element={<Dashboard />} />
              <Route path="maintenance" element={<TenantMaintenance />} />
              <Route path="complaints" element={<TenantComplaints />} />
              <Route path="contract" element={<Documents />} />
              <Route path="ledger" element={<Ledger />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="maintenance" element={<MaintenanceSidebar />}>
              <Route index element={<Dashboard />} />
              <Route path="inspection" element={<Inspection />} />
              <Route path="workorders" element={<MaintenanceWorkorders />} />
              <Route path="reports" element={<MaintenanceReports />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="cashier" element={<CashierSidebar />} >
              <Route index element={<Dashboard />} />
              <Route path="ledger" element={<CashierLedger />} />
              <Route path="payment" element={<CashierPayment />} />
              <Route path="collection" element={<CashierCollection />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="chiefsecurity" element={<FrontDeskSidebar />} >
              <Route index element={<Dashboard />} />
              <Route path="unit" element={<FrontdeskUnit />} />
              <Route path="visitorslog" element={<FrontdeskVisitorsLog />} />
              <Route path="help" element={<Help />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>
        </Routes>
      </LoginContext.Provider>
    </>
  );
}

export default App;
