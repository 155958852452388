import React, { useContext, useState } from "react";
// import addIcon from "../../../global/images2/add-icon.png"
import { LoginContext } from "../../../../context/LoginContext";
import { DatePicker } from 'antd';
import jsPDF from "jspdf";
const { RangePicker } = DatePicker;

export default function VisitorsLog() {
  // const {visitors, setVisitors, visitorsCollection} = useContext(LoginContext)
  const { visitors } = useContext(LoginContext)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [entriesCount, setEntriesCount] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line
  const [searchedVisitors, setSearchedVisitors] = useState([]);
  const [activeStatus, setActiveStatus] = useState(true)
  const [inactiveStatus, setInactiveStatus] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const lastIndex = currentPage * entriesCount;
  const firstIndex = lastIndex - entriesCount;
  const npage = Math.ceil(visitors.length / entriesCount)
  // eslint-disable-next-line
  const numbers = [...Array(npage + 1).keys()].slice(1)

  const displayedVisitorsLog = visitors
    .filter((employee) => {
      if (activeStatus && employee.timeOut === '') {
        return false;
      }

      if (startDate && endDate) {
        const employeeDateHired = new Date(employee.date);
        if (employeeDateHired < startDate || employeeDateHired > endDate) {
          return false;
        }
      }

      if (searchTerm) {
        const searchTermsArray = searchTerm.toLowerCase().split(' ');
        const allWordsFound = searchTermsArray.every((word) => {
          for (const key in employee) {
            if (Object.hasOwnProperty.call(employee, key)) {
              const value = employee[key];
              if (value && value.toString().toLowerCase().includes(word)) {
                return true;
              }
            }
          }
          return false;
        });
        return allWordsFound;
      }

      return true;
    }).slice(firstIndex, lastIndex);

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleEntriesChange = (event) => {
    setEntriesCount(event.target.value);
  };

  // eslint-disable-next-line
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterVisitors(searchTerm);
  };

  const filterVisitors = (searchTerm) => {
    const searchTermsArray = searchTerm.toLowerCase().split(' ');

    const filteredVisitors = visitors.filter((employee) => {
      if (!startDate || !endDate) {
        return true;
      }

      const employeeDateHired = new Date(employee.dateHired);
      const isWithinDateRange =
        employeeDateHired >= startDate && employeeDateHired <= endDate;

      // Check if all words in the search term exist in the employee's fields
      const allWordsFound = searchTermsArray.every((word) => {
        for (const key in employee) {
          if (Object.hasOwnProperty.call(employee, key)) {
            const value = employee[key];
            if (value && value.toString().toLowerCase().includes(word)) {
              return true; // Include the employee if any word matches and is within the date range.
            }
          }
        }
        return false;
      });

      return isWithinDateRange && allWordsFound;
    });

    setSearchedVisitors(filteredVisitors);
  };

  // eslint-disable-next-line
  const toggleActive = () => {
    setActiveStatus(!activeStatus);

  };
  // eslint-disable-next-line
  const toggleInactive = () => {
    setInactiveStatus(!inactiveStatus);
  };
  function nextPage() {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Date.', 'Name', 'Purpose', 'Unit', 'Time In', "Time Out"]],
      body: displayedVisitorsLog.map((employee) => [
        employee.date,
        `${employee.firstName} ${employee.lastName}`,
        employee.purpose,
        employee.unit,
        employee.timeIn,
        employee.timeOut,
      ]),
    });
    doc.save('Visitors.pdf');
  };

  return (
    <div className="admin-tenants-container">
      <div className="admin-tenants-content">
        <div className="tenants-header">
          <h1>VISITORS LOG</h1>
        </div>
        <div className="tenants-addSearch-div">
          <div className="left-header">
            <div>
              <RangePicker className="rangedate" onChange={handleDateChange} />
            </div>
          </div>
          <div className="right-header">
            <div className="entries">
              <span>Show</span>
              <form>
                <select onChange={handleEntriesChange} value={entriesCount}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </form>
              <span>Entries</span>
            </div>
            <form>
              <div className='search-container'>
                <input type="text"
                  className="searchbar"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
            </form>
          </div>
        </div>
        <div className="admin-table-div">
          <table className="admin-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Purpose</th>
                <th>Unit</th>
                <th>Time In</th>
                <th>Time Out</th>
              </tr>
            </thead>
            <tbody>
              {
                displayedVisitorsLog.map((visitor) => (
                  <tr key={visitor.id}>
                    <td>{visitor.date}</td>
                    <td>{visitor.firstName} {visitor.lastName}</td>
                    <td>{visitor.purpose}</td>
                    <td>{visitor.unit}</td>
                    <td>{visitor.timeIn}</td>
                    <td>{visitor.timeOut}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="pdfBtn-div">
            <nav className="pagination" id="center-pagination">
              <ul>
                <li className="page-item">
                  <a
                    href="*"
                    className={`page-link ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={prevPage}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </a>
                </li>
                <li className="page-item">
                  <a href="*" className="page-link">
                    {currentPage}
                  </a>
                </li>
                <li className="page-item">
                  <a
                    href="*"
                    className={`page-link ${currentPage === npage ? 'disabled' : ''}`}
                    onClick={nextPage}
                    disabled={currentPage === npage}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
            <button className="pdfBtn" onClick={downloadAsPDF}>Download as PDF</button>
          </div>
        </div>
      </div>
    </div>
  )
}