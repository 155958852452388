import React, { useState } from "react";
import PopOutPDF from "../../sammarc/popOutPDF";
import PopOutImage from "../../sammarc/popOutImage";
import "./helpStyle.css"
const Help = () => {

  const [showPopOutPDF, setShowPopOutPDF] = useState(false);
  const [showPopOutImage, setShowPopOutImage] = useState(false);

  const showPopOutComponentPDF = (e) => {
    setShowPopOutPDF(true);
  }
  const showPopOutComponentImage = (e) => {
    setShowPopOutImage(true);
  }

  return (
    <div className="help-container">
      <div className="help-content">
        <div className="help-header"><h1>HELP CENTER</h1></div>
        <div className="help-wrapper" id="help-wrapper1">
          
          <div
           
            className="help-box"
            
          >
            <a href="https://drive.google.com/drive/folders/1U2zvcB65n4v1SJirWexAnFzqBzrXET4a?usp=sharing">
            <i className="fa-solid fa-gears position-absolute"></i>
            <div className="help-box-desc">
              <h2>How to use the System</h2>
              <p>We provided a user manual on how to use the system. Kindly click here.</p>
            </div>
            </a>
          </div>
          
          <div
            name='button-two'
            className="help-box"
            id="help-wrapper2"
            onClick={showPopOutComponentImage}
          >
            <i className="fa-solid fa-file-pen position-absolute"></i>
            <div className="help-box-desc">
              <h2>Report a Problem</h2>
              <p>If you're  experiencing a problem while using the system kindly contact our team. Kindly click here.</p>
            </div>
          </div>
          {showPopOutPDF ? <PopOutPDF action={setShowPopOutPDF} /> : null}
          {showPopOutImage ? <PopOutImage action={setShowPopOutImage} /> : null}
        </div>
      </div>
    </div>
  )
}

export default Help;