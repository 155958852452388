import React, { useEffect } from "react";
import "./loginStyle.css"
import diplomatLogo from "./images/diplomat-logo.png"
import { Link,  useNavigate } from "react-router-dom";
// import AdminSidebar from "../profile/admin/adminSidebar/adminSidebar";
import { useState, useContext } from "react";
import { LoginContext } from "../../context/LoginContext";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase-config";
export default function ForgotPassword(){

    const [emailError, setEmailError] = useState(false)
    const [sent, setSent] = useState(false)
    const handleForgotPassword = (e) => {
      e.preventDefault(); // Prevent the default form submission behavior
      sendPasswordResetEmail(auth, email)
          .then(() => {
              console.log("Password reset email sent. Check your email.");
              setSent(true)
              setEmailError(false);
          })
          .catch((error) => {
              setEmailError(true);
              setSent(false)
              console.error("Error sending password reset email:", error);
          });
  };

    const [email, setEmail] = useState("");
    console.log(email);
    
    
    const navigate = useNavigate()

    const resetpassword = () => {
         navigate("/")
    }
    return(
        
        <div className="login-container">

                <div className="box" id="left-box">
                    <div  className="header"><img src={diplomatLogo} id="diplomat-logo" alt="Diplomat Logo"/></div>

                    <div className="login-content">
                        
                        <div className="form-div" >

                                <h2>Forgot Password?</h2>
                                
                                <form className="form-content" id="forgot-form" onSubmit={handleForgotPassword}>
                                    <input 
                                    className="text-input" 
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoFocus
                                    />
                                    <button type="submit" className="login">Send Password Reset</button>
                                    <button className="login" onClick={resetpassword}>Back to Log in form</button>
                                    <div className="remfor" >

                                    <span id="incorrectInp" style={{display: emailError ? 'block' : 'none' }}>Invalid Email</span>
                                    <span className="success" id="password-reset" style={{display: sent ? 'block' : 'none' }}>Password Reset Email Sent.</span>
                                        

                                    </div>
                                    
                                    {/* <button className="login" onClick={resetpassword}>Reset Password</button> */}
                                </form>
                            
                            
                        </div>
                    </div>

                </div>

                <div className="box" id="right-box"></div>
            
        </div>
        
    )
}